import styled from 'styled-components';
import { Button } from '_common/components/Button/Button';

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-top: solid 1px #dfdfdf;
  min-height: 35rem;
`;

export const ItemHolder = styled.div`
  margin: -2rem;
`;

export const CancelTitle = styled.div`
  font-size: ${props => props.theme.fontSize.xxl};
  font-weight: bold;
  margin: 4.5rem 0;
  color: ${props => props.theme.colors.text};
  text-align: center;
`;

export const ModalButton = styled(Button)`
  && {
    margin-bottom: 2rem;
  }
`;
