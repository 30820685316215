import {
  ROOT_CARRIER_ID,
  ROOT_ORG_ID,
  SENTRY_DSN_URL,
} from '_common/constants/common';
import * as SentrySDK from '@sentry/react';
import { getEnvVar } from '_common/utils/environmentVariable';

export enum EPAYMENT_EVENTS {
  BEFORE_PAYMENT = 'before payment',
  AFTER_PAYMENT = 'after payment',
}

export type TPaymentEventData = {
  orderNumber: string;
  email: string;
  amount: number;
  timestamp?: string;
  status?: string | number;
  transactionId?: string;
  resCode?: string;
};

export type TIntegratedOrderData = {
  orderId: string;
  companyId: string;
};

const getWhiteLabelBuildName = () =>
  [ROOT_ORG_ID, ROOT_CARRIER_ID]
    .filter(name => name && name !== 'N/A')
    .join('_');

class Sentry {
  private sentry = SentrySDK;

  private isProduction = process.env.NODE_ENV === 'production';

  init = () => {
    if (!window || !this.isProduction || !SENTRY_DSN_URL) {
      return;
    }

    if (SENTRY_DSN_URL === 'N/A') {
      console.info('Sentry is disabled for current env.');
      return;
    }

    this.sentry.init({
      release: `${getEnvVar('REACT_APP_NAME')}@${getEnvVar(
        'REACT_APP_VERSION'
      )}`,
      dsn: SENTRY_DSN_URL,
      beforeSend: event => {
        // adds carrier tag for better filtering in dashboard
        if (!event) return;
        if (!event.tags) {
          event.tags = {};
        }
        event.tags.carrier = getWhiteLabelBuildName();
        return event;
      },
    });
  };

  logPaymentPageEvent = (
    eventType: EPAYMENT_EVENTS,
    logData: TPaymentEventData
  ) => {
    this.sentry.withScope(scope => {
      scope.setTag('payment_event', eventType);
      scope.setFingerprint(['payment-page-logs']);
      this.sentry.captureMessage('Payment page event', {
        extra: logData,
      });
    });
  };

  logIntegratedDetailsPageEvent = (logData: TIntegratedOrderData) => {
    this.sentry.withScope(scope => {
      scope.setFingerprint(['integrated_details_event']);
      this.sentry.captureMessage('Integrated details page open', {
        extra: logData,
      });
    });
  };
}

export default new Sentry();
