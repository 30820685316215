import React, { Component, ReactNode } from 'react';
import { ModalBody, ModalOverlay, ModalWrapper } from './elements';
import { CloseIcon } from '_common/icons';
import { inject } from 'mobx-react';
import { compose } from 'recompose';
import EmbedStore from '_common/stores/embedStore';
import CustomResizeDetector from '../CustomResizeDetector';

type ModalProps = {
  children?: ReactNode;
  toggle?: () => void;
  width?: number;
  hasCloseBtn?: boolean;
  embedStore: EmbedStore;
};

class BaseModal extends Component<ModalProps, {}> {
  handleClose = () => {
    this.props.toggle && this.props.toggle();
  };

  detectResize = (_, h: number) => {
    const { embedStore } = this.props;
    embedStore.setHeight('BaseModal', h);
  };

  componentWillUnmount() {
    this.detectResize(0, 0);
  }

  render() {
    const {
      width,
      hasCloseBtn,
      embedStore: { embedded },
    } = this.props;

    return (
      <ModalWrapper>
        <ModalOverlay onClick={this.handleClose} />
        <CustomResizeDetector
          isEmbedded={embedded}
          handleHeight
          onResize={this.detectResize}
        >
          <ModalBody bodyWidth={width} hasCloseBtn={hasCloseBtn}>
            {hasCloseBtn && <CloseIcon onClick={this.handleClose} />}
            {this.props.children}
          </ModalBody>
        </CustomResizeDetector>
      </ModalWrapper>
    );
  }
}

export default compose(inject('embedStore'))(BaseModal);
