import { get } from 'lodash';

enum environmentLoad {
  environmentLoadExternal = 'externalProcessEnv',
  environmentLoadInternal = 'internalProcessEnv',
}

/**
 * Helper method to grab the requested environment variable from the window object
 * where they will now live when using "react-env" using lodash,
 * so we don't get any nasty "object not found" errors. Allows
 * passing in a default value.
 *
 * @param environmentVariablePath
 */
export const getEnvVar = (environmentVariablePath: string): string | null => {
  /**
   * Check that we have env.js enabled for usage in the index.html
   * This environment variable is adjusted via the Makefile in the root, only run on kube
   */
  if (
    get(process.env, 'REACT_APP_ENVIRONMENT_LOAD') ===
    environmentLoad.environmentLoadExternal
  ) {
    return get(window, `_env.${environmentVariablePath}`);
  }
  return get(process.env, `${environmentVariablePath}`);
};
