import styled, { css } from 'styled-components';
import { Divider } from 'antd';
import { FormErrorMessage } from 'pages/start/elements';
import React from 'react';
import { rtlMixin } from '_common/constants/theme';

export const RowWrapper = styled.div<{
  childrenWidth?: string;
}>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .ant-row.ant-form-item {
    width: ${({ childrenWidth = '48%' }) => childrenWidth};
  }

  .has-error.ant-input {
    border-color: ${props => props.theme.colors.borderAlt} !important;
  }
  .ant-form-explain {
    & > p {
      margin-bottom: 5px;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    .ant-row.ant-form-item {
      width: 100%;
    }
  }
`;

export const Terms = styled.span`
  font-size: ${props => props.theme.fontSize.m};
  font-weight: 400;
  letter-spacing: 0;
  color: ${props => props.theme.colors.textAlt};
  margin-left: 10px;
  line-height: 2;
  ${rtlMixin(css`
    margin-left: initial;
    margin-right: 10px;
  `)}

  a {
    color: ${props => props.theme.colors.textAlt};
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      color: ${props => props.theme.colors.textAlt};
    }

    &:focus {
      outline: 2px solid rgba(24, 144, 255, 0.2);
    }
  }
  @media only screen and (max-width: 768px) {
    font-size: ${props => props.theme.fontSize.l};
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
`;

export const CheckboxFormItemContainer = styled.div<{ showError?: boolean }>`
  margin: 20px 0;
  color: ${props => props.theme.colors.text};
  font-family: ${props => props.theme.fonts.primary};
  & {
    .ant-form-explain,
    .ant-form-explain-holder {
      ${({ showError }) =>
        showError
          ? css`
              text-align: left;
            `
          : css`
              display: none;
            `}
    }
  }
  & .ant-checkbox-inner {
    border-color: #e8e8e8;
  }
  & .has-error {
    .ant-checkbox-inner {
      border-color: ${props => props.theme.colors.error} !important;
    }
  }
`;

export const OrderTitle = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.xxl};
  font-weight: bold;
  letter-spacing: 0;
  color: ${props => props.theme.colors.textAlt};
  margin-bottom: 8px;
`;

export const SubTitle = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.l};
  font-weight: 400;
  color: ${props => props.theme.colors.text};
  margin-bottom: 1rem;
`;

export const IntegratedButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 4rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
  ${FormErrorMessage} {
    margin: 0 auto;
  }
`;

export const LoadingMessage = styled.div`
  padding-left: 39px;
  font-family: ${props => props.theme.fonts.basic};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.3px;
  color: ${props => props.theme.colors.textAlt};
  margin-top: 20px;
`;

export const CustomDivider = styled(({ isVisible, ...rest }) => (
  <Divider {...rest} />
))<{ isVisible?: boolean }>`
  && {
    width: calc(100% + 6rem);
    transform: translateX(-3rem);
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    margin: 0 0 1rem;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const DetailsFormErrorMessage = styled.div`
  margin: 0.5rem 0;
  font-size: ${props => props.theme.fontSize.s};
  font-family: ${props => props.theme.fonts.primary};
  color: #f5222d;
`;
