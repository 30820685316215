import { action, observable, runInAction } from 'mobx';
import { ModalProps } from 'types/modal';

class UIStore {
  modal: ModalProps | null = null;

  @observable
  isModalShown = false;

  @observable
  modalError: string | null = null;

  @action
  openModal = (payload: ModalProps) => {
    runInAction(() => {
      this.isModalShown = true;
      this.modal = {
        ...payload,
      };
    });
  };

  @action
  closeModal = () => {
    if (this.modal && this.modal.onClose) {
      this.modal.onClose();
    }
    runInAction(() => {
      this.isModalShown = false;
      this.modalError = null;
    });
  };

  @action
  submitModal = async (...args: [any]) => {
    if (this.modal?.eventProps?.onSubmit) {
      runInAction(() => {
        this.modalError = null;
      });
      try {
        await this.modal.eventProps.onSubmit(...args);
      } catch (e) {
        console.error(e);
        runInAction(() => (this.modalError = e.message));
        return;
      }
      this.closeModal();
    }
  };
}
export default UIStore;
