import apiEndpoins from '_common/api/endpoints';
import themeService from '_common/services/themeService';
import themes from 'themes';
import { merge } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { ASPECT_RATIO_16_9, DEFAULT_LOCALE } from '_common/constants/common';
import { EAsyncStatus, EAPP_LOCALES } from 'types/core';
import { localStore as storage, USER_LOCALE } from 'storage';
import i18nService, {
  FONTS_LOCALE_MAPPING,
  getCurrentLocale,
} from '_common/services/i18nService';
import moment from 'moment';
import { getLayoutDirection, MERCHANT_ORGANISATION } from '_common/utils';
import { storeCustomTextsAsI18nResources } from '_common/utils/consumerText';
import { DefaultTheme } from 'styled-components';
import { WhiteLabelUi } from '_common/whitelabelConfig';

/**
 * Observes loading theme for app.
 */
class ThemeStore {
  @observable
  currentLocale: EAPP_LOCALES = getCurrentLocale(); // this is used for antd locale only

  @observable
  state: EAsyncStatus = EAsyncStatus.IDLE;

  @observable
  theme: DefaultTheme = themes.default;

  @observable
  widthOfBackgroundImage: number =
    ASPECT_RATIO_16_9 * (window.innerHeight - 170); // 100 header, 70 footer

  @observable
  widthOfBackgroundImageWithoutFooter: number =
    ASPECT_RATIO_16_9 * (window.innerHeight - 100); // 100 header

  @computed
  get layoutDirection() {
    return getLayoutDirection(this.currentLocale);
  }

  constructor() {
    // check for locale in local storage
    const storedLocale = storage.get(USER_LOCALE);
    if (!storedLocale) {
      storage.set(USER_LOCALE, DEFAULT_LOCALE);
    }
    moment.locale(storedLocale || DEFAULT_LOCALE);
    this.changeFonts(this.currentLocale);

    if (window.innerWidth > 768) {
      window.addEventListener(
        'resize',
        () => {
          runInAction(() => {
            this.widthOfBackgroundImage =
              ASPECT_RATIO_16_9 * (window.innerHeight - 170);
            this.widthOfBackgroundImageWithoutFooter =
              ASPECT_RATIO_16_9 * (window.innerHeight - 100);
          });
        },
        true
      );
    }
  }

  @action
  setTheme = (themeConfig: DefaultTheme) => {
    storeCustomTextsAsI18nResources(
      i18nService,
      themeConfig.config?.consumerText
    );

    Object.keys(themeConfig.assets).forEach((id: string) => {
      const url = themeConfig.assets[id];
      // Increasing TTL to 24 hours reducing the timestamp from milliseconds to days
      const ts = Math.floor(Date.now() / 86400000);
      // eslint-disable-next-line no-param-reassign
      themeConfig.assets[id] = `${apiEndpoins.ASSETS_URL}/${url}?ts=${ts}`;
    });
    this.state = EAsyncStatus.SUCCESS;
    this.theme = merge({ ...this.theme }, themeConfig);
  };

  changeFonts = (locale: EAPP_LOCALES) => {
    const fonts = (WhiteLabelUi.common.fontsLocaleMapping ||
      FONTS_LOCALE_MAPPING)[locale];
    this.theme = merge({ ...this.theme }, { fonts });
  };

  @action
  makeThemeRequest = async (): Promise<DefaultTheme> => {
    /** Dont try to load theme for invalid merchant's params. */
    if (!MERCHANT_ORGANISATION || MERCHANT_ORGANISATION === 'not-found') {
      return Promise.resolve(this.theme);
    }

    this.state = EAsyncStatus.LOADING;
    try {
      const theme = await themeService.loadTheme();
      this.setTheme(theme);
      return Promise.resolve(theme);
    } catch (e) {
      /** Missing config isn't a error case anymore. */
      runInAction(() => {
        this.state = EAsyncStatus.SUCCESS;
        // this triggers loader placeholders to stop
        this.theme = themes.default;
        this.changeFonts(this.currentLocale);
      });
      return Promise.resolve(this.theme);
    }
  };

  @action
  setCurrentLocale = (
    locale: EAPP_LOCALES,
    isSilientSwitch: boolean = false
  ) => {
    if (!isSilientSwitch) {
      this.currentLocale = locale;
    }
    i18nService.changeLanguage(locale);
    this.changeFonts(locale);
    moment.locale(locale);
    storage.set(USER_LOCALE, locale);
  };
}
export default ThemeStore;
