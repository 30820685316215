import React from 'react';
import { StyledButton } from './elements';
import { ButtonProps } from 'antd/lib/button';
import styled from 'styled-components';

type Props = ButtonProps & {
  integratedFlow?: boolean;
  marginTop?: number;
  marginBottomMobile?: number;
  outline?: boolean;
  isActive?: boolean;
};

export const Button = ({
  integratedFlow,
  marginTop,
  marginBottomMobile,
  outline,
  isActive,
  ...props
}: Props) => <StyledButton {...props}>{props.children}</StyledButton>;

export const OutlineButton = styled(Button)`
  && {
    font-family: ${props => props.theme.fonts.primary};
    font-size: ${props => props.theme.fontSize.m};
    font-weight: 400;
    width: 12.3rem;
    height: 3.6rem;
    padding: 0.5rem 0;
    min-width: unset;
    margin-top: 2rem;

    background-color: ${props => props.theme.colors.defaultBackground};
    color: ${props => props.theme.colors.textAlt};
    border: 1px solid #111;

    &:hover {
      background-color: ${props => props.theme.colors.defaultBackground};
      color: ${props => props.theme.colors.textAlt};
      border: 1px solid #111;
    }
    @media only screen and (max-width: 420px) {
      display: none;
    }
  }
`;
