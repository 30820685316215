import styled, { css } from 'styled-components';
import { tickIcon } from 'assets';
import { isIE } from 'react-device-detect';
import { rtlMixin } from '_common/constants/theme';

const disabledStyle = css`
  pointer-events: none;
  position: relative;
  opacity: 0.5;
  border: solid 1px rgba(223, 223, 223, 0.47);
`;

const getColorConfig = (hex: string) => css`
  border: solid 1px ${hex};
  box-shadow: 0 0 0 1px ${hex};
  &:before {
    background: linear-gradient(
      to top right,
      transparent 0%,
      transparent 50%,
      ${hex} 50%,
      ${hex} 100%
    );
  }
`;

export const Wrapper = styled.div<{ active: boolean; disabled?: boolean }>`
  height: 100%;
  border-radius: 3px;

  display: flex;
  flex-direction: column;
  position: relative;

  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    width: 40px;
    height: 40px;
    ${rtlMixin(css`
      right: auto;
      left: -1px;
      transform: rotate(-90deg);
    `)}
  }
  &:after {
    content: url(${tickIcon});
    position: absolute;
    top: 0;
    right: 6px;
    z-index: ${props => props.theme.zIndex.elevation3};
    font-size: 1.5rem;
    color: ${props => props.theme.colors.white};
    ${rtlMixin(css`
      right: auto;
      left: 4px;
    `)}
    @media only screen and (max-width: 576px) {
      top: 3px;
      right: 5px;
      ${rtlMixin(css`
        right: auto;
        left: 5px;
      `)}
    }
  }
  ${({ disabled }) => disabled && disabledStyle};
  ${({ active }) => css`
    ${getColorConfig(active ? '#38b13a' : '#dfdfdf')};
    @media only screen and (min-width: 769px) {
      &:hover {
        ${!active && getColorConfig('#111')}
      }
    }
  `};

  @media only screen and (max-width: 576px) {
    min-height: initial;
  }
`;

export const Body = styled.div`
  width: 100%;
  min-height: 255px;
  display: flex;
  padding: 2rem 2rem 0;
`;

export const Footer = styled.div<{ isLoading: boolean; hasError: boolean }>`
  width: 100%;
  padding: 0 2rem 2rem;
  flex-grow: 1;
  min-height: 150px;
  @media only screen and (max-width: 576px) {
    min-height: unset;
  }
  ${({ isLoading, hasError }) =>
    (isLoading || hasError) &&
    `
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 0;
      flex-direction: ${hasError ? 'column' : 'row'};
      @media only screen and (max-width: 576px) {
        flex-direction: column;
      }
  `}
  && {
    .ant-skeleton {
      width: 50%;
      @media only screen and (max-width: 420px) {
        width: 100%;
      }
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
    .ant-skeleton.ant-skeleton-active
      .ant-skeleton-content
      .ant-skeleton-paragraph
      > li,
    .ant-skeleton.ant-skeleton-active
      .ant-skeleton-content
      .ant-skeleton-title {
      background: linear-gradient(90deg, #ececec 25%, #d1d1d1 37%, #ececec 63%);
      background-size: 400% 100%;
    }
  }
`;

export const TypeTitle = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.xl};
  font-weight: bold;
  color: #222222;
  margin-bottom: 3rem;
`;

export const InfoSection = styled.div`
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #dfdfdf;
  padding-bottom: 1.5rem;
  @media only screen and (max-width: 576px) {
    padding-bottom: 2rem;
  }
  & > :not(:last-child) {
    margin-bottom: 3rem;
  }
`;

export const IconSection = styled.div<{ icon: string; imageSize?: number }>`
  flex-basis: 20%;
  display: flex;
  background: url(${({ icon }) => icon}) no-repeat center 20%;
  background-size: ${({ imageSize = 100 }) => `${imageSize}%`};
  border-bottom: solid 1px #dfdfdf;
  @media only screen and (max-width: 576px) {
    background: url(${({ icon }) => icon}) no-repeat center 40%;
    background-size: ${({ imageSize = 100 }) => `${imageSize}%`};
    padding-bottom: 2rem;
  }
`;

export const PointWrapper = styled.div`
  display: flex;
`;

export const PointDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-size: ${props => props.theme.fontSize.xs};
  color: ${props => props.theme.colors.textAlt};
`;

export const PointText = styled.div`
  font-size: ${props => props.theme.fontSize.s};
  font-family: ${props => props.theme.fonts.primary};

  display: flex;
  flex-basis: 90%;
  flex-direction: column;
  color: ${props => props.theme.colors.textAlt};
`;

export const SmallIcon = styled.div<{ icon: string }>`
  flex-basis: 10%;

  display: flex;
  background: url(${({ icon }) => icon}) no-repeat left center;
  background-size: contain;
  width: 20px;
  height: 20px;
  ${rtlMixin(css<{ icon: string }>`
    background: url(${({ icon }) => icon}) no-repeat right center;
    background-size: contain !important; // magic
  `)}
`;

export const CheckIcon = styled.div`
  flex-basis: 10%;
  font-weight: bold;
`;

export const AccentText = styled.div`
  flex-basis: 10%;
  font-weight: bold;
  text-transform: uppercase;
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.s};
  font-style: italic;
  line-height: 1.71;
  color: #4a90e2;
  margin-left: 1rem;
`;

export const FooterAccentText = styled.div<{ noBorder?: boolean }>`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: bold;
  color: ${props => props.theme.colors.textAlt};
  margin-bottom: 15px;
  ${({ noBorder }) => !noBorder && 'border-bottom: solid 1px #dfdfdf;'};
  padding: 4px 10px;
  text-align: center;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StoreName = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: 600;
  color: ${props => props.theme.colors.textAlt};
`;

export const FooterStoresWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
  & > div {
    display: flex;
    align-items: flex-end;
    font-size: ${props => props.theme.fontSize.xs};
    flex-basis: 45%;
    ${isIE && 'width: 45%;'}
    @media only screen and (max-width: 576px) {
      justify-content: space-between;
    }
  }
  & > div:first-child {
    margin-right: 35px;
    ${rtlMixin(css`
      margin-right: 0 !important;
    `)}
    position: relative;
    @media only screen and (max-width: 576px) {
      padding-bottom: 10px;
      margin: 0 0 10px;
      border-bottom: solid 1px #dfdfdf;
    }
    ::after {
      width: 1px;
      height: calc(100% + 10px);
      position: absolute;
      top: -5px;
      right: -16px;
      content: '';
      background-color: #dbdbdb;
      ${rtlMixin(css`
        right: auto;
        left: -10px;
      `)}
      @media only screen and (max-width: 576px) {
        width: 0;
      }
    }
  }
`;

export const StoreDistance = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 1 auto;
  align-items: flex-end;
  font-size: ${props => props.theme.fontSize.xs};
  color: ${props => props.theme.colors.textAlt};
  @media only screen and (max-width: 576px) {
    flex-grow: 0;
  }
`;

export const StoreWrapper = styled.div`
  font-size: ${props => props.theme.fontSize.xxs};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${isIE && 'width: 100%;'}
  span {
    font-size: ${props => props.theme.fontSize.xs};
    color: ${props => props.theme.colors.textAlt};
  }
`;

export const NotFoundText = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
