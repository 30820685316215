import React, { Component } from 'react';
import { InfoWindow, GoogleMap } from '@react-google-maps/api';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { isMobile } from 'react-device-detect';
import { TFunction } from 'i18next';

import { WhiteLabelUi } from '_common/whitelabelConfig';
import commonStoresActions from '_common/actions';
import { IStore } from 'types/store';
import LocationStore from '_common/stores/locationStore';
import Marker from 'pages/locate/components/Marker';
import {
  MAX_DISTANCE,
  normalizeStoreName,
} from 'pages/locate/utils/locationUtils';
import {
  InfoAddress,
  InfoDistance,
  InfoHeader,
  InfoTitle,
  LocateMapGlobalStyles,
} from './elements';

interface Props {
  locationStore: LocationStore;
  isPortrait?: boolean;
  translateFunc: TFunction;
  onMapLoad: (map: google.maps.Map) => Promise<void>;
}

@observer
class LocateMap extends Component<Props> {
  disposeReaction: IReactionDisposer;

  googleMapRef: google.maps.Map;

  componentDidMount() {
    this.disposeReaction = reaction(
      () => this.props.locationStore.stores,
      () => {
        this.setBounds();
      }
    );
  }

  setBounds = () => {
    const {
      locationStore: {
        stores,
        isNewLocationSearch,
        mapDragActive,
        mapCenterCoordinates,
      },
    } = this.props;
    if (!isNewLocationSearch || mapDragActive) return;
    const bounds = new google.maps.LatLngBounds();

    if (!stores.length) {
      bounds.extend({
        lat: mapCenterCoordinates.lat - 0.1,
        lng: mapCenterCoordinates.lng - 0.1,
      });
      bounds.extend({
        lat: mapCenterCoordinates.lat + 0.1,
        lng: mapCenterCoordinates.lng + 0.1,
      });
    } else {
      stores.forEach(({ geo }) => {
        bounds.extend({ lat: geo.lat, lng: geo.lon });
      });
    }
    this.googleMapRef.fitBounds(bounds);
  };

  get mapCenter() {
    const { lat, lng } = this.googleMapRef.getCenter();
    return {
      lat: lat(),
      lng: lng(),
    };
  }

  get mapVisibleDistance() {
    const { google } = window;
    const SW = this.googleMapRef.getBounds().getSouthWest();
    const NE = this.googleMapRef.getBounds().getNorthEast();
    const SE = { lat: SW.lat, lng: NE.lng };
    const NW = { lat: NE.lat, lng: SW.lng };
    const geoLib = google.maps.geometry.spherical;
    const mapSideA = geoLib.computeDistanceBetween(
      NW as google.maps.LatLng,
      SW
    );
    const mapSideB = geoLib.computeDistanceBetween(
      SW as google.maps.LatLng,
      SE as google.maps.LatLng
    );
    const shortestMapSide = mapSideA - mapSideB < 0 ? mapSideA : mapSideB;
    const searchRadiusInKm = Math.floor(shortestMapSide / 2 / 1000);
    return searchRadiusInKm > MAX_DISTANCE
      ? MAX_DISTANCE
      : searchRadiusInKm || 1;
  }

  onMarkerClick = (_, storeData: IStore) => {
    commonStoresActions.setActiveStoreId(storeData.storeId);
  };

  handleDrag = async () => {
    try {
      await commonStoresActions.searchStoresByCoords(
        this.mapCenter,
        true,
        this.mapVisibleDistance
      );
    } catch (error) {
      console.error('Error while dragging', error);
    }
  };

  get normalizedStoreName() {
    const {
      locationStore: { activeStoreData },
    } = this.props;
    return activeStoreData
      ? normalizeStoreName(
          activeStoreData.storeName,
          activeStoreData.locationType
        )
      : null;
  }

  renderStoreInfo = () => {
    const { activeStoreData } = this.props.locationStore;
    if (!activeStoreData) {
      return this.props.translateFunc('storeInfo');
    }

    const {
      place: {
        address: { line1, line2, town, postcode },
      },
      locationInfo,
    } = activeStoreData;

    const storeAddress = [line1 || line2, town, postcode]
      .filter(Boolean)
      .join(', ');

    const storeDistance = this.props.locationStore.getDistanceToLastSearch(
      locationInfo
    );

    return (
      <>
        <InfoHeader>
          <InfoTitle>{this.normalizedStoreName}</InfoTitle>
          <InfoDistance>{storeDistance}</InfoDistance>
        </InfoHeader>
        <InfoAddress>{storeAddress}</InfoAddress>
      </>
    );
  };

  renderInfoWindow = () => {
    const { activeStoreData } = this.props.locationStore;
    return activeStoreData ? (
      <InfoWindow
        position={{
          lat: activeStoreData.geo.lat,
          lng: activeStoreData.geo.lon,
        }}
        options={{ pixelOffset: new google.maps.Size(0, -36) }}
      >
        {this.renderStoreInfo()}
      </InfoWindow>
    ) : null;
  };

  renderStoreMarker = (storeData: IStore) => {
    const { storeId, geo, locationType } = storeData;

    return (
      <Marker
        key={storeId}
        storeId={storeId}
        lat={geo.lat}
        lng={geo.lon}
        locationType={locationType}
        storeData={storeData}
        onClick={this.onMarkerClick}
      />
    );
  };

  render() {
    const {
      locationStore: { stores, mapCenterCoordinates },
    } = this.props;
    return (
      <>
        <LocateMapGlobalStyles />
        <GoogleMap
          onLoad={map => {
            this.googleMapRef = map;
            this.props.onMapLoad(map);
          }}
          mapContainerClassName="rpc-locate-map"
          center={WhiteLabelUi.common.defaultMapCenter}
          zoom={5}
          onDragEnd={this.handleDrag}
        >
          <Marker
            isDefaultUser
            lat={mapCenterCoordinates.lat}
            lng={mapCenterCoordinates.lng}
          />
          {isMobile && this.renderInfoWindow()}
          {stores.map(this.renderStoreMarker)}
        </GoogleMap>
      </>
    );
  }
}

export default compose(inject('locationStore'))(LocateMap);
