import styled from 'styled-components';
import { LoaderWrapper } from '_common/components/PageStates/element';
import { isIE } from 'react-device-detect';

export const Wrapper = styled.div`
  ${LoaderWrapper} {
    height: 550px;
  }
  & #secureFrame,
  #secureFrameIE {
    height: 550px;
    border: 0;
    border-radius: 3px;
    width: 100%;

    ${isIE &&
    `
      width: 120%;
      overflow: hidden;
    `};
  }
  & #secureFrame {
    height: 550px;
  }
`;
