import styled from 'styled-components';

export const StyledLink = styled.a`
  font-size: ${props => props.theme.fontSize.m};
  font-weight: 500;
  color: ${props => props.theme.colors.text};
  text-decoration: underline;
  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
