import * as qs from 'qs';
import {
  RETURNS_PORTAL_SERVICE_POSTFIX,
  ROOT_CARRIER_ID,
} from '_common/constants/common';
import { IProxyService } from 'types/whitelabelTypes';

const proxyService: IProxyService = {
  mapFrontAddressToBack: values => ({
    Address1: values.addressLine1,
    Address2: values.addressLine2,
    City: values.city,
    State: values.state,
    Zip5: values.postcode,
  }),
  mapBackAddressToFront: values => ({
    addressLine1: values.Address1,
    addressLine2: values.Address2,
    city: values.City,
    state: values.State,
    postcode: values.Zip5,
  }),
  createReturn: async function createReturn(body) {
    const res = await this.apiClient.post(
      `/v1/returns-portal-service${RETURNS_PORTAL_SERVICE_POSTFIX}/${ROOT_CARRIER_ID}/create-return`,
      body
    );
    return res.data;
  },
  createShipments: async function createShipments(body) {
    const res = await this.apiClient.post(
      `/v1/returns-portal-service${RETURNS_PORTAL_SERVICE_POSTFIX}/${ROOT_CARRIER_ID}/create-order`,
      body
    );
    return res.data;
  },
  getShipments: async function getShipments(id) {
    const res = await this.apiClient.get(
      `/v1/returns-portal-service${RETURNS_PORTAL_SERVICE_POSTFIX}/${ROOT_CARRIER_ID}/order-status/${id}?ts=${Date.now()}`
    );
    return res.data;
  },
  validateAddress: async function validateAddress(address) {
    const queryAddress = qs.stringify(address);
    const res = await this.apiClient.get(
      `/v1/returns-portal-service${RETURNS_PORTAL_SERVICE_POSTFIX}/${ROOT_CARRIER_ID}/validate-address?${queryAddress}`
    );
    return res.data;
  },
  locateAddress: async function locateAddress(zipCode) {
    const queryAddress = qs.stringify(zipCode);
    const res = await this.apiClient.get(
      `/v1/returns-portal-service${RETURNS_PORTAL_SERVICE_POSTFIX}/${ROOT_CARRIER_ID}/locate-address?${queryAddress}`
    );
    return res.data;
  },
};

export default proxyService;
