import styled, { css } from 'styled-components';
import { rtlMixin } from '_common/constants/theme';

const MARKETING_IMAGE_HEIGHT = 800;

export const PrimaryText = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.xxl};
  font-weight: bold;
  color: #231f20;
  margin-bottom: 1rem;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const SecondaryText = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.l};
  color: #231f20;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }
`;

export const Content = styled.div<{ centered: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 50rem;
  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
      align-items: center;
    `};
  @media only screen and (max-width: 768px) {
    min-height: auto;
  }
`;

export const ContentBody = styled.div`
  flex: 1 1 auto;
`;

export const MarketingImage = styled.a.attrs(() => ({
  rel: 'noopener noreferrer',
}))<{
  isMobile?: boolean;
}>`
  min-width: 34rem;
  max-width: 34rem;
  height: ${MARKETING_IMAGE_HEIGHT}px;
  ${props =>
    !props.theme.config.marketingUrl &&
    css`
      pointer-events: none;
    `};
  background-image: url(${props => props.theme.assets.marketingImage});
  background-size: cover;
  border-top-right-radius: 2px;
  @media only screen and (max-width: 768px) {
    display: none;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: none;
      @media only screen and (max-width: 768px) {
        display: block;
        height: 200px;
        max-width: 100%;
      }
    `};
`;

export const LatestStatusDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
  ${rtlMixin(css`
    margin-left: initial;
    margin-right: 3rem;
  `)}
  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

export const LatestStatus = styled.div`
  border-bottom: solid 1px #dbdbdb;
  background-color: ${props => props.theme.colors.defaultBackground};

  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 2rem 1.5rem;
  @media only screen and (max-width: 768px) {
    padding: 0 0 2rem;
    margin: 0 2rem;
  }
`;

export const LatestStatusSuccessIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 54px;
  height: 54px;

  margin-left: 4px;

  & img {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
