/* eslint camelcase: 0 */
// eslint-disable-next-line max-classes-per-file
import links from '_common/routes/urls';
import { withRouter } from 'react-router-dom';
import React from 'react';
import { CancelToken, CancelTokenSource } from 'axios';
import {
  PAYMENT_TYPES,
  RETURNS_PORTAL_BUILD_NAME,
  ROOT_ORG_ID,
} from '_common/constants/common';
import { includes } from 'lodash';
import moment from 'moment';
import { IDetailsFormFields, IFormErrors } from 'types/formFields';
import i18nService from '_common/services/i18nService';
import { ICreateOrderConfig } from 'types/order';
import {
  WhiteLabelConstants,
  WhiteLabelUtils,
  WhiteLabelUi,
} from '_common/whitelabelConfig';
import { DirectionType } from 'antd/lib/config-provider';
import { EAPP_LOCALES } from 'types/core';

/**
 * Converts string to websafe format.
 * @param str
 * @returns {string}
 */
export const toWebSafeFormat = (str: string): string =>
  str
    .toUpperCase()
    .replace(/[^A-Z0-9_\s]/g, '')
    .trim()
    .replace(/\s+/g, '_');

/**
 * Parse current url and returns merchant's organisation.
 */
export const getMerchantFromUrl = () => {
  const existingRoutes = Object.values(links)
    .map(path => path.replace('/:company', '').split('/')[1])
    .filter(p => p);
  const [company] = window.location.pathname.split('/').filter(p => p);
  if (company && existingRoutes.indexOf(company) === -1) {
    return toWebSafeFormat(company);
  }
  console.error('invalid company name::', company);
};

/**
 * Return config for creating shipment.
 */
export const getShipmentConfig = ({
  isIntegratedFlow,
  detailsPageStoreFormFields,
  startPageStoreFormFields,
  companyConfig,
  securePayData,
  intergratedUserProducts,
  intergratedOrderInfo,
  isPaperless,
  returnMethodData,
}): ICreateOrderConfig => {
  const { PRODUCT_NAME } = WhiteLabelConstants;
  const {
    fullName,
    addressLine1,
    addressLine2,
    city,
    postcode,
    state,
    confirmTerms,
    itemName,
    firstName,
    lastName,
    kannaLastName,
    kannaFirstName,
    phoneNumber,
    reasonComments,
    returnReason,
    returnItems,
    purchaseDate,
    handlingInstructions,
  } = detailsPageStoreFormFields;
  const { email, orderNumber, ranNumber } = startPageStoreFormFields;
  const {
    // companyName,
    // contactName,
    companyId,
    returns,
    products: {
      [PRODUCT_NAME]: {
        returnCompanyName,
        departmentName,
        showOrderIdOnLabel,
        returnFormFields,
      },
    },
    phoneNumber: toPhoneNumber,
  } = companyConfig;
  const {
    area,
    line1,
    line2,
    postcode: toPostcode,
    town,
  } = returns.addresses[0]; // pick first until selecting address feauture is implemented

  let config: ICreateOrderConfig = {
    companyId,
    orderNumber,
    appName: RETURNS_PORTAL_BUILD_NAME,
    additionalInfo: 'Return',
    ...(isIntegratedFlow
      ? {
          externalOrderId: orderNumber /** for integrated flow externalOrderId is responsible for check for returned products on BE  */,
          from: WhiteLabelUtils.convertFromAddressIntegrated(
            email,
            intergratedOrderInfo
          ),
          returns: WhiteLabelUtils.convertReturns({
            returnItems: `${intergratedUserProducts.length}`,
            purchaseDate: intergratedOrderInfo.orderDate
              ? moment(intergratedOrderInfo.orderDate)
                  .locale('en_GB')
                  .format('DD-MM-YYYY')
              : intergratedOrderInfo.orderDate,
            confirmTerms: true,
            returnReason: null,
            reasonComments: null,
            returnsAuthorisationNumber: ranNumber,
            isIntegrated: true,
          }),
          products: intergratedUserProducts,
        }
      : {
          ...(returnFormFields?.orderId &&
            showOrderIdOnLabel && {
              externalOrderId: orderNumber /** for non-integrated flow externalOrderId is responsible for displayng orderId on label */,
            }),
          from: WhiteLabelUtils.convertFromAddress({
            postcode,
            phoneNumber,
            email,
            fullName,
            firstName,
            lastName,
            kannaLastName,
            kannaFirstName,
            addressLine1,
            addressLine2,
            city,
            state,
          }),
          returns: WhiteLabelUtils.convertReturns({
            purchaseDate: purchaseDate ? moment(purchaseDate) : purchaseDate,
            returnItems,
            returnReason,
            confirmTerms:
              WhiteLabelUi.pages.details.skipConfirmTerms || confirmTerms,
            reasonComments,
            returnsAuthorisationNumber: ranNumber,
            isIntegrated: false,
          }),
        }),
    to: WhiteLabelUtils.convertToAddress({
      line1,
      line2,
      town,
      area,
      returnCompanyName,
      departmentName,
      toPostcode,
      toPhoneNumber,
    }),
    ...(securePayData && {
      consumerPaymentWasSuccessful: securePayData.resultText === 'Approved',
      payment: {
        paymentDateTime: securePayData.timestamp,
        securePayData: securePayData,
      },
    }),
    ...(itemName && { itemName }),
    printInStore: isPaperless,
  };

  if (WhiteLabelUtils.addSpecificFields) {
    config = WhiteLabelUtils.addSpecificFields(config, {
      returnMethodData,
      handlingInstructions,
    });
  }

  return config;
};

export const ScrollToTop = withRouter(
  class ScrollToTopWithoutRouter extends React.Component<any> {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0);
      }
    }

    render() {
      return null;
    }
  }
);

interface loadScriptParams {
  scriptId: string;
  successCallback: () => void;
  errorCallback: () => void;
  url: string;
  throttle: number;
  remainingAttempts: number;
}

const DEFAULT_REQUIRE_ERROR = {
  START: i18nService.t('utils:allFeildsError'),
  DETAILS: i18nService.t('utils:reqFieldsError'),
};

/** Build array of form errors */
export const getArrayOfErrors = (formErrors: IFormErrors, PAGE = 'START') => {
  const requireFieldsMsg =
    DEFAULT_REQUIRE_ERROR[PAGE] || DEFAULT_REQUIRE_ERROR.START;
  return Object.values(formErrors).map(({ errors }) =>
    /is required/.test(errors[0]) ? requireFieldsMsg : errors[0]
  );
};

/**
 * Checks is payment page should be rendered or not.
 * paymentType
 */
export const isPaymentPageAvailable = (paymentType: string) =>
  includes([PAYMENT_TYPES.CONSUMER_PAID, PAYMENT_TYPES.BOTH_PAID], paymentType);

/**
 * Return custom errors from address validation function.
 * @param values
 */
export const getErrorsOfAddressValidation = (values: IDetailsFormFields) => [
  {
    name: 'addressLine1',
    value: values.addressLine1,
    errors: [i18nService.t('constants:AUS_ADDRESS_FIELD_INVALID')],
  },
  {
    name: 'city',
    value: values.city,
    errors: [i18nService.t('constants:AUS_ADDRESS_FIELD_INVALID')],
  },
  {
    name: 'state',
    value: values.state,
    errors: [i18nService.t('constants:AUS_ADDRESS_FIELD_INVALID')],
  },
  {
    name: 'postcode',
    value: values.postcode,
    errors: [i18nService.t('constants:AUS_ADDRESS_FIELD_INVALID')],
  },
];

export const createFormFromModel = (model, constructor) => {
  const formData = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in model) {
    if (Object.prototype.hasOwnProperty.call(model, key)) {
      if (key === 'purchaseDate') {
        formData[key] = constructor.createFormField({
          value: model.purchaseDate
            ? moment(model.purchaseDate)
            : model.purchaseDate,
        });
      } else {
        formData[key] = constructor.createFormField({
          value: model[key],
        });
      }
    }
  }
  return formData;
};

class RequestCancelQueue {
  controllersMap: {
    [key: string]: CancelTokenSource;
  } = {};

  enqueueNewRequest(methodPath: string[]) {
    const key = methodPath.join('-');
    if (this.controllersMap[key]) {
      this.controllersMap[key].cancel();
    }
    // @ts-ignore
    this.controllersMap[key] = CancelToken.source();
    const cancelToken = this.controllersMap[key].token;
    return { cancelToken, controller: this.controllersMap[key] };
  }
}

export const requestQueue = new RequestCancelQueue();

export const extractReasonCommentFromProduct = (
  order,
  returnReason,
  reasonComments
) => {
  // reasonComments can be empty string
  if (reasonComments === undefined && !returnReason) {
    return;
  }
  order.returnReason = returnReason
    ? { code: returnReason.code, description: returnReason.description }
    : {};
  order.reasonComments = reasonComments;
  return order;
};

export const removeUnsupportedPropsFromProduct = product => {
  if (!product) return;
  // priceCurrency is TEMPORAL WORKAROUND AS NO SERVICE-LAYER SUPPORT YET
  const {
    priceCurrency,
    isNotReturnable,
    purchaseDate,
    returnWindow,
    isApproved,
    ...rest
  } = product;
  return rest;
};

export const truncate = (str: string, maxLength: number): string =>
  str.length > maxLength ? `${str.substr(0, maxLength)}\u2026` : str;

export const MERCHANT_ORGANISATION = getMerchantFromUrl();

export const formatTermsAndConditionUrl = (url: string) =>
  url ? `https://${url.replace(/(^\w+:|^)\/\//, '')}` : '#';

export const getItemReturnDate = (pickupDate: string) =>
  moment(pickupDate).format('Do MMMM YYYY');

export const isInternationalCountry = (country: string) =>
  (ROOT_ORG_ID === 'DODDLE' && country && country !== 'US') ||
  (ROOT_ORG_ID === 'STARLINKS' && country && country !== 'SA') ||
  (ROOT_ORG_ID === 'YAMATO' && country && country !== 'JP');

export const resetMobileZoom = () => {
  const viewportmeta = document.querySelector('meta[name="viewport"]');
  if (viewportmeta) {
    viewportmeta.setAttribute(
      'content',
      'width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0, shrink-to-fit=no'
    );
    viewportmeta.setAttribute(
      'content',
      'width=device-width, minimum-scale=1.0, initial-scale=1.0, shrink-to-fit=no'
    );
  }
};

export const getLayoutDirection = (locale: EAPP_LOCALES): DirectionType => {
  switch (locale) {
    case EAPP_LOCALES.AR:
      return 'rtl';
    default:
      return 'ltr';
  }
};
