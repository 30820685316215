import { action, observable, runInAction } from 'mobx';
import { getMerchantFromUrl } from '_common/utils';
import { MOBX_STORES, sessionStore } from 'storage';
import { EAsyncStatus, EPrintOptions } from 'types/core';
import locationService from '_common/services/locationService';
import { get } from 'lodash';
import { TFetchNearestStore, LatLng, IChannelStoreModel } from 'types/store';
import qs from 'qs';
import i18nService from '_common/services/i18nService';

class PrintOptionStore {
  @observable
  selectedOption: null | EPrintOptions = null;

  @observable
  geoCoordinatesOfAddress: null | LatLng;

  @observable
  nearestStores = {
    [EPrintOptions.PAPERLESS]: [],
    [EPrintOptions.PRINT_OWN]: [],
  };

  @observable
  asyncStatus = EAsyncStatus.IDLE;

  registerCommonActions = commonStoresActions => {
    commonStoresActions.validateSession(getMerchantFromUrl());
    const savedStoreState = sessionStore.get(MOBX_STORES.PrintOptionPageStore);

    const { printInStore } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (printInStore) {
      this.selectedOption = EPrintOptions.PAPERLESS;
    } else {
      this.selectedOption = get(savedStoreState, 'selectedOption', null);
    }

    this.nearestStores[EPrintOptions.PAPERLESS] = get(
      savedStoreState,
      'nearestStores.paperless',
      []
    );
    this.nearestStores[EPrintOptions.PRINT_OWN] = get(
      savedStoreState,
      'nearestStores.printOwn',
      []
    );
    this.geoCoordinatesOfAddress = get(
      savedStoreState,
      'geoCoordinatesOfAddress',
      []
    );
    if (this.selectedOption && this.nearestStores[this.selectedOption]) {
      this.asyncStatus = EAsyncStatus.SUCCESS;
    }
  };

  fetchStoreByLocationType: TFetchNearestStore = (
    { lat, lng },
    locationTypes,
    acceptsPaperless,
    resultsAmount = 1
  ) =>
    locationService.getGeoQueryStores(
      {
        lat,
        lng,
        locationTypes,
        extendedHours: false,
        limit: resultsAmount,
        acceptsPaperless,
      },
      null
    );

  getTwoUniqueStores = (
    postOffice: IChannelStoreModel,
    otherStores: IChannelStoreModel[]
  ) =>
    [
      postOffice,
      otherStores.find(
        store => store.store.storeId !== postOffice.store.storeId
      ),
    ].filter(Boolean);

  @action
  getClosestStores = async ({
    address,
    countryCode,
    isPrinterOptionDisabled,
  }) => {
    this.asyncStatus = EAsyncStatus.LOADING;
    try {
      let results;
      try {
        results = await locationService.getCoordinates({
          address,
          region: countryCode,
        });
      } catch {
        throw new Error(i18nService.t('printOption:coordinatesError'));
      }
      if (!results.length) return;
      const coords = results[0].geometry.location.toJSON();

      // Starlinks and Yamato flow
      if (isPrinterOptionDisabled) {
        const [paperlessPostOffice, paperless3rdParty] = await Promise.all([
          this.fetchStoreByLocationType(coords, null, false),
          this.fetchStoreByLocationType(coords, null, false, 2),
        ]);
        runInAction(() => {
          this.asyncStatus = EAsyncStatus.SUCCESS;
          this.geoCoordinatesOfAddress = coords;
          this.nearestStores[EPrintOptions.PAPERLESS] = this.getTwoUniqueStores(
            paperlessPostOffice[0],
            paperless3rdParty
          );
        });
        return;
      }

      // default flow
      const res = await locationService.getNearestLocations(
        coords,
        getMerchantFromUrl()
      );

      runInAction(() => {
        this.asyncStatus = EAsyncStatus.SUCCESS;
        this.geoCoordinatesOfAddress = coords;
        Object.values(EPrintOptions).forEach(storeType => {
          if (!res[storeType]) return;
          this.nearestStores[storeType] = res[storeType];
        });
      });
    } catch (e) {
      runInAction(() => {
        this.asyncStatus = EAsyncStatus.FAILED;
      });
    }
  };

  @action
  setPrintOption = (option: EPrintOptions) => {
    this.selectedOption = option;
  };

  @action
  resetStore = () => {
    this.selectedOption = null;
    this.asyncStatus = EAsyncStatus.IDLE;
  };
}

export default PrintOptionStore;
