import { doddleLogo, phoneIcon, printerIcon } from 'assets';
import {
  validateOnSpecialCharacters,
  validateOnSpecialCharactersAddress,
} from '_common/whitelabelConfig/DODDLE_USPS/_utils';
import { TRACKING_EVENT_TYPES } from '_common/whitelabelConfig/DODDLE_USPS/_constants';
import {
  starActive,
  starDisabled,
} from '_common/whitelabelConfig/DODDLE_USPS/_assets';
import { IWhiteLabelUi } from 'types/whitelabelTypes';
import i18nService from '_common/services/i18nService';
import {
  EDETAILS_ADDRESS_FIELD,
  EAPP_LOCALES,
  EPrintOptions,
} from 'types/core';
import { validatePhoneNumber } from '_common/utils/validationUtils';
import { ALLOWED_DATEPICKER_FORMATS } from '_common/constants/date';

const config: IWhiteLabelUi = {
  common: {
    carrierLogo: {
      src: doddleLogo,
      alt: 'Easy returns by Doddle',
      dimensions: {
        desktop: {
          width: 100,
          height: 24,
        },
        mobile: {
          width: 100,
          height: 24,
        },
      },
    },
    countryCode: 'US',
    availableLanguages: [EAPP_LOCALES.EN],
    isPrinterOptionDisabled: false,
    header: {
      showCarrierLogo: false,
      centeredLogo: true,
    },
    footer: {
      isHidden: false,
      showCarrierLogo: true,
      logoStacked: false,
      termsAndConditions: {
        href:
          'https://company-assets.apac-prod.doddle.tech/RETURNS_PORTAL/TERMS_AND_CONDITIONS_USPS/Terms+of+Use+-+Returns+Platform+(US).pdf',
        label: 'footerTermsLabel', // withTranslation
      },
      privacy: {
        href:
          'https://company-assets.apac-prod.doddle.tech/RETURNS_PORTAL/TERMS_AND_CONDITIONS_USPS/Privacy+Policy+and+Cookie+Notice+-+Returns+Platform+(US).pdf',
        label: 'footerPrivacyLabel', // withTranslation
      },
    },
    defaultMapCenter: {
      lat: 40.690411,
      lng: -74.043486,
    },
    isConsumerTextEnabled: (isIntegrated: boolean) => isIntegrated,
  },
  pages: {
    printOption: {
      [EPrintOptions.PAPERLESS]: {
        title: 'printPaperlessTitle', // withTranslation
        nearestLocationText: 'printPaperlessLocation', // withTranslation
        imageSize: 60,
        icon: phoneIcon,
        points: [
          { icon: phoneIcon, text: 'printPaperlessPoint1' }, // withTranslation
          {
            icon: phoneIcon,
            text: 'printPaperlessPoint2', // withTranslation
            includes: [{ text: 'printPaperlessPoint2includes1' }], // withTranslation
          },
        ],
      },
      [EPrintOptions.PRINT_OWN]: {
        title: 'printOwnTitle', // withTranslation
        nearestLocationText: 'printOwnLocation', // withTranslation
        imageSize: 100,
        icon: printerIcon,
        points: [
          { icon: printerIcon, text: 'printOwnPoint1' }, // withTranslation
          {
            icon: printerIcon,
            text: 'printOwnPoint2', // withTranslation
            includes: [
              { text: 'printOwnPoint2includes1' }, // withTranslation
              { text: 'printOwnPoint2includes2' }, // withTranslation
              { text: 'printOwnPoint2includes3' }, // withTranslation
            ],
          },
        ],
      },
    },
    start: {
      titleText: 'titleText', // withTranslation
      subTitleText: 'subTitleText', // withTranslation
      returnIsFreeText: 'startFree', // withTranslation
      ranPlaceholder: 'startPlaceholder', // withTranslation
      costText: 'startCost', // withTranslation
    },
    details: {
      dateFormates: ALLOWED_DATEPICKER_FORMATS,
      orderedFiledNames: [
        [EDETAILS_ADDRESS_FIELD.PHONE],
        [EDETAILS_ADDRESS_FIELD.ADDRESS1, EDETAILS_ADDRESS_FIELD.ADDRESS2],
        [EDETAILS_ADDRESS_FIELD.CITY, EDETAILS_ADDRESS_FIELD.STATE],
        [EDETAILS_ADDRESS_FIELD.ZIP],
      ],
      getStateLabel: item => `${item.value} - ${item.label}`,
      textValues: {
        purchaseDateLabel: 'detailsPurchaseDate', // withTranslation
        purchaseDatePlaceholder: 'detailsPurchaseDatePlaceholder', // withTranslation
        purchaseDateRequiredErr: 'detailsPurchaseDateRequiredErr', // withTranslation
        returnReasonRequiredErr: 'detailsReturnReasonRequiredErr', // withTranslation
        address1Label: 'address1Label', // withTranslation
        address2Label: 'address2Label', // withTranslation
        address1Placeholder: 'address1Placeholder', // withTranslation
        address2Placeholder: 'address2Placeholder', // withTranslation
        cityLabel: 'detailsCity', // withTranslation
        cityPlaceholder: 'detailsCityPlaceholder', // withTranslation
        areaLabel: 'detailsArea', // withTranslation
        areaPlaceholder: 'detailsAreaPlaceholder', // withTranslation
        isZipVisible: true,
        zipText: 'detailsZip', // withTranslation
        zipLength: 10,
        zipPlaceholder: 'detailsZipPlaceholder', // withTranslation
        tcUrl:
          'https://company-assets.apac-prod.doddle.tech/RETURNS_PORTAL/TERMS_AND_CONDITIONS_USPS/Terms+of+Use+-+Returns+Platform+(US).pdf',
        countryStates: [
          { value: 'AL', label: 'Alabama' },
          { value: 'AK', label: 'Alaska' },
          { value: 'AZ', label: 'Arizona' },
          { value: 'AR', label: 'Arkansas' },
          { value: 'CA', label: 'California' },
          { value: 'CO', label: 'Colorado' },
          { value: 'CT', label: 'Connecticut' },
          { value: 'DE', label: 'Delaware' },
          { value: 'FL', label: 'Florida' },
          { value: 'GA', label: 'Georgia' },
          { value: 'HI', label: 'Hawaii' },
          { value: 'ID', label: 'Idaho' },
          { value: 'IL', label: 'Illinois' },
          { value: 'IN', label: 'Indiana' },
          { value: 'IA', label: 'Iowa' },
          { value: 'KS', label: 'Kansas' },
          { value: 'KY', label: 'Kentucky' },
          { value: 'LA', label: 'Louisiana' },
          { value: 'ME', label: 'Maine' },
          { value: 'MD', label: 'Maryland' },
          { value: 'MA', label: 'Massachusetts' },
          { value: 'MI', label: 'Michigan' },
          { value: 'MN', label: 'Minnesota' },
          { value: 'MS', label: 'Mississippi' },
          { value: 'MO', label: 'Missouri' },
          { value: 'MT', label: 'Montana' },
          { value: 'NE', label: 'Nebraska' },
          { value: 'NV', label: 'Nevada' },
          { value: 'NH', label: 'New Hampshire' },
          { value: 'NJ', label: 'New Jersey' },
          { value: 'NM', label: 'New Mexico' },
          { value: 'NY', label: 'New York' },
          { value: 'NC', label: 'North Carolina' },
          { value: 'ND', label: 'North Dakota' },
          { value: 'OH', label: 'Ohio' },
          { value: 'OK', label: 'Oklahoma' },
          { value: 'OR', label: 'Oregon' },
          { value: 'PA', label: 'Pennsylvania' },
          { value: 'RI', label: 'Rhode Island' },
          { value: 'SC', label: 'South Carolina' },
          { value: 'SD', label: 'South Dakota' },
          { value: 'TN', label: 'Tennessee' },
          { value: 'TX', label: 'Texas' },
          { value: 'UT', label: 'Utah' },
          { value: 'VT', label: 'Vermont' },
          { value: 'VA', label: 'Virginia' },
          { value: 'WA', label: 'Washington' },
          { value: 'WV', label: 'West Virginia' },
          { value: 'WI', label: 'Wisconsin' },
          { value: 'WY', label: 'Wyoming' },
        ],
        addressInvalidMsg: i18nService.t('wl:detailsAdressError'), // withTranslation
      },
      rules: {
        fullName: [
          {
            required: true,
            message: i18nService.t('wl:detailsFullnameReq'), // withTranslation
          },
          {
            max: 32,
            message: i18nService.t('wl:detailsFullnameErrAlt'), // withTranslation
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ],
        zipCode: [
          {
            required: true,
            message: `${i18nService.t('wl:detailsZip')} ${i18nService.t(
              'details:isRequired'
            )}.`,
          },
          {
            pattern: /(^\d{5}$)|(^\d{5}[ -]{1}\d{4}$)/,
            message: i18nService.t('wl:detailsZipError'),
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ],
        addressLine1: [
          {
            required: true,
            message: i18nService.t('wl:detailsAddressReq'), // withTranslation
          },
          {
            max: 32,
            message: i18nService.t('wl:detailsAddressErrAlt'), // withTranslation
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharactersAddress(rule, value, callback);
            },
          },
        ],
        addressLine2: [
          {
            max: 32,
            message: i18nService.t('wl:detailsAddress2ErrAlt'), // withTranslation
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharactersAddress(rule, value, callback);
            },
          },
        ],
        city: [
          {
            required: true,
            message: i18nService.t('wl:detailsCityReq'), // withTranslation
          },
          {
            max: 25,
            message: i18nService.t('wl:detailsCityErrAlt'), // withTranslation
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ],
        phoneNumber: [
          {
            required: true,
            whitespace: true,
            message: i18nService.t('details:mobileError'),
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
          {
            validator: validatePhoneNumber, // TODO check if this is valid for USPS
          },
        ],
      },
      noReasonsText: null,
      noActiveReasonsAllowed: true,
      integratedReasonCommentsEnabled: true,
      reasonCommentsRequiredText: 'detailsCommentError', // withTranslation
      wrapReasonCodeText: false,
    },
    tracking: {
      trackersList: [
        {
          type: TRACKING_EVENT_TYPES.ORDER_RETURN_CREATED,
          description: i18nService.t('wl:trackingCreated'),
        },
        {
          type: TRACKING_EVENT_TYPES.PARCEL_REACHED_STORE,
          description: i18nService.t('wl:trackingReached'),
        },
        {
          type: TRACKING_EVENT_TYPES.CARRIER_COLLECTED,
          description: i18nService.t('wl:trackingCollected'),
        },
        {
          type: TRACKING_EVENT_TYPES.BACK_AT_WAREHOUSE,
          description: i18nService.t('wl:trackingBack'),
        },
      ],
    },
    success: {
      textValues: {
        successSearchPlaceholder: 'successPlaceholder', // withTranslation
      },
      distanceUnit: 'mi',
      storeLogosEnabled: false,
      availableTypeOptions: ['PRINT_IN_STORE', 'ALL_LOCATIONS'],
      returnSubtitlePaperless: 'success:returnSubtitlePaperless',
      returnSubtitleOwnLabel: () => 'success:returnSubtitleOwnLabel',
      pdfDownloadEnabled: true,
    },
    ratings: {
      config: {
        title: 'ratingsTitle', // withTranslation
        subTitle: 'ratingsSubtitle', // withTranslation
        icons: {
          starActive,
          starDisabled,
        },
      },
    },
  },
};

export default config;
