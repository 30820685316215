import React from 'react';
import Icon from '@ant-design/icons';

const CheckmarkIconLarge = () => {
  const Svg = () => (
    <svg width="80px" height="80px" viewBox="0 0 80 80" version="1.1">
      <title>Icons / primary tick</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="AP----Request-fork-2"
          transform="translate(-917.000000, -236.000000)"
        >
          <g
            id="Icons-/-primary-tick"
            transform="translate(917.000000, 236.000000)"
          >
            <g id="Group">
              <circle
                id="Oval-Copy"
                fill="#38B13A"
                cx="40"
                cy="40"
                r="40"
              ></circle>
              <path
                d="M33.585775,55.9093319 L20.5857623,42.9093192 C19.8047459,42.1283029 19.8047459,40.8619735 20.5857623,40.080879 L23.4141244,37.2524387 C24.1951408,36.4713442 25.4615483,36.4713442 26.2425647,37.2524387 L34.9999951,46.009791 L53.7574353,27.252429 C54.5384517,26.4714126 55.8048592,26.4714126 56.5858756,27.252429 L59.4142377,30.0808692 C60.1952541,30.8618856 60.1952541,32.128215 59.4142377,32.9093095 L36.4142152,55.9094101 C35.6331207,56.6904265 34.3667914,56.6904265 33.585775,55.9093319 L33.585775,55.9093319 Z"
                id="Shape-Copy-3"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );

  return <Icon component={Svg} />;
};

export default CheckmarkIconLarge;
