import moment from 'moment';
import i18nService from '_common/services/i18nService';

const comparingFormat = 'HH:mm a';

export const generateReturnPickupDates = (): moment.Moment[] => {
  const days = [];
  const currentDate = moment();
  // used to understand is it too late to book a return for today
  const timeLimit = moment('17:00 pm', comparingFormat);
  const skipCurrentDay = currentDate.isAfter(timeLimit);
  let firstAvailableDate = skipCurrentDay
    ? currentDate.clone().add(1, 'day')
    : currentDate;
  while (days.length < 7) {
    days.push(firstAvailableDate);
    firstAvailableDate = firstAvailableDate.clone().add(1, 'day');
  }
  return days;
};

export const generateReturnPickupTimeslots = (pickupDate: string) => {
  const currentDate = moment();
  const isToday = moment(pickupDate).isSame(currentDate, 'day');
  return [
    {
      value: '06',
      label: i18nService.t('utils:dateLabel1'),
      disabled:
        isToday && currentDate.isAfter(moment('17:00 pm', comparingFormat)),
    },
    {
      value: '07',
      label: i18nService.t('utils:dateLabel2'),
      disabled:
        isToday && currentDate.isAfter(moment('10:00 am', comparingFormat)),
    },
    {
      value: '08',
      label: i18nService.t('utils:dateLabel3'),
      disabled:
        isToday && currentDate.isAfter(moment('14:00 pm', comparingFormat)),
    },
    {
      value: '09',
      label: i18nService.t('utils:dateLabel4'),
      disabled:
        isToday && currentDate.isAfter(moment('16:00 pm', comparingFormat)),
    },
    {
      value: '10',
      label: i18nService.t('utils:dateLabel5'),
      disabled:
        isToday && currentDate.isAfter(moment('17:00 pm', comparingFormat)),
    },
  ];
};
