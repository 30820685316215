import { action, observable } from 'mobx';

class PaymentPageStore {
  @observable
  formFields = {};

  @action
  setFormField = (field: string, value: string) => {
    this.formFields[field] = value;
  };
}

export default PaymentPageStore;
