import styled from 'styled-components';

export const StyledInputLabel = styled.label`
  display: block;
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.m} !important;
  line-height: 2;
  font-weight: 600;
  color: ${props => props.theme.colors.text};
`;
