import React from 'react';
import { Redirect, withRouter } from 'react-router';
import { Route, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { endsWith, includes, isEmpty, find } from 'lodash';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import { withWhitelabelProps } from '_common/whitelabelConfig';
import { sessionStore, SUBMITTED_STEPS, MOBX_STORES } from 'storage';
import Main from './Main/Main';
import { isPaymentPageAvailable, resetMobileZoom } from '_common/utils';
import commonStoresActions from '_common/actions';
import { PAYMENT_TYPES } from '_common/constants/common';
import { IRouterMatch } from 'types/core';
import { IWhiteLabelUi } from 'types/whitelabelTypes';
import { IWhitelabelProxyStore, IDetailsStore } from 'types/internal';
import OrderStore from '_common/stores/orderStore';
import DirectoryStore from '_common/stores/directoryStore';

type Props = RouteComponentProps<IRouterMatch> & {
  directoryStore: DirectoryStore;
  whitelabelProxyStore: IWhitelabelProxyStore;
  orderStore: OrderStore;
  detailsPageStore: IDetailsStore;
  merchForRedirect?: string;
  whiteLabeled: { returnMethodParams: Partial<IWhiteLabelUi> };
};

/**
 * NB:
 * we have 2 types of route validation:
 * - check for completed steps in local storage before loading company config;
 * - run page-specific validation with data from company config on page mount;
 * Therefore, the flow of this guard is:
 * - run checkAccess() for current route for steps validation;
 * - on component mount run validateCurrentRoute() with page-specific validation, checks for loaded company config, etc;
 * - on component mount set urls for next() and previous() routes;
 */
@observer
class RouteGuard extends React.Component<Props> {
  redirectUrl = '';

  currentNext?: string;

  currentPrevious?: string;

  methodOptionUrl: string = this.props.whiteLabeled.returnMethodParams
    ? '/return-method'
    : '/print-option';

  componentDidMount() {
    this.validateCurrentRoute();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.validateCurrentRoute();
    }
  }

  /** DETAILS PAGE LOGIC */
  validateDetailsRoute = () => {
    const { merchForRedirect } = this.props;

    this.currentPrevious = `/${merchForRedirect}`;
    this.currentNext = `/${merchForRedirect}${this.methodOptionUrl}`;
  };

  validatePrintOptionRoute = () => {
    const {
      directoryStore: {
        paymentConfig: { paymentType },
        companyConfig,
      },
      merchForRedirect,
    } = this.props;

    const setCurrentNext = paymentConfig => {
      this.currentNext = `/${merchForRedirect}/${
        isPaymentPageAvailable(paymentConfig) ? 'payment' : 'success'
      }`;
    };
    // check if we have company loaded
    if (!isEmpty(companyConfig)) {
      setCurrentNext(paymentType);
    } else {
      reaction(
        () => this.props.directoryStore.paymentConfig,
        (paymentConfig, reactionFunc) => {
          reactionFunc.dispose();
          setCurrentNext(paymentConfig.paymentType);
        }
      );
    }
    this.currentPrevious = `/${merchForRedirect}/details`;
  };

  /** PAYMENT PAGE LOGIC */
  validatePaymentRoute = () => {
    const {
      directoryStore: {
        paymentConfig: { paymentType },
        companyConfig,
      },
      merchForRedirect,
    } = this.props;

    this.currentNext = `/${merchForRedirect}/success`;
    this.currentPrevious = `/${merchForRedirect}${this.methodOptionUrl}`;

    if (!isEmpty(companyConfig)) {
      /** Check payments configs */
      if (!isPaymentPageAvailable(paymentType)) {
        return this.routeNavigatorNext();
      }

      this.handlePaymentPageRedirect();
    } else {
      reaction(
        () => this.props.directoryStore.returnReasons,
        (returnReasons, reactionFunc) => {
          reactionFunc.dispose();
          /** Check is selected on details page reason requires payments . */
          this.handlePaymentPageRedirect();
        }
      );
    }
  };

  handlePaymentPageRedirect = () => {
    const {
      directoryStore: {
        returnReasons,
        paymentConfig: { paymentType },
      },
      detailsPageStore: {
        formFields: { returnReason: selectedByUserReturnReason },
      },
      orderStore: { isIntegratedFlow },
    } = this.props;

    if (isIntegratedFlow) {
      if (!commonStoresActions.isPaymentsRequiredForIntegrated()) {
        return this.routeNavigatorNext();
      }
    } else {
      /** MERCHANT_PAID - always skipped. */
      if (paymentType === PAYMENT_TYPES.MERCHANT_PAID) {
        return this.routeNavigatorNext();
      }

      /** CONSUMER_PAID - depends of reason codes */
      if (paymentType === PAYMENT_TYPES.CONSUMER_PAID) {
        /** All reason codes disabled - consumer must pay. */

        if (
          returnReasons.reasons.length &&
          returnReasons.reasons.every(({ enabled }) => !enabled)
        ) {
          return;
        }

        /** Reason code was selected, checking it's requires a payment. */
        const selectedReason = returnReasons.reasons.find(
          el => el.description?.trim() === selectedByUserReturnReason
        );
        if (!selectedReason) return;
        /** If selected reason not requires payment and enabled (for double-check) - skip payments; */
        if (selectedReason.enabled && !selectedReason.paymentRequired) {
          return this.routeNavigatorNext();
        }
      }

      /** BOTH_PAID - this is depends of selected reasons. */
      if (paymentType === PAYMENT_TYPES.BOTH_PAID) {
        /** If selected reason not requires payment and enabled (for double-check) - skip payments; */
        const reason = find(returnReasons.reasons, {
          description: selectedByUserReturnReason,
        });
        if (reason?.enabled && !reason?.paymentRequired) {
          return this.routeNavigatorNext();
        }
      }
    }
  };

  /** SUCCESS PAGE LOGIC */
  validateSuccessRoute = () => {
    const {
      directoryStore: { companyConfig },
      merchForRedirect,
    } = this.props;
    /** Success page has internal validation of fingerprint, if user redirected after SecureFrame.
     * Need to check here case when payment not required - DetailsPageStore */
    this.currentNext = `/${merchForRedirect}`;
    this.currentPrevious = `/${merchForRedirect}/details`;

    if (isEmpty(companyConfig)) {
      reaction(
        () => this.props.directoryStore.paymentConfig,
        (paymentConfig, reactionFunc) => {
          reactionFunc.dispose();
          const {
            whitelabelProxyStore: { consumerPaymentWasSuccessful },
          } = this.props;

          /** AP specific.
           * 1. Need to check was payment required or not.
           * 2. Need to validate, that's we accessing page with valid fingerprint.
           * */
          if (
            paymentConfig &&
            isPaymentPageAvailable(paymentConfig.paymentType) &&
            !consumerPaymentWasSuccessful
          ) {
            this.currentPrevious = `/${merchForRedirect}/payment`;
            this.routeNavigatorPrevious();
          }
        }
      );
    }
  };

  get currentPrivateRoute() {
    return this.privateRoutes.find(el =>
      endsWith(this.props.location.pathname, el.path)
    );
  }

  validateCurrentRoute = () => {
    this.currentPrivateRoute &&
      this.currentPrivateRoute.checkAccess().canActivate &&
      this.currentPrivateRoute.validateFn();
  };

  privateRoutes = [
    {
      path: '/details',
      checkAccess: () => ({
        canActivate: includes(
          sessionStore.get(SUBMITTED_STEPS),
          MOBX_STORES.StartPageStore
        ),
        redirectUrl: `/${this.props.merchForRedirect}`,
      }),
      validateFn: this.validateDetailsRoute,
    },
    {
      path: '/return-submitted',
      checkAccess: () => ({
        canActivate: includes(
          sessionStore.get(SUBMITTED_STEPS),
          MOBX_STORES.StartPageStore
        ),
        redirectUrl: `/${this.props.merchForRedirect}/details`,
      }),
      validateFn: this.validateDetailsRoute,
    },
    {
      path: this.methodOptionUrl,
      checkAccess: () => ({
        canActivate: includes(
          sessionStore.get(SUBMITTED_STEPS),
          MOBX_STORES.DetailsPageStore
        ),
        redirectUrl: `/${this.props.merchForRedirect}/details`,
      }),
      validateFn: this.validatePrintOptionRoute,
    },
    {
      path: '/payment',
      checkAccess: () => ({
        canActivate:
          includes(
            sessionStore.get(SUBMITTED_STEPS),
            MOBX_STORES.PrintOptionPageStore
          ) ||
          includes(
            sessionStore.get(SUBMITTED_STEPS),
            MOBX_STORES.ReturnMethodPageStore
          ),
        redirectUrl: `/${this.props.merchForRedirect}${this.methodOptionUrl}`,
      }),
      validateFn: this.validatePaymentRoute,
    },
    {
      path: '/success',
      checkAccess: () => ({
        canActivate:
          includes(
            sessionStore.get(SUBMITTED_STEPS),
            MOBX_STORES.PrintOptionPageStore
          ) ||
          includes(
            sessionStore.get(SUBMITTED_STEPS),
            MOBX_STORES.ReturnMethodPageStore
          ),
        redirectUrl: `/${this.props.merchForRedirect}${this.methodOptionUrl}`,
      }),
      validateFn: this.validateSuccessRoute,
    },
  ];

  canActivate = () => {
    let canAccess = true;

    if (this.currentPrivateRoute) {
      const results = this.currentPrivateRoute.checkAccess();
      canAccess = results.canActivate;
      this.redirectUrl = results.redirectUrl;
    } else {
      // currently only Start page is not private
      this.currentNext = `/${this.props.merchForRedirect}/details`;
    }
    return canAccess;
  };

  /**
   * in some cases we can't differ the next url in route guard (e.g. selected item in integrated journey)
   * so we need to be able to pass it as an argument
   */
  routeNavigatorNext = (url?: string) => {
    this.props.history.push(url || this.currentNext);
    resetMobileZoom();
  };

  routeNavigatorPrevious = () => {
    this.props.history.push(this.currentPrevious);
    resetMobileZoom();
  };

  routeWrapper = props =>
    this.canActivate() ? (
      <Main
        {...props}
        merchForRedirect={this.props.merchForRedirect}
        routeNavigator={{
          next: this.routeNavigatorNext,
          previous: this.routeNavigatorPrevious,
        }}
      />
    ) : (
      <Redirect to={this.redirectUrl} />
    );

  render() {
    return <Route exact render={this.routeWrapper} />;
  }
}

export default compose(
  inject(
    'directoryStore',
    'detailsPageStore',
    'orderStore',
    'whitelabelProxyStore'
  ),
  withWhitelabelProps({
    returnMethodParams: 'ui.pages.returnMethod',
  }),
  withRouter
)(RouteGuard);
