import styled from 'styled-components';
import { HEADER_HEIGHT, FOOTER_HEIGHT } from '_common/constants/styles';

export const StyledBackgroundWrapper = styled.div<{
  expandedFooter: boolean;
  isEmbedded: boolean;
  imageSrc?: string;
  isFooterHidden?: boolean;
}>`
  ${({ isEmbedded }) => isEmbedded && 'position: relative;'}
  background-color: ${({ theme }) => theme.colors.defaultBackground};
  transition: background 0.7s ease-in-out;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding: 60px 0;
  min-height: ${({ isEmbedded, isFooterHidden }) =>
    isEmbedded
      ? 'auto'
      : `calc(100vh - ${HEADER_HEIGHT} - ${
          isFooterHidden ? '0px' : FOOTER_HEIGHT
        })`};
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 769px) {
    background: ${({ imageSrc }) => imageSrc};
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }
  @media only screen and (max-width: 768px) {
    ${({ isEmbedded }) => isEmbedded && 'flex-direction: column;'}
    min-height: ${({ expandedFooter, isEmbedded, isFooterHidden }) =>
      isEmbedded
        ? 'auto'
        : expandedFooter
        ? `calc(100vh - 63.5px - ${isFooterHidden ? '0px' : '165px'})`
        : `calc(100vh - 63.5px - ${isFooterHidden ? '0px' : '60px'})`};
    padding: 24px 0 0;
    align-items: flex-start;
  }
  @media only screen and (max-width: 576px) {
    padding: 0;
  }
`;
