import styled, { createGlobalStyle } from 'styled-components';
import { Select as StyledSelect } from 'antd';
import { Icon } from '_common/components';
import { RefObject } from 'react';

export const LocateMapGlobalStyles = createGlobalStyle`
  .rpc-locate-map {
    width: 100%;
    height: 50vh;
    @media only screen and (max-width: 768px) {
      width: 100%;
      height: calc(100vh - 259px);
    }
    @media only screen and (max-width: 768px) and (orientation: landscape) {
      height: 90vh;
    }
  }
`;

export const Root = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
  @media only screen and (max-width: 768px) and (orientation: landscape) {
    display: block;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input<{ ref: RefObject<HTMLInputElement> }>`
  width: 100%;
  height: 50px;
  font-size: ${props => props.theme.fontSize.xl};
  color: #231f20;
  padding-right: 95px;
  padding-left: 20px;
  border: solid 1px rgba(151, 151, 151, 0.25);
`;

export const GeoIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 22px;
  top: 13px;
`;

export const Select = styled(StyledSelect)`
  position: relative;

  &:before {
    content: 'Filter :';
    position: absolute;
    right: auto;
    left: 2rem;
    color: #222;
    font-size: 14px;
    top: 30%;
    z-index: ${props => props.theme.zIndex.elevation1};
  }
  &.ant-select {
    color: ${props => props.theme.colors.text};
    width: 100%;
    border: 1px solid rgba(151, 151, 151, 0.25);
    border-top: none;

    .ant-select-selection {
      height: 50px;
      border: none;

      .ant-select-selection__rendered {
        line-height: 50px;
        margin-left: 8rem;
        margin-right: 20px;
      }
    }
  }
`;

export const StoreListWrapper = styled.div`
  height: calc(100vh - 540px);
  min-height: 500px;
  overflow-y: auto;

  // Medium devices (tablets, less than 992px)
  @media only screen and (max-width: 991.98px) {
    height: calc(100vh - 500px);
  }

  @media only screen and (max-width: 768px) {
    min-height: initial;
    height: calc(100vh - 450px);
  }

  @media only screen and (max-width: 450px) {
    height: calc(100vh - 350px);
  }
  @media only screen and (max-width: 768px) and (orientation: landscape) {
    height: calc(100vh - 150px);
  }
`;

export const InfoHeader = styled.div`
  font-size: 13px;
  display: flex;
  width: 200px;
`;

export const InfoTitle = styled.h4`
  flex-grow: 1;
  margin-right: 10px;
`;

export const InfoDistance = styled.div`
  font-size: 13px;
`;

export const InfoAddress = styled.div`
  margin-top: 5px;
  font-size: 12px;
`;

export const ClearButton = styled.div`
  font-size: 14px;
  cursor: pointer;
  color: rgb(199, 199, 204);
  text-decoration: underline;
  position: absolute;
  right: 55px;
  top: 14px;
`;
