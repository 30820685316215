import { createGlobalStyle } from 'styled-components';
import { EAPP_LOCALES } from 'types/core';

const getTextAlign = ({ direction, locale }) => {
  if (locale === EAPP_LOCALES.JA) {
    // requested in TECH-14139, JA error messages should be realigned, but still follow LTR
    return 'left';
  }
  return direction === 'rtl' ? 'left' : 'right';
};

export const GlobalAntdStyles = createGlobalStyle<{
  direction: string;
  locale: EAPP_LOCALES;
}>`
  .ant-form-item-explain {
    font-size: 1.4rem;
    letter-spacing: -0.23px;
    font-family: 'PublicSans', sans-serif;
    text-align: ${getTextAlign};
    margin-top: 3px;
  }

  .ant-legacy-form-item {
    margin-bottom: 14px;
  }

  .has-error .ant-input {
    border-color: #ff3654 !important;
  }

  .ant-select-dropdown {
    /* This is the smalles index witch allow rendering dropdown inside the modal,
      before modifying, please make sure select inside modal shows as expected */
    z-index: 99999;
  }

  .has-error .ant-select-selection {
    border-color: #ff3654 !important;
  }

  @media only screen and (max-width: 420px) {
    .ant-tooltip-placement-left {
      left: 120px !important;
    }
  }

`;
