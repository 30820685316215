import styled from 'styled-components';
import { StyledCardSubTitle } from '_common/components/Card/elements';

export const Holder = styled.div`
  display: flex;
`;

export const Pin = styled.div<{
  isActive: boolean;
}>`
  cursor: pointer;
  position: relative;
  margin: 10px 20px;
  display: block;
  width: 36px;
  height: 36px;
  border: 1px solid ${({ theme }) => theme.colors.text};
  border-radius: 50%;
  background: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary : theme.colors.defaultBackground};

  @media only screen and (max-width: 480px) {
    width: 20px;
    height: 20px;
    margin: 10px;
  }

  &:after {
    content: '';
    width: 20px;
    height: 20px;
    background: ${({ theme }) => theme.colors.defaultBackground};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    @media only screen and (max-width: 480px) {
      width: 10px;
      height: 10px;
    }
  }
`;

export const StyledSubTitle = styled(StyledCardSubTitle)`
  font-size: ${props => props.theme.fontSize.l};
`;

export const Content = styled.div`
  flex: 1;
`;
