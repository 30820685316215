import React, { Component } from 'react';
import { compose } from 'recompose';
import { Col, Row } from 'antd';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import {
  EAsyncStatus,
  EPrintOptions,
  IRouteNavigator,
  IRouterMatch,
} from 'types/core';
import { Card, OutlineButton } from '_common/components';
import { withTranslation, WithTranslation } from 'react-i18next';
import ReturnTypeCard from './ReturnTypeCard';
import { withWhitelabelProps } from '_common/whitelabelConfig';
import { ConfirmButton, FormErrorMessage } from 'pages/start/elements';
import { IDetailsStore } from 'types/internal';
import { ResponsiveRow } from '../elements';
import { getUserAddress } from '_common/utils/addressUtils';
import commonStoresActions from '_common/actions';
import { MOBX_STORES } from 'storage';
import { reaction } from 'mobx';
import { IReactionDisposer } from 'mobx/lib/internal';
import {
  CardActionButtons,
  StyledCardTitle,
} from '_common/components/Card/elements';
import amplitude from '_common/utils/amplitude';
import PrintOptionStore from 'pages/printOption/stores/printOptionPageStore';
import OrderStore from '_common/stores/orderStore';
import DirectoryStore from '_common/stores/directoryStore';

type Props = RouteComponentProps<IRouterMatch> &
  WithTranslation & {
    printOptionStore: PrintOptionStore;
    detailsPageStore: IDetailsStore;
    directoryStore: DirectoryStore;
    orderStore: OrderStore;
    whiteLabeled: any;
    setCurrentGoBackMethod: (cb?: Function) => void;
    routeNavigator: IRouteNavigator;
  };

interface State {
  touched: boolean;
}

@observer
class SelectPrintOptions extends Component<Props, State> {
  disposeReaction: IReactionDisposer;

  static PAGE_NAME = 'Select Print Options';

  state = {
    touched: false,
  };

  componentDidMount() {
    const {
      whiteLabeled: { countryCode, isPrinterOptionDisabled },
      detailsPageStore: { formFields },
      orderStore: { isIntegratedFlow, userInfo },
      printOptionStore: { getClosestStores },
      setCurrentGoBackMethod,
    } = this.props;

    setCurrentGoBackMethod(this.goBack);
    if (this.props.directoryStore.status === EAsyncStatus.SUCCESS) {
      getClosestStores({
        address: getUserAddress(isIntegratedFlow, userInfo, formFields),
        countryCode,
        isPrinterOptionDisabled,
      });
    } else {
      this.disposeReaction = reaction(
        () => this.props.directoryStore.status,
        (status, reactionFn) => {
          if (status === EAsyncStatus.SUCCESS) {
            reactionFn.dispose();
            getClosestStores({
              address: getUserAddress(isIntegratedFlow, userInfo, formFields),
              countryCode,
              isPrinterOptionDisabled,
            });
          }
        }
      );
    }
    // for only one available option it will be chosen
    if (isPrinterOptionDisabled) {
      this.handleOptionSelected(EPrintOptions.PAPERLESS)();
    }

    amplitude.logEventWithOrganisationAndUrl('page_open', {
      page_name: SelectPrintOptions.PAGE_NAME,
    });
  }

  componentWillUnmount() {
    this.disposeReaction && this.disposeReaction();
  }

  goBack = () => this.props.routeNavigator.previous();

  handleConfirmClick = () => {
    const {
      printOptionStore: {
        selectedOption,
        nearestStores,
        geoCoordinatesOfAddress,
      },
    } = this.props;
    const isCurrentSelectionValid =
      selectedOption === EPrintOptions.PAPERLESS
        ? Boolean(nearestStores[EPrintOptions.PAPERLESS])
        : true;
    const isSubmitEnabled = selectedOption && isCurrentSelectionValid;

    if (!isSubmitEnabled) {
      this.setState({ touched: true });
      amplitude.logValidationErrors(
        [
          {
            errors: [
              { message: 'No Print Option is selected', field: 'print_option' },
            ],
          },
        ],
        SelectPrintOptions.PAGE_NAME
      );
      return;
    }

    amplitude.logEventWithOrganisationAndUrl(
      'Print option is selected and Next button clicked'
    );

    commonStoresActions.saveToStorage(MOBX_STORES.PrintOptionPageStore, {
      selectedOption,
      nearestStores,
      geoCoordinatesOfAddress,
    });
    this.props.routeNavigator.next();
  };

  handleOptionSelected = (option: EPrintOptions) => () => {
    this.props.printOptionStore.setPrintOption(option);
    amplitude.logEventWithOrganisationAndUrl(`selected ${option} print option`);
  };

  render() {
    const {
      printOptionStore: { selectedOption, asyncStatus, nearestStores },
      whiteLabeled: { printOption: printOptionConfig, isPrinterOptionDisabled },
      t,
    } = this.props;
    const options = isPrinterOptionDisabled
      ? { [EPrintOptions.PAPERLESS]: EPrintOptions.PAPERLESS }
      : EPrintOptions;

    return (
      <Card>
        {!isPrinterOptionDisabled && (
          <Row justify="start" align="middle">
            <StyledCardTitle>{t('title')}</StyledCardTitle>
          </Row>
        )}
        <ResponsiveRow>
          {Object.values(options).map(printOption => (
            <Col xs={24} sm={12} key={printOption}>
              <ReturnTypeCard
                t={t}
                printOptionConfig={printOptionConfig[printOption]}
                asyncStatus={asyncStatus}
                isActive={selectedOption === printOption}
                isDefaultOption={printOption === EPrintOptions.PRINT_OWN}
                stores={nearestStores[printOption]}
                onSelected={this.handleOptionSelected(printOption)}
              />
            </Col>
          ))}
        </ResponsiveRow>
        <CardActionButtons>
          {this.state.touched && !selectedOption && (
            <FormErrorMessage centered>
              {t('selectOptionError')}
            </FormErrorMessage>
          )}
          <ConfirmButton htmlType="submit" onClick={this.handleConfirmClick}>
            {t('constants:submitBtn')}
          </ConfirmButton>
          <OutlineButton onClick={this.goBack}>
            {t('constants:backBtn')}
          </OutlineButton>
        </CardActionButtons>
      </Card>
    );
  }
}

export default compose(
  inject(
    'printOptionStore',
    'detailsPageStore',
    'orderStore',
    'directoryStore'
  ),
  withTranslation(['printOption', 'wl', 'constants']),
  withWhitelabelProps({
    printOption: 'ui.pages.printOption',
    countryCode: 'ui.common.countryCode',
    isPrinterOptionDisabled: 'ui.common.isPrinterOptionDisabled',
  })
)(SelectPrintOptions);
