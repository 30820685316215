import { IFeature } from 'types/features';
import { apiClient } from '_common/api/clients/clients';

const getFeaturesForCompany = async (
  companyId: string
): Promise<IFeature[]> => {
  const response = await apiClient.get(`/v1/features/companyId/${companyId}`);
  return response.data.resources;
};

export default {
  getFeaturesForCompany,
};
