import styled from 'styled-components';
import { StyledCardTitle } from '_common/components/Card/elements';

export const CardContent = styled.div`
  min-height: 28rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const TitleCentered = styled(StyledCardTitle)`
  text-align: center;
  margin: 0;
`;
