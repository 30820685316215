import React from 'react';
import Card from '../Card/Card';
import { LoadingWrapper, AnimatedPlaceholder } from './elements';

const LoaderPage = () => (
  <Card>
    <LoadingWrapper>
      <AnimatedPlaceholder height="46px" width="90%" />
      <AnimatedPlaceholder height="27px" width="75%" />
      <AnimatedPlaceholder height="70px" />
      <AnimatedPlaceholder height="70px" />
      <AnimatedPlaceholder height="60px" width="50%" centered />
    </LoadingWrapper>
  </Card>
);

export default LoaderPage;
