import { assetsClient } from '_common/api/clients/clients';
import { WhiteLabelConstants } from '_common/whitelabelConfig';
import { MERCHANT_ORGANISATION } from '_common/utils';
import { DefaultTheme } from 'styled-components';

const loadTheme = async (): Promise<DefaultTheme> => {
  const themeConfig = await assetsClient.get(
    `/${
      WhiteLabelConstants.PRODUCT_NAME
    }/${MERCHANT_ORGANISATION}/application-config.json?ts=${Date.now()}`
  );

  return themeConfig.data;
};

export default {
  loadTheme,
};
