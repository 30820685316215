import styled, { keyframes } from 'styled-components';
import { ToastContainer, cssTransition } from 'react-toastify';
import { HEADER_HEIGHT } from '_common/constants/styles';

export const customAnimation = cssTransition({
  enter: 'rpc-toast-enter',
  exit: 'rpc-toast-exit',
  duration: [0, 300],
  collapse: true,
});

const enter = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const exit = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const StyledContainer = styled(ToastContainer).attrs({
  closeButton: false,
  autoClose: 6000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  draggable: true,
  limit: 1,
})`
  &.Toastify__toast-container {
    position: absolute;
    top: ${HEADER_HEIGHT};
    left: 0;
    right: 0;
    z-index: 100;
    @media only screen and (max-width: 768px) {
      top: 63.5px;
    }
  }
  .Toastify__toast {
    color: white;
    font-size: 16px;
    text-align: center;
    padding: 8px;
  }
  .Toastify__toast--error {
    background: #e02020;
  }
  .Toastify__toast--success {
    background: #38b13a;
  }
  .Toastify__toast--info {
    background: #4a90e2;
  }
  .rpc-toast-enter {
    animation: ${enter};
  }
  .rpc-toast-exit {
    animation: ${exit};
  }
`;

export default StyledContainer;
