import React from 'react';
import { AsyncStatus } from '_common/constants/common';

interface Props {
  dependenciesList: Array<Function>;
  orderedDependenciesList?: Array<Function>; // This collection is to pass the dependencies to be initialized in particular order before others
  loadingComponent: JSX.Element;
  errorComponent: JSX.Element;
  children: React.ReactNode;
}

type State = {
  status: string;
};

class DependenciesLoader extends React.Component<Props, State> {
  state = {
    status: AsyncStatus.IDLE,
  };

  async componentDidMount() {
    this.setState({ status: AsyncStatus.LOADING });
    try {
      if (this.props.orderedDependenciesList) {
        // Sequential dependencies have priority over others
        /* eslint-disable no-restricted-syntax, no-await-in-loop */
        for (const fn of this.props.orderedDependenciesList) {
          await fn();
        }
      }
      await Promise.all(this.props.dependenciesList.map(fn => fn()));
      this.setState({ status: AsyncStatus.SUCCESS });
    } catch (e) {
      this.setState({ status: AsyncStatus.FAILED });
    }
  }

  render() {
    const { status } = this.state;
    const { children, errorComponent } = this.props;

    switch (status) {
      case AsyncStatus.SUCCESS:
        return children;
      case AsyncStatus.LOADING:
        return children;
      case AsyncStatus.FAILED:
        return errorComponent;
      default:
        return null;
    }
  }
}

export default DependenciesLoader;
