import { Card as AntdCard } from 'antd';
import { CardProps as AntdCardProps } from 'antd/lib/card';
import React from 'react';
import { StyledCard } from './elements';
import { AsyncStatus } from '_common/constants/common';
import { withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'recompose';

export enum CardSize {
  XSMALL = 520,
  SMALL = 685,
  REGULAR = 800,
  LARGE = 1024,
}

export interface CardProps extends AntdCardProps, WithTranslation {
  children: React.ReactNode;
  asyncStatus?: string;
  noPadding?: boolean;
  customSize?: CardSize;
}

const Card = ({
  children,
  asyncStatus,
  noPadding,
  customSize = CardSize.REGULAR,
  t,
  i18n,
  tReady,
  ...otherProps
}: CardProps) => (
  <StyledCard maxWidth={customSize} noPadding={noPadding}>
    <AntdCard loading={asyncStatus === AsyncStatus.LOADING} {...otherProps}>
      {asyncStatus === AsyncStatus.FAILED ? <p>{t('cardError')}</p> : children}
    </AntdCard>
  </StyledCard>
);

export default compose(withTranslation('components'))(Card);
