export const detailsFormFields = {
  purchaseDate: null,
  returnItems: undefined, // undefined only for select
  returnReason: undefined,
  fullName: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  postcode: null,
  state: undefined,
  confirmTerms: false,
  phoneNumber: null,
  reasonComments: null,
  itemName: null,
  handlingInstructions: undefined,
  firstName: null,
  lastName: null,
  kannaLastName: null,
  kannaFirstName: null,
};
