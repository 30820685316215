import React from 'react';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import { get } from 'lodash';
import { Marker as GoogleMarker } from '@react-google-maps/api';
import { compose } from 'recompose';

import {
  markerIcon,
  activeMarkerIcon,
  defaultUserMarker,
  postOfficeIcon,
  postOfficeActiveIcon,
  postBoxIcon,
  postBoxActiveIcon,
  lockerIcon,
  lockerActiveIcon,
  colocatedPostbox,
  colocatedPostboxActive,
  storeActiveIcon,
  storeIcon,
} from 'assets';
import commonStoresActions from '_common/actions';
import { IStore } from 'types/store';
import { LocationStore } from 'stores';

type Props = {
  storeId?: string;
  lat: number;
  lng: number;
  locationStore: LocationStore;
  storeData?: IStore;
  isDefaultUser?: boolean;
  onClick?: (marker: google.maps.Marker, storeData: IStore) => void;
  locationType?: string;
};

type State = {};

@observer
class Marker extends React.Component<Props, State> {
  googleMarkerRef = React.createRef<typeof GoogleMarker>();

  get googleMarker() {
    return get(this.googleMarkerRef.current, 'marker', null);
  }

  @computed
  get isActive() {
    return this.props.storeId === this.props.locationStore.activeStoreId;
  }

  onClick = () => {
    commonStoresActions.setActiveStoreId(this.props.storeId);
    if (this.props.onClick) {
      this.props.onClick(null, this.props.storeData);
    }
  };

  render() {
    const {
      storeId,
      isDefaultUser,
      lat,
      lng,
      locationStore,
      locationType,
      ...rest
    } = this.props;

    let iconUrl: string;
    switch (locationType) {
      case 'POSTOFFICE':
        iconUrl = this.isActive ? postOfficeActiveIcon : postOfficeIcon;
        break;

      case 'POSTBOX':
        iconUrl = this.isActive ? postBoxActiveIcon : postBoxIcon;
        break;

      case 'EXPRESS_POSTBOX':
        iconUrl = this.isActive ? colocatedPostboxActive : colocatedPostbox;
        break;

      case 'LOCKER':
        iconUrl = this.isActive ? lockerActiveIcon : lockerIcon;
        break;

      case 'STORE':
        iconUrl = this.isActive ? storeActiveIcon : storeIcon;
        break;

      default:
        iconUrl = this.isActive ? activeMarkerIcon : markerIcon;
    }

    const markerImage = {
      url: isDefaultUser ? defaultUserMarker : iconUrl,
      scaledSize: { width: 37, height: 36 },
    };

    return (
      <GoogleMarker
        {...rest}
        position={{ lat, lng }}
        icon={markerImage as google.maps.Icon}
        onClick={this.onClick}
      />
    );
  }
}
export default compose(inject('locationStore'))(Marker);
