import { RETURNS_PORTAL_BUILD_NAME } from '_common/constants/common';
import { EReturnType } from 'types/core';

export interface IUserInfo {
  phoneNumber: string | null;
  name: string | null;
  orderDate: Date | null;
}

export enum OrderStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
}

export interface IName {
  firstName: string;
  lastName: string;
}

export interface ICustomer {
  email: string;
  mobileNumber?: string;
  name: IName;
}

export interface IEventHistory {
  dateTime: Date;
  eventType: string;
}

export interface IDeliveryAddress {
  area: string;
  country: string;
  line1: string;
  line2: string;
  line3: string;
  postcode: string;
  town: string;
}

export interface IExternalOrderData {
  deliveryAddress: IDeliveryAddress;
  purchaseDate: string;
}

export interface IDestinationLocation {
  locationId: string;
  locationType: string;
}

export interface IPackage {
  labelValue: string;
}

export interface IReturnWindow {
  isWithinReturnWindow: boolean;
  lastDayOfReturn: string;
}

export interface IOrderProduct {
  description: string;
  imageUrl: string;
  price: number;
  productId: string;
  quantity: number;
  size: string;
  isNotReturnable?: boolean;
  isPendingApproval?: boolean;
  isApproved?: boolean;
  colour?: string;
  returnWindow?: IReturnWindow;
}

export interface IOrderProductModel extends IOrderProduct {
  reasonComments?: string;
  priceCurrency: any;
}

export interface IReturnCustomer {
  email: string;
  phoneNumber: string;
  name: string;
  address: IDeliveryAddress;
}

export interface ICreateReturnRequest {
  companyId: string;
  customer: IReturnCustomer;
  orderLines: OrderLine[];
  externalOrderId: string;
  purchaseDate: string;
  appName: string;
}

export interface ICreateReturnResponse {
  status: OrderStatus;
  caseNumber: string;
  caseId: string;
}

export interface IShipmentBooking {
  carrierBarcodeValue: string;
}

export interface IShippingLabel {
  pdfUrl: string;
}

export interface ReturnData {
  shipmentBooking?: IShipmentBooking;
  shippingLabel?: IShippingLabel;
  reasonComments?: string;
  returnReason?: string;
}

export interface SourceLocation {
  locationId: string;
  locationType: string;
}

export interface OrderLine {
  collectionDayType?: string;
  collectionDeadlineDays?: number;
  destinationLocation?: IDestinationLocation;
  fulfilmentMethod?: string;
  package?: IPackage;
  priceCurrency?: string;
  product?: IOrderProductModel;
  requiredCollectionIdCheckType?: string;
  returnData?: ReturnData;
  signatureRequired?: boolean;
  sourceLocation?: SourceLocation[];
}

export interface IOrder {
  companyId: string;
  customer: ICustomer;
  eventHistory: IEventHistory[];
  externalOrderData: IExternalOrderData;
  externalOrderId: string;
  orderId: string;
  orderLines: OrderLine[];
  referenceId?: string;
  resourceType?: string;
  orderType: OrderType;
  // caseId are used for "returns-approval"
  caseId?: string;
  alwaysReturnable?: boolean;
}

export interface IOrderCreationStatus {
  bookingId: string;
  recordExpirationTime: number;
  trackingId: string;
  pdfLabelUrl: string;
  orderId: string;
  status: string;
}

export type OrderType = 'EXTERNAL' | 'INTERNAL';

export interface IOrderServerResponse {
  resources: IOrder[];
}

export interface ICreateOrderConfig {
  companyId: string;
  orderNumber?: string;
  appName: typeof RETURNS_PORTAL_BUILD_NAME;
  additionalInfo: string;
  externalOrderId?: string;
  from: any;
  to: any;
  returns: any;
  products?: any;
  consumerPaymentWasSuccessful?: boolean;
  payment?: any;
  printInStore?: boolean;
  returnType?: EReturnType;
  pickupDate?: string;
  pickupTime?: string;
  itemName?: string;
}
