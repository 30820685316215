import services from './services';
import whiteLabelStores from './stores';
import ui from './ui';
import utils from './_utils';
import {
  PRODUCT_NAME,
  TRACKING_EVENT_TYPES,
  defaultTheme,
  DEFAULT_PAYMENT_TYPE,
  IS_INTEGRATED_JOURNEY_AVAILABLE,
  DEFAULT_CURRENCY_NAME,
} from './_constants';
import { IWhiteLabelConstants } from 'types/whitelabelTypes';

const constants: IWhiteLabelConstants = {
  PRODUCT_NAME,
  TRACKING_EVENT_TYPES,
  defaultTheme,
  DEFAULT_PAYMENT_TYPE,
  IS_INTEGRATED_JOURNEY_AVAILABLE,
  DEFAULT_CURRENCY_NAME,
};

export default {
  stores: whiteLabelStores,
  services,
  ui,
  utils,
  constants,
};
