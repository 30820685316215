import lodashGet from 'lodash/get';
import React from 'react';

// TODO: refactor this file at all.
// @ts-ignore
// eslint-disable-next-line import/extensions,import/no-unresolved
import WHITELABEL_CONFIG from './override';
import { IWhiteLabelConfig } from 'types/whitelabelTypes';

const addGetters = obj => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // eslint-disable-next-line no-param-reassign,func-names
      obj[key].get = function (path) {
        return lodashGet(this, path);
      };
    }
  }
  // eslint-disable-next-line no-param-reassign,func-names
  obj.get = function (path) {
    return lodashGet(this, path);
  };
  return obj;
};

const customConfig: IWhiteLabelConfig = addGetters(WHITELABEL_CONFIG);

customConfig.initServices = methodMap => {
  // eslint-disable-next-line no-restricted-syntax
  for (const serviceName in customConfig.services) {
    if (
      !Object.prototype.hasOwnProperty.call(customConfig.services, serviceName)
    ) {
      // eslint-disable-next-line no-continue
      continue;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const methodName in methodMap) {
      if (!Object.prototype.hasOwnProperty.call(methodMap, methodName)) {
        // eslint-disable-next-line no-continue
        continue;
      }
      customConfig.services[serviceName][methodName] = methodMap[methodName];
    }
  }
};

const { ui, services, stores, utils, initServices, constants } = customConfig;

const withWhitelabelProps = configProps => WrappedComponent =>
  React.forwardRef((ownProps, ref) => {
    const { children, ..._props } = ownProps;
    const populatedProps = Object.keys(configProps).reduce((obj, key) => {
      obj[key] = customConfig.get(configProps[key]);
      return obj;
    }, {});
    return (
      <WrappedComponent {..._props} whiteLabeled={populatedProps} ref={ref}>
        {children}
      </WrappedComponent>
    );
  });

export {
  initServices,
  withWhitelabelProps,
  ui as WhiteLabelUi,
  services as WhiteLabelServices,
  stores as WhiteLabelStores,
  utils as WhiteLabelUtils,
  constants as WhiteLabelConstants,
};

export default customConfig;
