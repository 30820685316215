const white = '#fff';
const black = '#000';
const gray = '#282d32';
const gray3 = '#eee';
const green = '#38b13a';
const blue = '#4a90e2';
const red = '#e02020';

const basicTheme = {
  fonts: {
    basic: 'Arial, Helvetica, sans-serif',
    primary: 'PublicSans',
  },
  colors: {
    text: gray,
    textAlt: black,
    error: red,
    defaultBackground: white,
    success: green,
    primary: blue,
    border: gray3,
    white,
  },
  fontSize: {
    xxs: '1rem',
    xs: '1.2rem',
    s: '1.4rem',
    m: '1.6rem',
    l: '1.8rem',
    xl: '2rem',
    xxl: '2.4rem',
  },
  zIndex: {
    elevation1: 20,
    elevation2: 40,
    elevation3: 60,
    elevation4: 80,
    elevation5: 100,
    elevation6: 120,
    elevationTop: 999,
  },
};

export const rtlMixin = rtlCss => ({ theme: { layoutDirection = 'ltr' } }) =>
  layoutDirection && layoutDirection === 'rtl' && rtlCss;

export default basicTheme;
