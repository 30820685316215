/* eslint camelcase: 0 */

import { action, observable, runInAction } from 'mobx';
import { localStore as storage, PDF_LABEL_URL } from 'storage';
import { isEmpty } from 'lodash';

import USPSServices from '../services';
import {
  LABEL_POLLER_ATTEMPTS,
  LABEL_POLLER_INTERVAL,
} from '_common/constants/timeout';
import {
  EValidation_Errors,
  ICommonStoresActions,
  TSPResult,
} from 'types/core';
import { IStoreWithCommonActions } from 'types/internal';
import { ICreateOrderConfig } from 'types/order';
import i18nService from '_common/services/i18nService';

/**
 * Observes state of creating labels via aus.post proxy.
 */
class USPSProxyStore implements IStoreWithCommonActions {
  commonStoresActions: ICommonStoresActions;

  registerCommonActions = (commonStoresActions: ICommonStoresActions) => {
    this.commonStoresActions = commonStoresActions;
  };

  @observable
  pdfLabelURL = storage.get(PDF_LABEL_URL);

  @observable
  shipmentCreationResponse = null;

  @observable
  consumerPaymentWasSuccessful: boolean = false;

  @observable
  orderWasSubmitted: boolean = false;

  securePayData: TSPResult | null = null;

  @action
  resetStore = () => {
    this.shipmentCreationResponse = null;
    this.consumerPaymentWasSuccessful = false;
    this.orderWasSubmitted = false;
    this.securePayData = null;
  };

  @action
  createShipment = (config: ICreateOrderConfig, company): Promise<void> =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      let accountNumber;
      let bookingId;
      try {
        accountNumber = this.commonStoresActions.getAccountNumberForHeader();
        // Shipment creation step.
        const shipmentCreationResponse = await USPSServices.proxyService.createShipments(
          { ...config, accountNumber }
        );
        bookingId = shipmentCreationResponse.bookingId;
      } catch (e) {
        return reject(e);
      }
      // Multiple attempts to get shipment data.
      const poller = async (currentIndex): Promise<void> => {
        if (currentIndex >= LABEL_POLLER_ATTEMPTS) {
          return reject(new Error(i18nService.t('success:failedSubtitle')));
        }
        try {
          // Get shipment data.
          const detailedShipment = await USPSServices.proxyService.getShipments(
            bookingId
          );
          if (detailedShipment.status === 'COMPLETE - ORDER CREATED') {
            const { pdfLabelUrl } = detailedShipment;
            runInAction(() => {
              this.pdfLabelURL = pdfLabelUrl;
              this.shipmentCreationResponse = detailedShipment;
              this.orderWasSubmitted = true;
              storage.set(PDF_LABEL_URL, pdfLabelUrl);
            });
            company && this.commonStoresActions.validateSession(company, true);
            return resolve();
          }
          /** next tick */
          setTimeout(() => poller(currentIndex + 1), LABEL_POLLER_INTERVAL);
        } catch {
          /** In error case also re-trying. */
          setTimeout(() => poller(currentIndex + 1), LABEL_POLLER_INTERVAL);
        }
      };
      poller(0);
    });

  @action
  validateAddress = async (address): Promise<void> => {
    try {
      const mappedAddress = USPSServices.proxyService.mapFrontAddressToBack(
        address
      );
      const validationResult = await USPSServices.proxyService.validateAddress(
        mappedAddress
      );

      const { found, body } = validationResult;
      if (found) {
        // populate values with corrected ones from API only for 9-digit zipCodes
        if (address.postcode?.length === 10 && !isEmpty(body)) {
          const correctedValues = USPSServices.proxyService.mapBackAddressToFront(
            body
          );

          Object.keys(correctedValues).forEach(key => {
            this.commonStoresActions.setDetailsFormField(
              key,
              correctedValues[key]
            );
          });
        }

        this.commonStoresActions.saveDetailsFormToStorage();
        return Promise.resolve();
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        error_type: EValidation_Errors.INVALID_ADDRESS,
      });
    } catch (e) {
      console.error('Validate address error:', e);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        error_type: EValidation_Errors.INVALID_ADDRESS,
      });
    }
  };

  // TODO add logic if any shipment validations will be needed for USPS
  @action
  validateShipment = async (): Promise<void> => {};

  @action
  setConsumerPaymentWasSuccessful = (paymentData: TSPResult) => {
    this.securePayData = paymentData;
    this.consumerPaymentWasSuccessful = false; // USPS NOT required payments at all, why it was true - not clear.
  };
}

export default USPSProxyStore;
