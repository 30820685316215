import { Button } from '_common/components';
import styled, { css } from 'styled-components';
import { rtlMixin } from '_common/constants/theme';

export const ItemHolder = styled.div<{ fitContent: boolean }>`
  min-width: calc(360px - 40px); /* min device width - double padding */
  display: flex;
  flex-direction: column;
  padding: ${({ fitContent }) => (fitContent ? 0 : '1.5rem')};
  font-family: ${props => props.theme.fonts.primary};
  ${({ fitContent }) =>
    fitContent &&
    `background-color: ${props => props.theme.colors.defaultBackground};`}
  & > span {
    z-index: ${props => props.theme.zIndex.elevationTop};
    padding-left: 5px;
    padding-top: 5px;
    position: absolute;
    right: 3px;
    color: ${props => props.theme.colors.white};
  }
  ${rtlMixin(css`
    & > span {
      right: initial;
      left: 3px;
    }
  `)};
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    ${rtlMixin(css`
      & > span {
        order: 1;
      }
    `)}
  }
  @media only screen and (max-width: 320px) {
    ${rtlMixin(css`
      min-width: 280px;
    `)}
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-top: solid 1px #dfdfdf;
  min-height: 35rem;
`;

export const Heading = styled.div`
  font-size: ${props => props.theme.fontSize.xl};
  font-weight: bold;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.text};
`;

export const ConfirmButton = styled(Button)`
  && {
    margin-top: auto;
    width: 100%;
    font-size: ${props => props.theme.fontSize.xxl};
  }
`;

export const ReturnItemWrapper = styled.div<{
  isComplete: boolean;
  hasError: boolean;
}>`
  min-width: calc(360px - 40px); /* min device width - double padding */
  margin-bottom: 2rem;
  position: relative;
  ${({ isComplete }) =>
    !isComplete &&
    css`
      cursor: pointer;
    `}
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid
      ${({ isComplete, hasError }) =>
        isComplete ? '2px #38b13a' : hasError ? '1px #e5e5e5' : '1px #979797'};
    border-radius: 3px;
    pointer-events: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid #38b13a;
    border-top: 20px solid #38b13a;
    border-bottom: 20px solid transparent;
    display: ${({ isComplete }) => (isComplete ? 'block' : 'none')};
    border-radius: 3px;
    ${rtlMixin(css`
      left: 0;
      right: initial;
      transform: rotate(-90deg);
    `)};
  }

  &:hover :after {
    border: solid
      ${({ isComplete, hasError }) =>
        isComplete ? '2px #38b13a' : hasError ? '1px #e5e5e5' : '2px #222'};
  }

  i {
    position: absolute;
    right: 4px;
    top: 4px;
    color: ${props => props.theme.colors.white};
  }

  @media only screen and (max-width: 320px) {
    ${rtlMixin(css`
      min-width: 280px;
    `)}
  }
`;

export const ItemContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 83px;
`;

export const Image = styled.div<{
  imageUrl: string;
  fitContent?: boolean;
  isDisabled: boolean;
}>`
  min-width: 83px;
  width: 83px;
  min-height: 83px;
  margin-right: 2rem;

  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
  ${({ isDisabled }) => isDisabled && 'filter: grayscale(1);'}
  ${({ imageUrl }) => !imageUrl && ' background-color: #e5e5e5'};
  @media only screen and (max-width: 768px) {
    width: 80px;
    min-width: 80px;
    min-height: 80px;
  }
`;

export const FitContentImage = styled(Image)`
  margin-right: 0;
  position: absolute;
  height: 100%;
  @media only screen and (max-width: 768px) {
    min-height: unset;
  }
`;

export const IeDisabledImage = styled.svg`
  background-size: cover;
  position: absolute;
  height: 100%;
  min-width: 83px;
  width: 83px;
  min-height: 83px;
`;

export const Description = styled.div`
  color: ${props => props.theme.colors.textAlt};
  font-size: ${props => props.theme.fontSize.s};
  ${rtlMixin(css`
    padding-left: initial;
    padding-right: 10rem;
  `)};
`;

export const DetailsRow = styled.div`
  line-height: 2;
  text-align: right;
  color: ${props => props.theme.colors.textAlt};
  font-size: ${props => props.theme.fontSize.s};
  ${rtlMixin(css`
    margin-right: initial;
    margin-left: 15rem;
  `)};
  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
  }
`;

export const DetailsWrapper = styled.div<{ isDisabled?: boolean }>`
  width: 100%;
  ${({ isDisabled }) =>
    isDisabled &&
    `${Description} {
      color: #959595;
    }
    ${DetailsRow} {
      color: #959595;
    }
    `}
  @media only screen and (max-width: 768px) {
    ${rtlMixin(css`
      width: auto;
    `)};
  }
`;

export const DetailsBlock = styled.div<{ fitContent?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: start;
  font-size: ${props => props.theme.fontSize.m};
  padding: ${({ fitContent }) =>
    fitContent ? '1.5rem 2.5rem 1.5rem 0' : '0 1rem 0 0'};
  ${Description} {
    ${({ fitContent }) => fitContent && 'padding-left: 10rem'};
    @media only screen and (max-width: 768px) {
      ${({ fitContent }) =>
        fitContent &&
        `
          padding-left: 11rem;
        `}
      ${rtlMixin(css`
        padding-left: initial;
        padding-right: 11rem;
      `)};
    }
  }
  ${DetailsRow} {
    @media only screen and (max-width: 768px) {
      ${({ fitContent }) =>
        fitContent &&
        `
          padding-left: 11rem;
          text-align: left;
        `}
      ${rtlMixin(css`
        padding-left: initial !important;
        padding-right: 11rem;
        margin: initial !important;
        text-align: right;
        flex-direction: column;
      `)};
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    color: #222;
    ${({ fitContent }) => fitContent && 'padding-right: 0'}
  }
`;

export const Info = styled.div`
  font-size: ${props => props.theme.fontSize.s};
  padding-left: 10rem;
  display: flex;
  color: ${props => props.theme.colors.textAlt};
  ${rtlMixin(css`
    padding-left: initial;
    padding-right: 12rem;
  `)}
  @media only screen and (max-width: 768px) {
    padding: 1rem;
    display: block;
  }
`;

export const InfoButtons = styled.div`
  width: 40rem;
  padding: 0 1rem;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 1rem;
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
    display: flex;
    margin: 0 -0.5rem;
  }
`;

export const StyledButton = styled(Button)`
  margin-left: 1rem;
  margin-bottom: 1rem;
  @media only screen and (max-width: 768px) {
    margin: 0 0.5rem;
  }
`;

export const InfoContent = styled.div`
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    display: block;
    font-size: 1.5rem;
  }
`;

export const Title = styled.div`
  font-size: ${props => props.theme.fontSize.m};
  margin-bottom: 1rem;
  font-weight: 400;
`;

export const ReturnItemError = styled.div`
  padding: 0 2.5rem 1rem 1.5rem;
  color: #222;
  font-size: ${props => props.theme.fontSize.s};
  font-weight: 400;
  text-align: right;
  ${rtlMixin(
    css`
      text-align: left;
    `
  )};
  @media only screen and (max-width: 768px) {
    padding: 0 0 1rem;
  }
`;
