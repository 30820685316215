import styled, { css } from 'styled-components';

export const CHECKBOX_SIZE = '28px';

export const CheckboxWrapper = styled.div<{ hasError: boolean }>`
  input[type='checkbox']:focus + .ant-checkbox-inner {
    outline: 3px solid rgba(24, 144, 255, 0.2);
  }

  .ant-checkbox-inner {
    width: ${CHECKBOX_SIZE};
    height: ${CHECKBOX_SIZE};
  }

  .ant-checkbox-checked {
    border-color: #979797 !important;
  }
  .ant-checkbox-checked:hover {
    border-color: #1890ff !important;
    & > .ant-checkbox-inner {
      border-color: #1890ff !important;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.theme.colors.defaultBackground};
    border-color: #979797;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: ${props => props.theme.colors.textAlt};
    top: 50%;
    left: 40%;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      .ant-checkbox-inner {
        border-color: ${props => props.theme.colors.error} !important;
      }
    `}
`;
