// withTranslation for shortName
export const DAYS = [
  { fullName: 'monday', shortName: 'day0Short' },
  { fullName: 'tuesday', shortName: 'day1Short' },
  { fullName: 'wednesday', shortName: 'day2Short' },
  { fullName: 'thursday', shortName: 'day3Short' },
  { fullName: 'friday', shortName: 'day4Short' },
  { fullName: 'saturday', shortName: 'day5Short' },
  { fullName: 'sunday', shortName: 'day6Short' },
];

// withTranslation
export const PURCHASE_DATE_ERRORS = {
  FULL_MESSAGE: 'PURCHASE_DATE_ERROR_FULL',
  SHORT_MESSAGE: 'PURCHASE_DATE_ERROR_SHORT',
};

export const ALLOWED_DATEPICKER_FORMATS = [
  'DD-MM-YYYY',
  'DD/MM/YYYY',
  'DD.MM.YYYY',
  'DD,MM,YYYY',
];
