import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { NotificationBar } from '_common/components';
import RouteGuard from '_common/routes/RouteGuard';
import { getMerchantFromUrl, ScrollToTop } from '_common/utils';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { ConfigProvider as AntdLocaleProvider } from 'antd';
import { ANTD_LOCALE_MAPPING } from '_common/services/i18nService';
import ThemeStore from '_common/stores/themeStore';
import { GlobalAntdStyles } from 'themes/globalAntdStyles';

interface Props {
  themeStore: ThemeStore;
}
@observer
class App extends React.Component<Props> {
  render() {
    const {
      themeStore: { currentLocale, layoutDirection },
    } = this.props;
    const merchantName = getMerchantFromUrl();

    return (
      <AntdLocaleProvider
        locale={ANTD_LOCALE_MAPPING[currentLocale]}
        direction={layoutDirection}
      >
        <BrowserRouter>
          <>
            <GlobalAntdStyles
              direction={layoutDirection}
              locale={currentLocale}
            />
            <NotificationBar />
            <ScrollToTop />
            <RouteGuard merchForRedirect={merchantName} />
          </>
        </BrowserRouter>
      </AntdLocaleProvider>
    );
  }
}

export default compose(inject('themeStore'))(App);
