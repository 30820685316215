import React, { Component } from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { IReactionDisposer } from 'mobx/lib/internal';
import { RouteComponentProps } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import qs from 'qs';
import { reaction } from 'mobx';
import { withTranslation, TransProps } from 'react-i18next';
import { Row } from 'antd';
import { EAsyncStatus, IRouterMatch } from 'types/core';
import { Card } from '_common/components';
import { CardSize } from '_common/components/Card/Card';
import commonStoresActions from '_common/actions';
import LocateMap from './LocateMap';
import StoreList from './StoreList';
import SearchHeader from './SearchHeader';
import { LocateWrapper, TabHeader, MobileTabs, TabWrapper } from './elements';
import amplitude from '_common/utils/amplitude';
import { IStore, LatLng } from 'types/store';
import { StyledCardTitle } from '_common/components/Card/elements';
import { withWhitelabelProps } from '_common/whitelabelConfig';
import { getUserAddress } from '_common/utils/addressUtils';
import PrintOptionStore from 'pages/printOption/stores/printOptionPageStore';
import OrderStore from '_common/stores/orderStore';
import LocationStore from '_common/stores/locationStore';
import DirectoryStore from '_common/stores/directoryStore';
import { IDetailsStore } from 'types/internal';

interface State {
  hasError: boolean;
  isSearchButtonDisabled: boolean;
  key: 'list' | 'map';
}

type Props = RouteComponentProps<IRouterMatch> &
  TransProps & {
    locationStore: LocationStore;
    printOptionStore: PrintOptionStore;
    directoryStore: DirectoryStore;
    orderStore: OrderStore;
    whiteLabeled: any;
    detailsPageStore: IDetailsStore;
  };

interface IUrlParams {
  address?: string;
  storeId?: string;
  latitude?: string;
  longitude?: string;
}

@observer
class LocatePage extends Component<Props, State> {
  disposeReaction: IReactionDisposer;

  static PAGE_NAME = 'Locate Page';

  urlParams: IUrlParams;

  autocompleteRef = React.createRef<HTMLInputElement>();

  mapRef = React.createRef<typeof LocateMap>();

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      key: 'list',
      isSearchButtonDisabled: false,
    };

    this.urlParams = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });
  }

  componentDidMount() {
    amplitude.logEventWithOrganisationAndUrl('page_open', {
      page_name: LocatePage.PAGE_NAME,
    });
  }

  componentWillUnmount() {
    this.disposeReaction && this.disposeReaction();
  }

  logFieldEvent = (eventName: string, extraPayload?) => {
    const { href: url } = window.location;
    const { company: retailerName } = this.props.match.params;
    amplitude.logEvent(eventName, {
      url,
      retailerName,
      ...extraPayload,
    });
  };

  get shouldAutoSearch() {
    return (
      this.urlParams.address &&
      !this.urlParams.storeId &&
      !this.urlParams.latitude &&
      !this.urlParams.longitude
    );
  }

  get userAddress() {
    const {
      orderStore: { isIntegratedFlow, userInfo },
      detailsPageStore: { formFields },
    } = this.props;

    return getUserAddress(isIntegratedFlow, userInfo, formFields);
  }

  onMapLoad = async map => {
    const {
      directoryStore,
      locationStore: { mapCenterCoordinates, getCoordinates },
      whiteLabeled,
    } = this.props;
    // need to have company config first for available location types
    if (directoryStore.status !== EAsyncStatus.SUCCESS) {
      this.disposeReaction = reaction(
        () => directoryStore.status,
        async (status, reactionFn) => {
          /** Need to get company config before sending requests. */
          if (status === EAsyncStatus.SUCCESS) {
            reactionFn.dispose();
            this.onMapLoad(map);
          } else {
            console.error('Unable to start search, company model not loaded.');
          }
        }
      );
      return;
    }
    this.initAutocomplete(map);
    /** If address provided from URL - dont need to call search by coords. */
    if (this.shouldAutoSearch) {
      const results = await getCoordinates({
        address: this.urlParams.address,
        componentRestrictions: {
          country: whiteLabeled.countryCode,
        },
      });
      if (results.length) {
        const position = results[0].geometry.location;
        await commonStoresActions.setSearchGeoCoordinates({
          lat: position.lat(),
          lng: position.lng(),
        });
        commonStoresActions.searchStoresByCoords();
      }
    } else {
      // get coords from the url or use defaults
      const searchCoords: LatLng =
        this.urlParams.latitude && this.urlParams.longitude
          ? {
              lat: Number(this.urlParams.latitude),
              lng: Number(this.urlParams.longitude),
            }
          : mapCenterCoordinates;
      await commonStoresActions.setSearchGeoCoordinates(searchCoords);
      await commonStoresActions.searchStoresByCoords(searchCoords);

      if (this.urlParams.storeId) {
        commonStoresActions.setActiveStoreId(this.urlParams.storeId);
      }
    }
  };

  initAutocomplete(map) {
    const {
      whiteLabeled: countryCode,
      match: {
        params: { company },
      },
    } = this.props;

    if (!map || !this.autocompleteRef.current) return;

    const autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteRef.current,
      {
        types: ['geocode'],
        componentRestrictions: { country: [countryCode.countryCode] },
      }
    );
    autocomplete.bindTo('bounds', map);
    autocomplete.setComponentRestrictions({
      country: [countryCode.countryCode],
    });

    autocomplete.addListener('place_changed', () => {
      this.logFieldEvent('click search', {
        locationInput: this.urlParams.address,
      });
      const place = autocomplete.getPlace();

      if (!place.geometry) return;

      const position = place.geometry.location;

      isMobile
        ? commonStoresActions.searchStoresByCoords({
            lat: position.lat(),
            lng: position.lng(),
            company: company,
          })
        : commonStoresActions.setSearchGeoCoordinates({
            lat: position.lat(),
            lng: position.lng(),
          });
    });
  }

  searchByUserGeoLocation = async () => {
    const {
      locationStore: { isLoading },
      match: {
        params: { company },
      },
    } = this.props;
    this.enableSearchButton();

    const searchInput = this.autocompleteRef && this.autocompleteRef.current;
    if (!searchInput || isLoading) return;
    this.logFieldEvent('click my location icon');
    searchInput.value = '';
    searchInput.value = await commonStoresActions.searchStoresByGeoLocation(
      company
    );
  };

  onSearchInputClick = () => {
    this.logFieldEvent('click on search field');
  };

  onSearchBtnClick = async () => {
    this.setState({ hasError: false });
    if (this.autocompleteRef.current) {
      const extraPayload = {
        locationInput: (this.autocompleteRef.current as HTMLInputElement).value,
      };
      this.logFieldEvent('click search', extraPayload);
    }
    try {
      await commonStoresActions.searchStoresByCoords();
    } catch (e) {
      this.setState({ hasError: true });
    }
  };

  onStoreTypeChange = (storeType: string) => {
    commonStoresActions.setLocationType(storeType);
    if (!isMobile) return;
    const { previousSearchCoords } = this.props.locationStore;
    const {
      params: { company },
    } = this.props.match;
    if (!previousSearchCoords) return;
    commonStoresActions.searchStoresByCoords({
      lat: previousSearchCoords.lat,
      lng: previousSearchCoords.lng,
      company,
    });
  };

  onClearBtnClick = () => {
    this.logFieldEvent('click on clear search');
    this.autocompleteRef.current.value = '';
    this.setState({ isSearchButtonDisabled: true });
  };

  enableSearchButton = () => {
    this.setState({ isSearchButtonDisabled: false });
  };

  handleStoreClick = (store: IStore) => {
    commonStoresActions.setActiveStoreId(store.storeId);
    // TODO add mobile check?
    this.changeTab('map');
  };

  changeTab = key => {
    this.setState({ key }, () => {
      // google maps fitBounds don't work on display: none, so need to show map first
      this.mapRef.current?.wrappedInstance.setBounds();
    });
  };

  render() {
    const {
      t,
      locationStore: { isLoading },
      directoryStore: { locationTypesOptions },
    } = this.props;
    const { hasError, isSearchButtonDisabled } = this.state;

    return (
      <Card customSize={CardSize.LARGE} noPadding>
        <LocateWrapper>
          <StyledCardTitle>{t('locate:title')}</StyledCardTitle>
          <SearchHeader
            ref={this.autocompleteRef}
            defaultValue={this.urlParams.address || this.userAddress || ''}
            isLoading={isLoading}
            hasError={hasError}
            isSearchButtonDisabled={isSearchButtonDisabled}
            locationTypesOptions={locationTypesOptions}
            onGeoIconClick={this.searchByUserGeoLocation}
            onInputClick={this.onSearchInputClick}
            onInputChange={this.enableSearchButton}
            onSearchBtnClick={this.onSearchBtnClick}
            onClearBtnClick={this.onClearBtnClick}
            onStoreTypeChange={this.onStoreTypeChange}
          />
          <MobileTabs>
            <TabHeader
              active={this.state.key === 'list'}
              onClick={() => this.changeTab('list')}
            >
              {t('listView')}
            </TabHeader>
            <TabHeader
              active={this.state.key === 'map'}
              onClick={() => this.changeTab('map')}
            >
              {t('mapView')}
            </TabHeader>
          </MobileTabs>
          <Row>
            <TabWrapper span={12} isActive={this.state.key === 'list'}>
              <StoreList
                onStoreClick={this.handleStoreClick}
                currentTab={this.state.key}
              />
            </TabWrapper>
            <TabWrapper span={12} isActive={this.state.key === 'map'}>
              <LocateMap
                onMapLoad={this.onMapLoad}
                translateFunc={t}
                ref={this.mapRef}
              />
            </TabWrapper>
          </Row>
        </LocateWrapper>
      </Card>
    );
  }
}

export default compose(
  inject('locationStore', 'directoryStore', 'detailsPageStore', 'orderStore'),
  withWhitelabelProps({
    countryCode: 'ui.common.countryCode',
  }),
  withTranslation(['success', 'locate'], { withRef: true })
)(LocatePage);
