import axios, { AxiosInstance } from 'axios';

type Config = {
  baseUrl?: string;
  headers?: {
    [key: string]: string;
  };
};

export default (config: Config): AxiosInstance =>
  axios.create({
    baseURL: config.baseUrl,
    headers: config.headers,
  });
