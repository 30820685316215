import AuthStore from '_common/stores/authStore';
import DirectoryStore from '_common/stores/directoryStore';
import ThemeStore from '_common/stores/themeStore';
import OrderStore from '_common/stores/orderStore';
import UiStore from '_common/stores/uiStore';
import EmbedStore from '_common/stores/embedStore';
import PaymentPageStore from 'pages/payment/stores/paymentPageStore';
import StartPageStore from 'pages/start/stores/startPageStore';
import LocationStore from '_common/stores/locationStore';
import TrackingStore from 'pages/tracking/stores/trackingStore';
import PrintOptionStore from 'pages/printOption/stores/printOptionPageStore';
import ReturnMethodStore from 'pages/returnMethod/stores/returnMethodPageStore';
import { apiClient } from '_common/api/clients/clients';
import commonStoresActions from '_common/actions';
import { WhiteLabelStores, initServices } from '_common/whitelabelConfig';
import amplitude from '_common/utils/amplitude';
import FeatureStore from '_common/stores/featureStore';

initServices({ apiClient });

const { DetailsPageStore, WhitelabelProxyStore } = WhiteLabelStores;

export {
  AuthStore,
  StartPageStore,
  DetailsPageStore,
  PaymentPageStore,
  ThemeStore,
  DirectoryStore,
  LocationStore,
  WhitelabelProxyStore,
  TrackingStore,
  OrderStore,
  UiStore,
  EmbedStore,
  FeatureStore,
};

const authStore = new AuthStore();
const themeStore = new ThemeStore();
const embedStore = new EmbedStore();
const startPageStore = new StartPageStore();
const detailsPageStore = new DetailsPageStore();
const paymentPageStore = new PaymentPageStore();
const directoryStore = new DirectoryStore();
const locationStore = new LocationStore();
const whitelabelProxyStore = new WhitelabelProxyStore();
const trackingStore = new TrackingStore();
const orderStore = new OrderStore();
const uiStore = new UiStore();
const printOptionStore = new PrintOptionStore();
const featureStore = new FeatureStore();
const returnMethodStore = new ReturnMethodStore();

const storesInstances = {
  uiStore,
  authStore,
  startPageStore,
  detailsPageStore,
  paymentPageStore,
  themeStore,
  embedStore,
  directoryStore,
  locationStore,
  whitelabelProxyStore,
  trackingStore,
  orderStore,
  printOptionStore,
  featureStore,
  returnMethodStore,
};

/** THIS is now our entry point for all stores actions. */
commonStoresActions.registerStores(storesInstances);

/** To avoid Circle dependencies - injecting actions after all stores were initialised */
Object.values(storesInstances).forEach(store => {
  if ('registerCommonActions' in store) {
    store.registerCommonActions(commonStoresActions);
  }
});

amplitude.registerCommonActions(commonStoresActions);

export default storesInstances;
