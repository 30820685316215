import { IMobXStores } from 'types/mobxStores';
import { IReturnFormFields } from 'types/company';
import { LatLng } from 'types/store';
import { IDetailsFormFields } from 'types/formFields';
import { ReactElement } from 'react';
import { EWL_FEATURES } from './features';
import { ValidationRule } from '@ant-design/compatible/lib/form';
import { DefaultTheme } from 'styled-components';

export interface TSPResult {
  summaryCode: number;
  fingerprint?: string;
  transactionId?: string;
  timestamp?: string;
  transactionDate?: string;
  amount?: number;
  surAmount?: number;
  surFee?: number;
  surRate?: number;
  baseAmount?: number;
  currency?: string;
  cardExpDate?: string;
  cardtype?: string;
  pan?: string;
  resultText?: string;
  spMerchantId?: string;
  resCode?: string;
  referenceId?: string;
}

export enum EAsyncStatus {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  IDLE = 'IDLE',
}

export enum EPrintOptions {
  PAPERLESS = 'PAPERLESS',
  PRINT_OWN = 'PRINT_OWN',
}

export enum EReturnType {
  DROP_OFF = 'CUSTOMER_RETURN',
  HOME_PICKUP = 'RETURN_HOME_PICKUP',
  // STORE_RETURN is for for store app, but doesn't used at the moment
  STORE_RETURN = 'STORE_RETURN',
}

export interface IOption {
  value: string;
  label: string;
}

export interface IProductJourneyType {
  NOT_SUPPORTED: boolean;
  INTEGRATED: boolean;
  NON_INTEGRATED: boolean;
}

export interface ICommonStoresActions {
  stores: IMobXStores | null;
  registerStores: (stores: IMobXStores) => void;
  loadTheme: () => Promise<DefaultTheme>;
  logout: () => void;
  refreshToken: () => void;
  getCompany: (companyName: string) => Promise<void>;
  createShipment: (company: string) => Promise<void>;
  getTrackingInfo: (trackingId: string, companyId: string) => Promise<void>;
  saveToStorage: (storeName: string, model: object) => void;
  validateAddress: (address: IDetailsFormFields) => Promise<void>;
  validateSession: (
    merchantId: string,
    resetAfterOrdersSubmit?: boolean
  ) => void;
  getLocationTypes: () => IOption[];
  getAccountNumberForHeader: () => string;
  resetStoresWithUserData: () => void;
  setConsumerPaymentWasSuccessful: (paymentDetails: TSPResult) => void;
  setDetailsFormField: (field: string, value: string) => void;
  saveDetailsFormToStorage: () => void;
  getOrderById: (company: string) => Promise<void>;
  getJourneyType: () => string;
  isPaymentsRequiredForIntegrated: () => boolean;
  getProductJourneyType: () => IProductJourneyType;
  getPurchaseWarrantyPeriod: () => number;
  getReturnFormFields: () => IReturnFormFields;
  searchStoresByCoords: (
    config: { lat: number; lng: number; company?: string },
    onDrag?,
    distance?
  ) => Promise<void>;
  searchStoresByGeoLocation: (company: string) => Promise<string>;
  setActiveStoreId: (storeId: string) => void;
  setLocationType: (locationType: string) => void;
  setSearchGeoCoordinates: (coords: LatLng) => void;
  getPrintOption: () => EPrintOptions;
  setLanguage: (locale: EAPP_LOCALES, isSilientSwitch: boolean) => void;
  validateFeatureAccess: (feature: EWL_FEATURES) => boolean;
  checkFeatureIsEnabled: (feature: EWL_FEATURES) => boolean;
}

export interface IRouterMatch {
  company: string;
  location?: string;
}

export interface IImageDimensions {
  desktop: {
    width: number;
    height: number;
  };
  mobile: {
    width: number;
    height: number;
  };
}

type TValidationParams = {
  allowedLengths?: number[];
  regex?: {
    value: RegExp;
    message?: string | React.ReactNode;
  };
  specialCharacters?: {
    regexValue: RegExp;
    errorText: string;
  };
  restrictRegex?: {
    value: RegExp;
    message?: string | React.ReactNode;
  };
  maxLength?: {
    value: number;
    message: string | React.ReactNode;
  };
};
export type TValidatePreconditions = (
  params: TValidationParams
) => (
  _: ValidationRule,
  inputValue: string,
  callback: (msg?: string | React.ReactNode) => void
) => void;

export interface IRouteNavigator {
  next: (url?: string) => void;
  previous: Function;
}

export type StepConfig = {
  id: number;
  texts: {
    [key: string]: string;
  };
};

export type CustomTextLanguageConfig = {
  language: EAPP_LOCALES;
  steps: StepConfig[];
};

export type ConsumerTextConfig = {
  languages?: CustomTextLanguageConfig[];
};

export interface IRoute {
  path: string;
  component: ReactElement;
}

export enum EDETAILS_ADDRESS_FIELD {
  ZIP = 'Zip',
  ADDRESS1 = 'AddressLine1',
  ADDRESS2 = 'AddressLine2',
  STATE = 'State',
  CITY = 'City',
  PHONE = 'Phone',
}

export enum EAPP_LOCALES {
  EN = 'en',
  JA = 'ja',
  AR = 'ar',
}

export enum BgType {
  IMAGE = 'image',
  COLOR = 'colour',
}

export enum EValidation_Errors {
  INVALID_ADDRESS = 'invalidAddress',
  INVALID_SHIPMENT = 'invalidShipment',
}

export enum ServerErrorType {
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  MAINTENANCE_ERROR = 'MAINTENANCE_ERROR',
  TECHNICAL_ERROR = 'TECHNICAL_ERROR',
}
