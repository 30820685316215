import { toast, ToastOptions } from 'react-toastify';
import { customAnimation } from '_common/components/NotificationBar/NotificationBar';

class NotificationService {
  showErrorMessage = (msg: string) => toast.error(msg);

  showInfoMessage = (msg: string, options?: ToastOptions) =>
    toast.info(msg, {
      transition: customAnimation,
      ...options,
    });

  showSuccessMessage = (msg: string) => toast.success(msg);

  dismiss = () => toast.dismiss();
}

export default new NotificationService();
