import styled, { css } from 'styled-components';
import { successStepIcon, primaryTickIcon } from 'assets';
import { rtlMixin } from '_common/constants/theme';

export const TrackersList = styled.div`
  flex-grow: 1;
  padding: 2rem 1.5rem;
  @media only screen and (max-width: 768px) {
    padding: 2rem 0 0;
    margin: 0 2rem;
    border-bottom: solid 1px #eee;
  }
`;

const getBgColor = ({ isNextEventOccurred }) =>
  isNextEventOccurred ? '#222;' : '#eee;';
const getBgMobileColor = ({ isNextEventOccurred }) =>
  isNextEventOccurred ? 'rgb(56, 177, 58);' : '#e6e7e8;';

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: url(${primaryTickIcon});
  z-index: ${props => props.theme.zIndex.elevation3};

  @media only screen and (max-width: 768px) {
    background: url(${successStepIcon}) center;
    background-size: cover;
    width: 30px;
    height: 30px;
  }
`;

export const Tracker = styled.div<{
  inactive: boolean;
  isNextEventOccurred: boolean;
}>`
  width: 100%;
  display: flex;
  position: relative;
  min-height: 10rem;

  ${Icon} {
    ${({ inactive }) =>
      inactive &&
      css`
        background: ${props => props.theme.colors.border};
      `}
  }

  &:before {
    content: '';
    display: block;
    z-index: 0;
    width: 4px;
    transform: translateX(29px);
    background-color: ${getBgColor};

    ${rtlMixin(css`
      transform: translateX(-29px);
    `)}

    @media only screen and (max-width: 768px) {
      width: 2px;
      background-color: ${getBgMobileColor};
      transform: translateX(16px);
      ${rtlMixin(css`
        transform: translateX(-16px);
      `)}
    }
  }

  &:last-of-type {
    margin-left: 4px;
    min-height: 7rem;
    @media only screen and (max-width: 768px) {
      margin-left: 2px;
    }
    &:before {
      display: none;
    }
  }
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
  ${rtlMixin(css`
    margin-left: initial;
    margin-right: 3rem;
  `)}
`;

export const PrimaryText = styled.div<{ inactive: boolean }>`
  font-size: ${props => props.theme.fontSize.xxl};
  font-weight: ${({ inactive }) => (inactive ? 'normal' : 'bold')};
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => (props.inactive ? '#a9a9a9' : '#000')};
  margin-bottom: 0.8rem;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
    font-weight: ${({ inactive }) => (inactive ? 'normal' : '600')};
    margin-top: 0.5rem;
  }
`;

export const SecondaryText = styled.div<{ isGreyed: boolean }>`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.m};
  color: ${({ isGreyed }) => (isGreyed ? '#9b9b9b' : '#222')};

  @media only screen and (max-width: 768px) {
    color: #9b9b9b;
    font-size: 14px;
  }
`;
