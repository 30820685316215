import { FormErrorMessage } from 'pages/start/elements';
import styled from 'styled-components';

export const StyledCard = styled.div<{
  maxWidth: number;
  noPadding?: boolean;
}>`
  width: 100%;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  position: relative;
  font-family: ${props => props.theme.fonts.primary};

  & .ant-card-bordered {
    border-radius: 3px;
    border: solid 1px #dbdbdb;
  }

  @media only screen and (max-width: 768px) {
    & .ant-card-bordered {
      border-radius: 0;
      border: unset;
      .ant-card-body {
        padding-bottom: 0;
      }
    }
  }

  .ant-card-body {
    padding: ${({ noPadding }) => (noPadding ? '0' : '2.5rem 3rem 3rem')};
    @media only screen and (max-width: 768px) {
      padding: ${({ noPadding }) => (noPadding ? '0' : '2rem')};
    }
  }

  & > div {
    background: rgba(
      255,
      255,
      255,
      ${props => (props.theme.config.css.transparency as number) * 0.01}
    );
    @media only screen and (max-width: 768px) {
      background: ${props => props.theme.colors.defaultBackground};
    }
  }
`;

export const StyledCardTitle = styled.div<{ mobilePadding?: string }>`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.xxl};
  font-weight: bold;
  color: #222;
  display: block;
  padding: 0 0 1rem;
  @media only screen and (max-width: 576px) {
    padding: ${({ mobilePadding = '0 0 2rem' }) => mobilePadding};
    font-size: ${props => props.theme.fontSize.xl};
  }
`;

export const StyledCardSubTitle = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.xxl};
  letter-spacing: 0;
  color: ${props => props.theme.colors.textAlt};
  margin-bottom: 3rem;
  @media only screen and (max-width: 576px) {
    font-size: ${props => props.theme.fontSize.m};
  }
`;

export const CardActionButtons = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  ${FormErrorMessage} {
    padding-top: 2rem;
    width: 90%;
    white-space: pre-wrap;
    word-break: break-all;
    text-align: center;
  }
`;

export const WarningText = styled(StyledCardSubTitle)`
  font-size: ${props => props.theme.fontSize.xl};
  margin-bottom: 1rem;
  text-align: center;
`;
