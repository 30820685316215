import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { TFunction } from 'i18next';
import {
  FooterContent,
  Link,
  LinkSection,
  Delimeter,
  Wrapper,
  StyledButton,
  LanguageIcon,
  LanguageMenuItem,
  MenuHolder,
  SocialLinkSection,
  SocialLink,
} from './elements';
import { CarrierLogo } from '../Header/elements';
import { IHrefWithLabel, ISocialLinkConfig } from 'types/whitelabelTypes';
import { gbIcon, arIcon, jpIcon } from 'assets';
import { IImageDimensions, EAPP_LOCALES } from 'types/core';
import commonStoresActions from '_common/actions';
import amplitude from '_common/utils/amplitude';

interface Props {
  termsAndConditions: IHrefWithLabel;
  privacy: IHrefWithLabel;
  showCarrierLogo: boolean;
  logoStacked: boolean;
  src: string;
  alt: string;
  dimensions: IImageDimensions;
  t: TFunction;
  isSuccessPage: boolean;
  isLocatePage: boolean;
  socialLinks?: ISocialLinkConfig[];
  availableLanguages: EAPP_LOCALES[];
}

type Language = {
  title: string;
  value: EAPP_LOCALES;
  iconName: string;
};

const languages: Language[] = [
  { title: 'English', value: EAPP_LOCALES.EN, iconName: gbIcon },
  { title: 'Japanese', value: EAPP_LOCALES.JA, iconName: jpIcon },
  { title: 'Arabic', value: EAPP_LOCALES.AR, iconName: arIcon },
];

function Footer(props: Props) {
  const {
    termsAndConditions,
    privacy,
    showCarrierLogo,
    src,
    alt,
    dimensions,
    logoStacked,
    t,
    isSuccessPage,
    socialLinks,
    isLocatePage,
    availableLanguages,
  } = props;

  const lnaguageList = languages.filter(l =>
    availableLanguages.includes(l.value)
  );
  const showLngMenu = lnaguageList.length > 1;

  const renderLanguageItem = (lng: Language) => {
    const setLanguage = () => {
      amplitude.logEventWithOrganisationAndUrl('Language changed', {
        language: lng.title,
      });
      commonStoresActions.setLanguage(lng.value, isSuccessPage);
    };

    return (
      <Menu.Item key={lng.value}>
        <LanguageMenuItem onClick={setLanguage}>
          <LanguageIcon iconName={lng.iconName} />
          {lng.title}
        </LanguageMenuItem>
      </Menu.Item>
    );
  };

  const menu = (
    <MenuHolder>
      <Menu>
        <Menu.Item>
          <LanguageMenuItem>
            <b>{t('components:selectLanguage')}</b>
            <DownOutlined />
          </LanguageMenuItem>
        </Menu.Item>
        {lnaguageList.map(lng => renderLanguageItem(lng))}
      </Menu>
    </MenuHolder>
  );

  const considerLogClick = event => {
    const text = (event.target as HTMLElement).innerText;
    if (text && text.trim() === t('components:language').trim()) {
      amplitude.logEventWithOrganisationAndUrl('clicked "Language" selector');
    }
  };

  const logClickEvent = name => () => {
    amplitude.logEventWithOrganisationAndUrl(name);
  };

  return (
    <FooterContent
      hasCarrierLogo={showCarrierLogo}
      logoStacked={logoStacked}
      withSocialIcons={Boolean(socialLinks)}
      locatePageView={isLocatePage}
    >
      <Wrapper>
        {showCarrierLogo && (
          <CarrierLogo
            src={src}
            alt={alt}
            dimensions={dimensions}
            hideOnDesktop={logoStacked}
          />
        )}
        <LinkSection onClick={e => considerLogClick(e)}>
          {showLngMenu && (
            <>
              <Dropdown overlay={menu} trigger={['click']} placement="topRight">
                <StyledButton>
                  {t('components:language')} <DownOutlined />
                </StyledButton>
              </Dropdown>
              <Delimeter spacing={27} />
            </>
          )}
          <Link
            href={privacy.href}
            onClick={logClickEvent('clicked "Privacy policy" link')}
          >
            {t(privacy.label)}
          </Link>
          <Delimeter spacing={27} />
          <Link
            href={termsAndConditions.href}
            onClick={logClickEvent('clicked "Terms and Conditions" link')}
          >
            {t(termsAndConditions.label)}
          </Link>
        </LinkSection>
        {socialLinks && (
          <SocialLinkSection>
            {socialLinks.map((socialLink: ISocialLinkConfig) => (
              <SocialLink href={socialLink.href} key={socialLink.alt}>
                <img src={socialLink.src} alt={socialLink.alt} />
              </SocialLink>
            ))}
          </SocialLinkSection>
        )}
      </Wrapper>
    </FooterContent>
  );
}

export default compose(withTranslation(['wl', 'components']))(Footer);
