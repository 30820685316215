import styled, { css } from 'styled-components';
import { OutlineButton } from '_common/components';

export const DateButton = styled(OutlineButton)<{
  isActive: boolean;
  disabled?: boolean;
}>`
  && {
    white-space: normal;
    margin: 0 5px 20px;
    height: 50px;
    padding: 5px;
    font-size: ${props => props.theme.fontSize.xs};
    width: 90px;
    ${props =>
      props.isActive &&
      css`
        background: #ffffb3 !important;
        color: ${props.theme.colors.textAlt} !important;
      `}
    ${props =>
      props.disabled &&
      css`
        background: #eee !important;
        color: ${props.theme.colors.textAlt} !important;
        pointer-events: disabled;
      `}

    @media only screen and (max-width: 768px) {
      min-width: 120px;
      font-size: ${props => props.theme.fontSize.s};
    }

    @media only screen and (max-width: 480px) {
      width: 40%;
      font-size: ${props => props.theme.fontSize.s};
    }

    @media only screen and (max-width: 420px) {
      display: block;
    }
  }
`;
