import trackingService from '_common/services/trackingService';
import { action, observable, ObservableMap, runInAction } from 'mobx';
import moment from 'moment';
import { WhiteLabelServices, WhiteLabelUi } from '_common/whitelabelConfig';
import { TrackingEvent, TrackingEventWithInternalState } from 'types/tracking';
import { EAsyncStatus } from 'types/core';

export const INTERNAL_EVENT_STATES = {
  EVENT_OCCURRED: 'EVENT_OCCURRED',
  EVENT_SKIPPED: 'EVENT_SKIPPED',
  EVENT_NOT_OCCURRED: 'EVENT_NOT_OCCURRED',
};

class TrackingStore {
  static NOT_FOUND_MESSAGE = 'Nothing found';

  @observable
  events: ObservableMap<
    string,
    TrackingEventWithInternalState
  > = observable.map();

  @observable
  latestEvent: string | null = null;

  @observable
  asyncStatus: EAsyncStatus = EAsyncStatus.IDLE;

  /**
   * Filters all incoming events by predicates and takes latest by time.
   * @param events - all order events.
   * @returns {*|T|T|number}
   */
  getFilteredEventsMap = events =>
    events.reduce((hashEvents, event: TrackingEvent) => {
      const { utcDateTime, eventType, customerVisible } = event;
      /** We interested only in specific event types. */
      if (
        !WhiteLabelServices.trackingEventChecker(eventType) ||
        !customerVisible
      ) {
        return hashEvents;
      }

      const storedEvent = hashEvents.get(eventType);
      if (
        !storedEvent ||
        moment(utcDateTime) > moment(storedEvent.utcDateTime)
      ) {
        hashEvents.set(eventType, event);
      }

      return hashEvents;
    }, new Map());

  /**
   * Sort events by expected order and adds internal helper state.
   * @param filteredEventsMap
   * @returns {*|T|T|number}
   */
  getOrderedEventsWithState = filteredEventsMap => {
    let shouldMarkSkippedEvents = false;
    return WhiteLabelUi.get('pages.tracking.trackersList')
      .reverse()
      .reduce((eventsInRightOrder, { type: eventType, description }) => {
        if (filteredEventsMap.has(eventType)) {
          /** We found the LATEST (logically) event, all skipped previous must be marked as 'skipped' */
          if (!shouldMarkSkippedEvents) {
            shouldMarkSkippedEvents = true;
            this.latestEvent = description;
          }
          eventsInRightOrder.unshift({
            ...filteredEventsMap.get(eventType),
            customerDescription: description,
            internalEventState: INTERNAL_EVENT_STATES.EVENT_OCCURRED,
          });
        } else {
          /** This event is expected to be, but not occurred yet, or just skipped. */
          eventsInRightOrder.unshift({
            customerDescription: description,
            eventType,
            internalEventState: shouldMarkSkippedEvents
              ? INTERNAL_EVENT_STATES.EVENT_SKIPPED
              : INTERNAL_EVENT_STATES.EVENT_NOT_OCCURRED,
          });
        }
        return eventsInRightOrder;
      }, []);
  };

  @action
  getTrackingEvents = async (trackingId: string, companyId: string) => {
    this.resetStore();
    this.asyncStatus = EAsyncStatus.LOADING;

    try {
      const events = await trackingService.getTrackingInfo(
        trackingId,
        companyId
      );
      const filteredEvents = this.getFilteredEventsMap(events);

      if (!filteredEvents.size) {
        return;
      }

      runInAction(() => {
        this.getOrderedEventsWithState(filteredEvents).forEach(event =>
          this.events.set(event.eventType, event)
        );
        this.asyncStatus = EAsyncStatus.SUCCESS;
      });
    } catch (e) {
      runInAction(() => {
        this.asyncStatus = EAsyncStatus.FAILED;
      });
      console.error('error::', e);
    }
  };

  @action
  resetStore = () => {
    this.latestEvent = null;
    this.events.clear();
    this.asyncStatus = EAsyncStatus.IDLE;
  };
}

export default TrackingStore;
