import Logo from '_common/components/Logo/Logo';
import backBtn from 'assets/images/back-arrow.svg';
import { inject } from 'mobx-react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { DefaultTheme, withTheme } from 'styled-components';
import {
  BackButton,
  HeaderWrapper,
  MerchantLogoWrapper,
  CarrierLogo,
  CarrierLogoWrapper,
  LogoPlaceholder,
} from './elements';
import { withWhitelabelProps } from '_common/whitelabelConfig';
import { EAsyncStatus, IRouterMatch } from 'types/core';
import { IReactionDisposer, reaction } from 'mobx';

interface Props extends RouteComponentProps<IRouterMatch> {
  theme: DefaultTheme;
  whiteLabeled: any;
  goBackAction: () => void;
  merchWebsite?: string;
  configsAsyncStatus: EAsyncStatus;
  themeStore: any;
}

class Header extends React.Component<Props, any> {
  state = {
    logoLoaded: false,
  };

  disposeReaction: IReactionDisposer;

  componentDidMount() {
    this.disposeReaction = reaction(
      () => this.props.themeStore.theme,
      newThemeVal => {
        if (!newThemeVal) return;

        if (!newThemeVal.assets.logoImage) {
          return this.setState({ logoLoaded: true });
        }

        this.processLogoLoading(newThemeVal);
      }
    );
  }

  componentWillUnmount() {
    this.disposeReaction();
  }

  processLogoLoading = theme => {
    const img = new Image();
    img.onload = () => {
      this.setState({
        logoLoaded: true,
      });
    };
    img.src = theme.assets.logoImage;
  };

  isHidden = () => typeof this.props.goBackAction !== 'function';

  render() {
    const {
      theme: {
        assets: { logoImage },
      },
      whiteLabeled: {
        carrierLogoSrc,
        logoDimensions,
        showCarrierLogo,
        centeredLogo,
      },
      merchWebsite,
      configsAsyncStatus,
    } = this.props;

    return (
      <HeaderWrapper>
        <BackButton
          onClick={this.props.goBackAction}
          isHidden={this.isHidden()}
        >
          <img src={backBtn} alt="back button" />
        </BackButton>
        {logoImage && (
          <MerchantLogoWrapper centered={centeredLogo}>
            {configsAsyncStatus === EAsyncStatus.LOADING ||
            !this.state.logoLoaded ? (
              <LogoPlaceholder />
            ) : (
              <Logo
                path={merchWebsite || '#'}
                url={logoImage}
                alt="merchant logo"
              />
            )}
          </MerchantLogoWrapper>
        )}
        <CarrierLogoWrapper>
          {showCarrierLogo && (
            <CarrierLogo src={carrierLogoSrc} dimensions={logoDimensions} />
          )}
        </CarrierLogoWrapper>
      </HeaderWrapper>
    );
  }
}

export default compose(
  withTheme,
  withRouter,
  inject('themeStore'),
  withWhitelabelProps({
    carrierLogoSrc: 'ui.common.carrierLogo.src',
    logoDimensions: 'ui.common.carrierLogo.dimensions',
    showCarrierLogo: 'ui.common.header.showCarrierLogo',
    centeredLogo: 'ui.common.header.centeredLogo',
  })
)(Header);
