import {
  ErrorWrapper,
  PageWrapper,
} from '_common/components/PageStates/element';
import React from 'react';
import { withTranslation } from 'react-i18next';

const PageError = ({ t }) => (
  <PageWrapper>
    <ErrorWrapper>{t('loadingError')}</ErrorWrapper>
  </PageWrapper>
);

export default withTranslation('components')(PageError);
