import React, { Component } from 'react';
import { withTranslation, TransProps } from 'react-i18next';
import {
  ReturnItemWrapper,
  ModalBody,
  Heading,
  ConfirmButton,
} from './elements';
import Select, { Option } from '_common/components/Select/Select';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { withWhitelabelProps } from '_common/whitelabelConfig';
import { IOrderProductModel } from 'types/order';
import { IDetailsStore } from 'types/internal';
import { TextArea, BaseModal } from '_common/components';
import ItemContent from './ItemContent';
import amplitude from '_common/utils/amplitude';
import OrderStore from '_common/stores/orderStore';
import { get, isEmpty } from 'lodash';
import ReturnItemModal from '_common/components/modals/ReturnItemModal';
import UIStore from '_common/stores/uiStore';
import DirectoryStore from '_common/stores/directoryStore';
import { validateOnSpecialCharacters } from '_common/utils/validationUtils';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { IFormErrors } from 'types/formFields';

type Props = TransProps & {
  uiStore: UIStore;
  formRef: React.RefObject<FormInstance>;
  product: IOrderProductModel;
  directoryStore: DirectoryStore;
  detailsPageStore: IDetailsStore;
  whiteLabeled: any;
  orderStore: OrderStore;
  onInputClick: (val: string) => () => void;
  onInputBlur: (val: string) => () => void;
  returnNotAllowed?: boolean;
  errorDescription?: string;
};

type State = {
  isItemModalOpen: boolean;
  isComplete: boolean;
};

@observer
class ReturnItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      product: { productId },
      detailsPageStore: {
        selectedReturnReasonsForProduct,
        selectedReturnCommentForProduct,
      },
    } = props;
    const chosenReason = selectedReturnReasonsForProduct.get(productId);
    const chosenComment = selectedReturnCommentForProduct.get(productId);
    this.state = {
      isItemModalOpen: false,
      isComplete: !!(chosenReason || chosenComment),
    };
  }

  handleCommentsInput = e => {
    const {
      product: { productId },
      orderStore: { updateIntegratedComment },
      detailsPageStore: { enableFormErrorMessage },
    } = this.props;
    enableFormErrorMessage(false);
    updateIntegratedComment(e.target.value, productId);
  };

  onCancelClick = () => {
    amplitude.logEventWithOrganisationAndUrl('Return Item cancel modal opened');
    this.props.uiStore.openModal({
      component: ReturnItemModal,
      width: 640,
      hasExtraCloseBtn: true,
      props: {
        product: this.props.product,
      },
      onClose: () => {
        amplitude.logEventWithOrganisationAndUrl(
          'clicked No for cancel returning item'
        );
        amplitude.logEventWithOrganisationAndUrl(
          'Return Item cancel modal closed'
        );
      },
      eventProps: {
        onSubmit: this.onCancel,
      },
    });
  };

  toggleItemModal = () => {
    this.props.formRef.current.resetFields();
    const { isItemModalOpen } = this.state;
    amplitude.logEventWithOrganisationAndUrl(
      `Return Item details modal ${isItemModalOpen ? 'closed' : 'opened'}`
    );
    this.setState({ isItemModalOpen: !isItemModalOpen });
  };

  onItemClick = () => {
    const { isComplete } = this.state;
    if (isComplete || this.props.returnNotAllowed) {
      return;
    }
    this.onItemEdit();
  };

  onItemEdit = () => {
    amplitude.logEventWithOrganisationAndUrl('clicked on edit return item');
    const {
      directoryStore: { activeReturnReasons, returnFormFields },
      whiteLabeled: { integratedCommentsEnabled },
      detailsPageStore: { setSelectedReturnCommentForProduct },
      product,
    } = this.props;
    const commentsEnabled =
      integratedCommentsEnabled && returnFormFields.reasonComments;
    const hasInputs = !!activeReturnReasons.length || commentsEnabled;

    if (hasInputs) {
      this.toggleItemModal();
    } else {
      this.setState({ isComplete: true });
      setSelectedReturnCommentForProduct(product, '');
    }
  };

  onConfirm = async () => {
    const {
      directoryStore: { activeReturnReasons, returnFormFields },
      detailsPageStore: {
        setSelectedReturnReasonsForProduct,
        setSelectedReturnCommentForProduct,
      },
      whiteLabeled: { integratedCommentsEnabled },
      product,
      formRef,
    } = this.props;
    await formRef.current.validateFields();
    const formErrors: IFormErrors = formRef.current.getFieldsError();
    const isValid = !Object.values(formErrors).some(
      ({ errors }) => !isEmpty(errors)
    );

    if (!isValid) {
      amplitude.logFormValidationErrors(formErrors, 'Details page');
      return;
    }
    const commentsEnabled =
      integratedCommentsEnabled && returnFormFields.reasonComments;
    const comment = this.props.formRef.current.getFieldValue('reasonComments');
    const reasonCode = activeReturnReasons.find(
      reason =>
        reason.code === this.props.formRef.current.getFieldValue('returnReason')
    );
    if (reasonCode) {
      setSelectedReturnReasonsForProduct(product, reasonCode);
    }
    if (commentsEnabled) {
      setSelectedReturnCommentForProduct(product, comment || '');
    }
    this.setState({ isComplete: true });
    this.toggleItemModal();
  };

  onCancel = () => {
    const {
      detailsPageStore: {
        clearSelectedReturnCommentForProduct,
        clearSelectedReturnReasonsForProduct,
      },
    } = this.props;
    amplitude.logEventWithOrganisationAndUrl(
      'clicked Yes for cancel returning item'
    );
    this.setState({ isComplete: false });
    amplitude.logEventWithOrganisationAndUrl('Return Item cancel modal closed');
    this.props.formRef.current.resetFields();
    clearSelectedReturnCommentForProduct();
    clearSelectedReturnReasonsForProduct();
    amplitude.logEventWithOrganisationAndUrl('Return Item cancelled');
  };

  getLabelWithColon = (key: string, t?: Function): string => {
    const result = t ? t(key) : key;
    return /\w$/.test(result) ? `${result}:` : result;
  };

  render() {
    const { getLabelWithColon } = this;
    const {
      product: {
        imageUrl,
        description,
        price,
        size,
        productId,
        priceCurrency,
        colour,
      },
      directoryStore: { activeReturnReasons, returnFormFields },
      detailsPageStore: {
        selectedReturnReasonsForProduct,
        selectedReturnCommentForProduct,
      },
      whiteLabeled: { integratedCommentsEnabled },
      onInputClick,
      onInputBlur,
      t,
      returnNotAllowed,
      errorDescription,
    } = this.props;
    const { isItemModalOpen, isComplete } = this.state;
    const reasonEnabled = !!activeReturnReasons.length;
    const commentsEnabled =
      integratedCommentsEnabled && returnFormFields.reasonComments;
    const chosenReason = selectedReturnReasonsForProduct.get(productId);
    const chosenComment = selectedReturnCommentForProduct.get(productId);
    return (
      <>
        {isItemModalOpen && (
          <BaseModal toggle={this.toggleItemModal} width={640} hasCloseBtn>
            <ItemContent
              imageUrl={imageUrl}
              description={description}
              priceCurrency={priceCurrency}
              price={price}
              size={size}
              colour={colour}
            />
            <ModalBody>
              {reasonEnabled && (
                <Form.Item
                  name="returnReason"
                  initialValue={get(chosenReason, 'code')}
                  label={
                    <Heading>
                      {getLabelWithColon('returnReasonLabel', t)}
                    </Heading>
                  }
                  rules={[
                    {
                      required: true,
                      message: t('defaultError'),
                    },
                  ]}
                >
                  <Select
                    placeholder={t('reasonPlaceholder')}
                    dropdownClassName="rpc-reason-select"
                    onFocus={onInputClick('returnReason')}
                    onChange={onInputBlur('returnReason')}
                  >
                    {activeReturnReasons.map(
                      ({ enabled, description: value, code }) =>
                        enabled && (
                          <Option key={code} value={code}>
                            {value}
                          </Option>
                        )
                    )}
                  </Select>
                </Form.Item>
              )}
              {commentsEnabled && (
                <Form.Item
                  name="reasonComments"
                  initialValue={chosenComment}
                  label={
                    <Heading>
                      {getLabelWithColon('reasonComment2Label', t)}
                    </Heading>
                  }
                  rules={[
                    {
                      required: returnFormFields.reasonCommentsMandatory,
                      message: t('defaultError'),
                    },
                    {
                      validator: (
                        rule: any,
                        value: string,
                        callback: (error?: string) => void
                      ) => {
                        validateOnSpecialCharacters(rule, value, callback);
                      },
                    },
                  ]}
                >
                  <TextArea
                    rows={3}
                    maxLength={300}
                    placeholder={t('reasonComments2Placeholder')}
                    onChange={this.handleCommentsInput}
                    onFocus={onInputClick('reasonComments')}
                    onBlur={onInputBlur('reasonComments')}
                  />
                </Form.Item>
              )}
              <ConfirmButton onClick={this.onConfirm}>
                {t('confirmBtn')}
              </ConfirmButton>
            </ModalBody>
          </BaseModal>
        )}
        <ReturnItemWrapper
          isComplete={isComplete}
          onClick={this.onItemClick}
          hasError={returnNotAllowed}
        >
          <ItemContent
            imageUrl={imageUrl}
            description={description}
            priceCurrency={priceCurrency}
            price={price}
            size={size}
            colour={colour}
            sizeTitle={t('size')}
            reason={chosenReason}
            comment={chosenComment}
            isComplete={isComplete}
            onCancel={this.onCancelClick}
            onEdit={this.onItemEdit}
            productError={errorDescription}
            fitContent
            hasEditButton={reasonEnabled || commentsEnabled}
            isDisabled={returnNotAllowed}
          />
        </ReturnItemWrapper>
      </>
    );
  }
}

export default compose(
  inject('detailsPageStore', 'directoryStore', 'orderStore', 'uiStore'),
  withWhitelabelProps({
    noReasonsText: 'ui.pages.details.noReasonsText',
    noActiveReasonsAllowed: 'ui.pages.details.noActiveReasonsAllowed',
    integratedCommentsEnabled:
      'ui.pages.details.integratedReasonCommentsEnabled',
    whitelabeledRules: 'ui.pages.details.rules',
  }),
  withTranslation(['details', 'constants'])
)(ReturnItem);
