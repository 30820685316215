import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TransProps, withTranslation } from 'react-i18next';
import { IRouterMatch } from 'types/core';
import { Card } from '_common/components';
import { StyledButtonLink } from '_common/components/Button/elements';
import { DirectoryStore } from 'stores';
import { inject } from 'mobx-react';
import { compose } from 'recompose';
import { get } from 'lodash';
import { Row } from 'antd';
import { CardSize } from '_common/components/Card/Card';
import { CardContent, TitleCentered } from './elements';

type Props = RouteComponentProps<IRouterMatch> &
  TransProps & {
    directoryStore: DirectoryStore;
    whiteLabeled: any;
    merchForRedirect?: string;
  };

/**
 * Ensures that we add the protocol onto the url so it's not relative to current
 * portal
 *
 * @param url
 */
const addHttp = (url: string) => {
  let addHttpUrl = url;
  if (!/^https?:\/\//.test(url)) {
    addHttpUrl = `https://${url}`;
  }
  return addHttpUrl;
};

const NotFoundPage = (props: Props) => {
  const { merchForRedirect, directoryStore, t } = props;

  let isURLDisabled;
  let website;
  /*
   * Confirm that we have a directory store before getting values, as may not
   * be set due to an error
   */
  if (directoryStore) {
    isURLDisabled = get(directoryStore, 'isURLDisabled');
    website = get(directoryStore, 'companyConfig.website');
  }

  const renderRedirectButton = () => {
    let href;
    if (isURLDisabled && website) {
      href = `${addHttp(website)}`;
    } else if (merchForRedirect) {
      href = `/${merchForRedirect}`;
    }

    if (!href) {
      return null;
    }

    return (
      <Row align="middle" justify="center">
        <StyledButtonLink href={href}>{t('visitLink')}</StyledButtonLink>
      </Row>
    );
  };

  return (
    <Card customSize={CardSize.LARGE}>
      <CardContent>
        <TitleCentered>{t('notFoundError')}</TitleCentered>
        {renderRedirectButton()}
      </CardContent>
    </Card>
  );
};

export default compose(
  inject('directoryStore'),
  withTranslation('notFound')
)(NotFoundPage);
