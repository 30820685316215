import React from 'react';
import { StyledInputLabel } from './elements';

interface Props {
  htmlFor: string;
  children: React.ReactNode;
}

export function InputLabel(props: Props) {
  return <StyledInputLabel {...props} />;
}

export default InputLabel;
