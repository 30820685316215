import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import qs from 'qs';

import RatingsBody from './RatingsBody';
import ratingsService from '_common/services/ratingsService';
import { IRouterMatch } from 'types/core';
import { RETURNS_PORTAL_BUILD_NAME } from '_common/constants/common';
import { Card } from '_common/components';

interface IState {
  rate: string;
}

interface IDecodedURLParams {
  interaction_id?: string;
  service_id?: string;
  rate?: string;
}

class Ratings extends React.Component<
  RouteComponentProps<IRouterMatch>,
  IState
> {
  state = {
    rate: '3',
  };

  componentDidMount() {
    const {
      location: { search },
      match: {
        params: { company },
      },
    } = this.props;
    const { interaction_id, service_id, rate } = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    if (!interaction_id && !rate) {
      return this.props.history.push(`/${company}`);
    }

    window.history.pushState('', '', `/${company}/ratings`);

    ratingsService
      .sendRating({
        interactionType: RETURNS_PORTAL_BUILD_NAME,
        interactionId: interaction_id,
        serviceType: 'STORE',
        serviceId: service_id,
        ratingType: 'FIVE_STARS',
        ratingValue: rate,
      })
      .catch(e => {
        console.error('Send rating error:', e);
      });

    this.setState({ rate });
  }

  render() {
    return (
      <Card noPadding>
        <RatingsBody rate={this.state.rate} />
      </Card>
    );
  }
}

export default Ratings;
