import {
  LoaderDots,
  LoaderText,
  LoaderWrapper,
  PageWrapper,
} from '_common/components/PageStates/element';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { compose } from 'recompose';

interface Props {
  global?: boolean;
  t: TFunction;
}
const PageLoader = ({ global, t }: Props) => (
  <PageWrapper global={global}>
    <LoaderWrapper>
      <LoaderText>{t('loading')}</LoaderText>
      <LoaderDots>
        <div />
        <div />
        <div />
        <div />
      </LoaderDots>
    </LoaderWrapper>
  </PageWrapper>
);

export default compose(withTranslation('components'))(PageLoader);
