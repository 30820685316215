import React from 'react';
import { StyledLogo, LogoWrapper } from './elements';

type Props = {
  url: string;
  alt: string;
  path: string;
};

const Logo = (props: Props) => (
  <a href={props.path} target="_blank" rel="noopener noreferrer">
    <LogoWrapper>
      <StyledLogo {...props} src={props.url} alt={props.alt} />
    </LogoWrapper>
  </a>
);

export default Logo;
