import styled from 'styled-components';

export const ErrorModalWrapper = styled.div`
  max-width: 400px;
  padding: 40px 45px;
  min-width: 150px;
`;

export const Text = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: ${props => props.theme.fontSize.xl};
  font-weight: 500;
  line-height: 1.4;
  color: #222222;
  text-align: center;
  margin-bottom: 32px;
`;

export const TextError = styled(Text)`
  color: red;
`;
