import { sessionStore, CURRENT_SESSION, CurrentSession } from 'storage';

const SESSION_LIFE_TIME = 86400000;
const resetSession = (merchantId: string) => {
  sessionStore.removeAll();
  return sessionStore.set(CURRENT_SESSION, {
    startDate: Date.now(),
    merchantId,
  });
};

export default (
  merchantId: string,
  resetAfterOrdersSubmit: boolean = false
) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if (!merchantId) {
    return resetSession('not-provided');
  }

  /** When the return is submitted to /v2/orders successfully, we should remove the session data */
  if (resetAfterOrdersSubmit) {
    return resetSession(merchantId);
  }

  const existingSession: CurrentSession = sessionStore.get(CURRENT_SESSION);
  if (!existingSession) {
    return resetSession(merchantId);
  }
  const {
    startDate: storedStartDate,
    merchantId: storedMerchantId,
  } = existingSession;
  if (
    merchantId !== storedMerchantId ||
    Date.now() - storedStartDate > SESSION_LIFE_TIME
  ) {
    return resetSession(merchantId);
  }
  /** just update session-signature, not deleting steps and form. */
  return sessionStore.set(CURRENT_SESSION, {
    startDate: Date.now(),
    merchantId,
  });
};
