import { action, observable, runInAction, computed, ObservableMap } from 'mobx';
import { sessionStore, MOBX_STORES } from 'storage';
import {
  getMerchantFromUrl,
  extractReasonCommentFromProduct,
} from '_common/utils';
import USPSServices from '../services';

import amplitude from '_common/utils/amplitude';
import { IReason } from 'types/company';
import { IOrderProductModel, ICreateReturnRequest } from 'types/order';
import { ICommonStoresActions } from 'types/core';
import { IStoreWithCommonActions } from 'types/internal';
import { detailsFormFields } from '_common/constants/stores';

const ITEMS_AMPLITUDE_EVENTS = {
  ADDED: 'Item added to return list',
};
class USPSDetailsPageStore implements IStoreWithCommonActions {
  static FORM_FIELDS = detailsFormFields;

  commonStoresActions: ICommonStoresActions;

  @observable
  formFields = { ...USPSDetailsPageStore.FORM_FIELDS };

  registerCommonActions = (commonStoresActions: ICommonStoresActions) => {
    this.commonStoresActions = commonStoresActions;
    this.commonStoresActions.validateSession(getMerchantFromUrl());
    this.formFields = {
      ...this.formFields,
      ...sessionStore.get(MOBX_STORES.DetailsPageStore),
    };
  };

  @observable
  showFormErrorMessage: string | false = false;

  @observable
  returningProductId: string | null = null;

  @observable
  selectedReturnReasonsForProduct: ObservableMap<
    string,
    IReason
  > = observable.map();

  @observable
  selectedReturnCommentForProduct: ObservableMap<
    string,
    string
  > = observable.map();

  @computed
  get isAnyProductSelectedForReturn() {
    return (
      this.selectedReturnReasonsForProduct.size > 0 ||
      this.selectedReturnCommentForProduct.size > 0
    );
  }

  @action
  setFormField = (field: string, value: string, antdUpdater?: Function) => {
    if (
      field === 'postcode' &&
      (value.length === 5 || value.length === 10) &&
      !this.formFields.addressLine1
    ) {
      this.locateAddress(value, antdUpdater);
    }
    this.formFields[field] = typeof value === 'string' ? value.trim() : value;
  };

  @action
  locateAddress = async (zipCode: string, antdUpdater: Function) => {
    try {
      // we have to figure out if all the fields will be present in lookup response when BE is ready
      const { found, body } = await USPSServices.proxyService.locateAddress({
        Zip5: zipCode,
      });
      if (!found) return;

      runInAction(() => {
        this.populateEmptyFields(body, antdUpdater);
      });
    } catch (error) {
      console.error('error:', error);
    }
  };

  @action
  createReturn = async (request: ICreateReturnRequest) => {
    try {
      return await USPSServices.proxyService.createReturn(request);
    } catch (error) {
      console.error('error:', error);
    }
  };

  @action
  populateEmptyFields(data, antdUpdater: Function) {
    const values = USPSServices.proxyService.mapBackAddressToFront(data);
    const antdObj = {};
    Object.keys(values).forEach(key => {
      if (values[key]) {
        this.formFields[key] = values[key];
        antdObj[key] = values[key];
      }
    });
    antdUpdater(antdObj);
  }

  saveFormToStorage() {
    this.commonStoresActions.saveToStorage(
      MOBX_STORES.DetailsPageStore,
      this.formFields
    );
  }

  @action
  enableFormErrorMessage = (error: string | false) => {
    this.showFormErrorMessage = error;
  };

  @action
  resetStore = () => {
    this.formFields = { ...USPSDetailsPageStore.FORM_FIELDS };
    this.selectedReturnReasonsForProduct = observable.map();
    this.selectedReturnCommentForProduct = observable.map();
  };

  @action
  setReturningProductId = (productId: string | null) => {
    if (productId === null) {
      this.returningProductId = null;
      return;
    }

    if (this.returningProductId === productId) {
      this.returningProductId = null;
    } else {
      amplitude.logEventWithOrganisationAndUrl(ITEMS_AMPLITUDE_EVENTS.ADDED, {
        returningProductId: productId,
      });
      this.returningProductId = productId;
    }
  };

  @action
  setSelectedReturnReasonsForProduct = (
    product: IOrderProductModel,
    returnReason: IReason
  ) => {
    this.selectedReturnReasonsForProduct.set(product.productId, returnReason);
    amplitude.logEventWithOrganisationAndUrl(
      'Selected return reason for product',
      { returningProductId: product, returnReason: returnReason.description }
    );
  };

  @action
  setSelectedReturnCommentForProduct = (
    product: IOrderProductModel,
    comment: string
  ) => {
    this.selectedReturnCommentForProduct.set(product.productId, comment);
    amplitude.logEventWithOrganisationAndUrl(
      'Selected return comment for product',
      { returningProductId: product, comment }
    );
  };

  @action
  clearSelectedReturnReasonsForProduct = () => {
    this.selectedReturnReasonsForProduct.clear();
  };

  @action
  clearSelectedReturnCommentForProduct = () => {
    this.selectedReturnCommentForProduct.clear();
  };

  @action
  deleteSelectedReturnReasonsForProduct = (productId: string) => {
    this.selectedReturnReasonsForProduct.delete(productId);
  };

  populateOrderWithReturnReasonComment = (order: IOrderProductModel) => {
    const updatedOrder = extractReasonCommentFromProduct(
      order,
      this.selectedReturnReasonsForProduct.get(order.productId),
      this.selectedReturnCommentForProduct.get(order.productId)
    );
    return updatedOrder;
  };
}

export default USPSDetailsPageStore;
