import * as React from 'react';
import {
  Icon,
  PrimaryText,
  SecondaryText,
  TextBlock,
  Tracker,
} from '../elements/tracker';
import { INTERNAL_EVENT_STATES } from '../stores/trackingStore';
import { TrackingEventWithInternalState } from 'types/tracking';
import { getSecondaryConfig } from '_common/utils/tracker';

interface Props extends TrackingEventWithInternalState {
  index: number;
  list: TrackingEventWithInternalState[];
}
export const StatusTracker = ({
  customerDescription,
  utcDateTime,
  internalEventState,
  index,
  list,
}: Props) => {
  const { secondaryText, isTextShouldBeGreyed } = getSecondaryConfig(
    utcDateTime,
    internalEventState
  );
  const inactive =
    internalEventState === INTERNAL_EVENT_STATES.EVENT_NOT_OCCURRED;
  const nextEvent = list[index + 1];

  let isNextEventOccurred = false;
  if (
    nextEvent &&
    nextEvent.internalEventState !== INTERNAL_EVENT_STATES.EVENT_NOT_OCCURRED
  ) {
    isNextEventOccurred = true;
  }

  return (
    <Tracker inactive={inactive} isNextEventOccurred={isNextEventOccurred}>
      <Icon />
      <TextBlock>
        <PrimaryText inactive={inactive}>{customerDescription}</PrimaryText>
        <SecondaryText isGreyed={isTextShouldBeGreyed}>
          {secondaryText}
        </SecondaryText>
      </TextBlock>
    </Tracker>
  );
};
