import React from 'react';
import { TransProps, withTranslation } from 'react-i18next';
import { OrderLine } from 'types/order';
import { compose } from 'recompose';
import {
  ItemHolder,
  Image,
  ContentHolder,
  Description,
  Info,
} from './elements';
import { CURRENCY_SYMBOLS } from '_common/constants/common';

type Props = TransProps & {
  orderLine: OrderLine;
};

const Product = ({ orderLine, t }: Props) => {
  const { product, priceCurrency } = orderLine;
  return (
    <ItemHolder>
      <Image imageUrl={product.imageUrl} />
      <ContentHolder>
        <Description>{product.description}</Description>
        <Info>
          {CURRENCY_SYMBOLS[priceCurrency]}
          {product.price}
          <br />
          {product.size && t('size')} {product.size}
          <br />
          {product.colour && t('color')} {product.colour}
        </Info>
      </ContentHolder>
    </ItemHolder>
  );
};

export default compose(withTranslation('returnSubmitted'))(Product);
