import React from 'react';
import { StyledLink } from './elements';
import { formatTermsAndConditionUrl } from '_common/utils';
import amplitude from '_common/utils/amplitude';
import { TFunction } from 'i18next';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

interface Props {
  termsAndConditionsUrl: string;
  retailerName: string;
  label?: string;
  t: TFunction;
}

const TermsLink = ({
  termsAndConditionsUrl,
  retailerName,
  label,
  t,
}: Props) => {
  const handleTermsClick = () => {
    const { href: url } = window.location;
    amplitude.logEvent('clicking on view T&Cs', {
      url,
      retailerName,
    });
  };

  return (
    <StyledLink
      href={formatTermsAndConditionUrl(termsAndConditionsUrl)}
      onClick={handleTermsClick}
      onAuxClick={handleTermsClick}
      target="_blank"
    >
      {label || t('policyLink')}
    </StyledLink>
  );
};

export default compose(withTranslation('start'))(TermsLink);
