import { action, observable } from 'mobx';
import { max, values } from 'lodash';
import qs from 'qs';

/**
 * Observes all states related to embedding mode.
 */
class EmbedStore {
  constructor() {
    this.prepopulatedParams = qs.parse(window.location.search.slice(1));
  }

  embedded: boolean =
    window.location !== window.parent.location &&
    window.name === 'doddleConsumerWidget';

  prepopulatedParams: { orderId?: string; email?: string } = {};

  @observable
  heights: { [objNum: string]: number }[] = [];

  @action
  setHeight = (objName: string, height: number) => {
    this.heights[objName] = height;
    if (this.embedded && window.parent.postMessage) {
      const maxHeight = max(values(this.heights));
      window.parent.postMessage({ doddleWidgetHeight: maxHeight }, '*');
    }
  };
}

export default EmbedStore;
