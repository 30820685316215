import React from 'react';
import { TFunction } from 'i18next';
import { ProductImage, ProductWrapper, ProductDescription } from '../elements';
import { IOrderProductModel } from 'types/order';
import { getMerchantFromUrl } from '_common/utils';
import { includes } from 'lodash';

type Props = {
  product: IOrderProductModel;
  t: TFunction;
};

const IntegratedProduct = ({
  product: {
    imageUrl,
    description,
    quantity,
    size,
    price,
    priceCurrency,
    colour,
  },
  t,
}: Props) => {
  // THIS IS A WORKAROUND REQUESTED IN TECH-11851 (PLANET_BLUE) merchant
  const renderPrice = () => {
    if (
      includes(['PLANET_BLUE', 'PLANETBLUE'], getMerchantFromUrl()) ||
      typeof price === 'undefined'
    ) {
      return null;
    }
    return `${priceCurrency}${price}`;
  };
  return (
    <ProductWrapper>
      <ProductImage imageUrl={imageUrl} />
      <div>
        <ProductDescription>{description}</ProductDescription>
        {colour && (
          <>
            {`${t('colour')}: ${colour}`}
            <br />
          </>
        )}
        Qty: {quantity}
      </div>
      <div>
        {size && `${t('size')}: ${size}`}
        <br />
        {renderPrice()}
      </div>
    </ProductWrapper>
  );
};

export default IntegratedProduct;
