import { apiClient } from '_common/api/clients/clients';
import { toWebSafeFormat } from '../utils';

const getCompany = async (companyName: string): Promise<any> => {
  const response = await apiClient.get(
    `/v1/companies/${toWebSafeFormat(companyName)}?ts=${Date.now()}`
  );
  return response.data.resources;
};

export default {
  getCompany,
};
