import i18nService from '_common/services/i18nService';

export const DEFAULT_SPECIAL_CHARS_REGEX = {
  regexValue: /([}{[\]<>!#$%^*])/,
  errorText: i18nService.t('constants:DEFAULT_SPECIAL_CHARS_REGEX'),
};

export const REASON_COMMENTS_REGEX = {
  regexValue: /([}{[\]<>#$%^*])/,
  errorText: i18nService.t('constants:REASON_COMMENTS_REGEX'),
};

/*
  this only allows:
  full-width Katakana (part of Japanese),
*/
export const doubleByteKatakana = '\u30a1-\u30ff';

/*
  this only allows:
  full-width Japanese,
  full-width English,
*/
export const doubleByteChars =
  '\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff21-\uff5a';

/*
  this only allows:
  full-width numbers,
*/
export const doubleByteNumbers = '\uff10-\uff19';

/*
  this only allows:
  full-width symbols: ALL (including space)
  note: Half-width kana characters are excluded: from \uff66 to \uff9f
*/
export const doubleByteSybmols =
  '\u00a0-\u00df' +
  '\u0020-\u0030' +
  '\u2010-\u2040' +
  '\u2150-\u2473' +
  '\u2500-\u2600' +
  '\u3000-\u3039' +
  '\u30fb' +
  '\u3200-\u4dbf' +
  '\u4e00-\u9fff' +
  '\uff00-\uff0f' +
  '\uff1a-\uff65' +
  '\uffa0-\uffef';

/*
  this only allows:
  full-width symbols: －, －,－ [short and long hyphenes]
*/
export const doubleByteHyphenes = '\u30fc\u2015\uff0d\u2500';
