import {
  ConsumerTextConfig,
  StepConfig,
  CustomTextLanguageConfig,
} from 'types/core';
import { forEach } from 'lodash';
import { i18n } from 'i18next';
import { CUSTOM_TEXT_NAMESPACE } from '_common/constants/customTextMap';

export const storeCustomTextsAsI18nResources = (
  i18nService: i18n,
  consumerText: ConsumerTextConfig
) => {
  if (!consumerText) {
    return;
  }
  forEach(consumerText.languages, (lang: CustomTextLanguageConfig) => {
    const { steps, language } = lang;
    forEach(steps, (step: StepConfig) => {
      forEach(Object.entries(step.texts), (txt: string[]) => {
        i18nService.addResource(
          language,
          CUSTOM_TEXT_NAMESPACE,
          `${CUSTOM_TEXT_NAMESPACE}_${step.id}_${txt[0]}`,
          txt[1]
        );
      });
    });
  });
};
