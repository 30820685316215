import React from 'react';
import { Wrapper } from './LoaderArcElements';

interface Props {
  color?: string;
  displayText?: string;
  fontSize?: number;
  arcSize?: number;
  arcBorderSize?: number;
}
export default ({
  color,
  fontSize,
  arcSize,
  arcBorderSize,
  displayText = 'Loading...',
}: Props) => (
  <Wrapper
    color={color}
    fontSize={fontSize}
    arcSize={arcSize}
    arcBorderSize={arcBorderSize}
  >
    <div />
    <div />
    <div />
    <div />
    <span>{displayText}</span>
  </Wrapper>
);
