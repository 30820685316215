import { Select } from 'antd';
import styled, {
  createGlobalStyle,
  css,
  DefaultTheme,
} from 'styled-components';
import {
  FORM_ITEM_HEIGHT,
  FORM_ITEM_WRAPPED_HEIGHT,
  FORM_ITEM_WRAPPED_MARGIN,
} from '_common/constants/styles';

export const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selector {
    height: ${FORM_ITEM_HEIGHT} !important;
    border: solid 1px #e8e8e8 !important;
    align-items: center;
  }

  .ant-select-selector-rendered {
    line-height: ${FORM_ITEM_HEIGHT};
    color: ${props => props.theme.colors.textAlt};
  }

  .ant-select-selection-placeholder {
    font-family: ${props => props.theme.fonts.primary};
    font-size: ${props => props.theme.fontSize.m};
    font-weight: 300;
    letter-spacing: 0;
    color: ${props => props.theme.colors.textAlt2} !important;
    display: flex;
    align-items: center;
  }

  .ant-select-selection-item {
    font-size: ${props => props.theme.fontSize.m};
    font-family: ${props => props.theme.fonts.primary};
  }

  .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: none;
  }

  @media only screen and (max-width: 768px) {
    .ant-select-selector--single {
      height: 50px;
    }

    .ant-select-selector-rendered {
      line-height: 50px;
    }
  }
`;

export const SelectDropdownStyles = createGlobalStyle<{
  theme: DefaultTheme;
  wrapOptionText?: boolean;
}>`
  .rc-virtual-list-holder-inner {
    & > .ant-select-item-option {
      font-family: ${props => props.theme.fonts.primary} !important;
      font-size: ${props => props.theme.fontSize.m} !important;
      min-height: ${FORM_ITEM_HEIGHT};
      line-height: ${FORM_ITEM_HEIGHT};
      ${({ wrapOptionText }) =>
        wrapOptionText &&
        css`
          & > .ant-select-item-option-content {
            white-space: pre-wrap;
            word-break: break-word;
            line-height: ${FORM_ITEM_WRAPPED_HEIGHT};
            margin-top: ${FORM_ITEM_WRAPPED_MARGIN};
            margin-bottom: ${FORM_ITEM_WRAPPED_MARGIN};
          }
        `}
      border-bottom: 1px solid #e8e8e8;
      flex: 1 1 auto;
      transition: none;
      &:hover {
        background: #121212;
        color: #fff;
      }
    }

    & li:last-child {
      border-bottom: unset;
    }

    & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #121212;
      color: #fff;
      // wrapped text has some jumping glitches when font-weight is applied
      ${({ wrapOptionText }) =>
        !wrapOptionText &&
        css`
          font-weight: 200;
        `}
    }
  }

  @media only screen and (max-width: 768px) {
    .rpc-reason-select, .rpc-state-select {
      & .ant-select-dropdown-menu {
        & li {
          min-height: 50px;
          line-height: 50px;
        }
      }
    }
  }

  .rpc-state-select {
    & .ant-select-dropdown-menu {
      max-height: 350px;
      overflow-y: auto;
    }
  }
`;
