import React from 'react';
import { DateButton } from './elements';

export interface Props {
  value: string;
  onSelect: (string) => void;
  isActive: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

const DateTimeOption = ({
  isActive,
  value,
  onSelect,
  children,
  disabled,
}: Props) => {
  const onMethodSelect = () => onSelect(value);

  return (
    <DateButton
      isActive={isActive}
      onClick={onMethodSelect}
      disabled={disabled}
    >
      {children}
    </DateButton>
  );
};

export default DateTimeOption;
