import { some } from 'lodash';
import { isPaymentPageAvailable } from '_common/utils';

export default stores => {
  const { selectedReturnReasonsForProduct } = stores.detailsPageStore;
  return (
    isPaymentPageAvailable &&
    some(
      [...selectedReturnReasonsForProduct.values()],
      ({ enabled, paymentRequired }) => enabled && paymentRequired
    )
  );
};
