/**
 * Parse SSM param to list of enabled WL features.
 */
export const parseEnabledFeatures = (featuresString: string): string[] => {
  if (!featuresString) {
    console.error(
      'Invalid enabled features configuration. featuresString:',
      featuresString
    );
  }
  return featuresString
    .split(',')
    .filter((name: string) => name && name.toUpperCase() !== 'N/A');
};
