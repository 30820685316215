import React from 'react';
import ReactResizeDetector from 'react-resize-detector';

const CustomResizeDetector = ({ isEmbedded, children, ...rest }) =>
  isEmbedded ? (
    <ReactResizeDetector {...rest}>{children}</ReactResizeDetector>
  ) : (
    children
  );

export default CustomResizeDetector;
