import Icon from '@ant-design/icons';
import { Select as AntdSelect, SelectProps } from 'antd';
import React, { forwardRef } from 'react';
import { compose } from 'recompose';
import { SelectDropdownStyles, StyledSelect } from './elements';
import { IconProps } from '@ant-design/compatible/lib/icon';
import { withWhitelabelProps } from '_common/whitelabelConfig';

export const { Option } = AntdSelect;

const CustomIcon = (props: IconProps) => {
  const Svg = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      viewBox="0 0 14 8"
    >
      <path
        fill="#111"
        fillRule="nonzero"
        d="M7 5.648L12.627 0 14 1.378 7 8 0 1.378 1.373 0z"
      />
    </svg>
  );

  return <Icon component={Svg} {...props} />;
};

interface Props extends SelectProps<string> {
  children: React.ReactNode;
  onClick?: (event) => void;
  whiteLabeled: {
    wrapReasonCodeText: boolean;
  };
}
const Select = forwardRef<any>(
  ({ whiteLabeled, ...props }: Props, ref: any) => (
    <div onClick={props.onClick} aria-hidden="true">
      <SelectDropdownStyles wrapOptionText={whiteLabeled.wrapReasonCodeText} />
      <StyledSelect
        suffixIcon={<CustomIcon />}
        {...props}
        listHeight={1000}
        ref={ref}
      >
        {props.children}
      </StyledSelect>
    </div>
  )
);

export default compose(
  withWhitelabelProps({
    wrapReasonCodeText: 'ui.pages.details.wrapReasonCodeText',
  })
)(Select);
