import styled from 'styled-components';

const Icon = styled.div<{
  width: number;
  height: number;
  image: string;
  disabled?: boolean;
}>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background-image: url(${({ image }) => image});
  background-size: cover;
  flex-shrink: 0;
`;

export default Icon;
