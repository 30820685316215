import { DEFAULT_LOCALE, ROOT_ORG_FULLNAME } from '../constants/common';
import { EAPP_LOCALES } from 'types/core';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';
/** i18next locales */
import i18nEn from 'i18next-icu/locale-data/en';
import i18nJa from 'i18next-icu/locale-data/ja';
import i18nAr from 'i18next-icu/locale-data/ar';
/** antd locales */
import antdEn from 'antd/es/locale/en_GB';
import antdJa from 'antd/es/locale/ja_JP';
import antdAr from 'antd/es/locale/ar_EG';
/** moment locales */
import 'moment/locale/ja';
import 'moment/locale/ar';
import { localStore as storage, USER_LOCALE } from 'storage';

import detailsEN from 'assets/locales/en/details.json';
import locateEN from 'assets/locales/en/locate.json';
import constantsEN from 'assets/locales/en/constants.json';
import notFoundEN from 'assets/locales/en/notFound.json';
import returnSubmittedEN from 'assets/locales/en/returnSubmitted.json';
import printOptionEN from 'assets/locales/en/printOption.json';
import returnMethodEN from 'assets/locales/en/returnMethod.json';
import startEN from 'assets/locales/en/start.json';
import successEN from 'assets/locales/en/success.json';
import paymentEN from 'assets/locales/en/payment.json';
import trackingEN from 'assets/locales/en/tracking.json';
import componentsEN from 'assets/locales/en/components.json';
import storesEN from 'assets/locales/en/stores.json';
import utilsEN from 'assets/locales/en/utils.json';
import wlAUSPEN from 'assets/locales/en/wlAUSP.json';
import wlUSPSEN from 'assets/locales/en/wlUSPS.json';
import wlStarlinksEN from 'assets/locales/en/wlStarlinks.json';
import wlYamatoEN from 'assets/locales/en/wlYamato.json';

import detailsAR from 'assets/locales/ar/details.json';
import locateAR from 'assets/locales/ar/locate.json';
import constantsAR from 'assets/locales/ar/constants.json';
import notFoundAR from 'assets/locales/ar/notFound.json';
import returnSubmittedAR from 'assets/locales/ar/returnSubmitted.json';
import printOptionAR from 'assets/locales/ar/printOption.json';
import returnMethodAR from 'assets/locales/ar/returnMethod.json';
import startAR from 'assets/locales/ar/start.json';
import paymentAR from 'assets/locales/ar/payment.json';
import successAR from 'assets/locales/ar/success.json';
import trackingAR from 'assets/locales/ar/tracking.json';
import componentsAR from 'assets/locales/ar/components.json';
import storesAR from 'assets/locales/ar/stores.json';
import utilsAR from 'assets/locales/ar/utils.json';
import wlAUSPAR from 'assets/locales/ar/wlAUSP.json';
import wlUSPSAR from 'assets/locales/ar/wlUSPS.json';
import wlStarlinksAR from 'assets/locales/ar/wlStarlinks.json';
import wlYamatoAR from 'assets/locales/ar/wlYamato.json';

import detailsJP from 'assets/locales/jp/details.json';
import locateJP from 'assets/locales/jp/locate.json';
import constantsJP from 'assets/locales/jp/constants.json';
import notFoundJP from 'assets/locales/jp/notFound.json';
import returnSubmittedJP from 'assets/locales/jp/returnSubmitted.json';
import printOptionJP from 'assets/locales/jp/printOption.json';
import returnMethodJP from 'assets/locales/jp/returnMethod.json';
import startJP from 'assets/locales/jp/start.json';
import paymentJP from 'assets/locales/jp/payment.json';
import successJP from 'assets/locales/jp/success.json';
import trackingJP from 'assets/locales/jp/tracking.json';
import componentsJP from 'assets/locales/jp/components.json';
import storesJP from 'assets/locales/jp/stores.json';
import utilsJP from 'assets/locales/jp/utils.json';
import wlAUSPJP from 'assets/locales/jp/wlAUSP.json';
import wlUSPSJP from 'assets/locales/jp/wlUSPS.json';
import wlStarlinksJP from 'assets/locales/jp/wlStarlinks.json';
import wlYamatoJP from 'assets/locales/jp/wlYamato.json';

import {
  TextNamespaceItem,
  CUSTOM_TEXT_NAMESPACE,
  CUSTOM_TEXT_MAP,
} from '_common/constants/customTextMap';

import { isString, isArray } from 'lodash';

export const ANTD_LOCALE_MAPPING = {
  [EAPP_LOCALES.EN]: antdEn,
  [EAPP_LOCALES.JA]: antdJa,
  [EAPP_LOCALES.AR]: antdAr,
};

export const I18N_LOCALE_MAPPING = {
  [EAPP_LOCALES.EN]: i18nEn,
  [EAPP_LOCALES.JA]: i18nJa,
  [EAPP_LOCALES.AR]: i18nAr,
};

export const getCurrentLocale = () =>
  storage.get(USER_LOCALE) || DEFAULT_LOCALE;

export const FONTS_LOCALE_MAPPING = {
  [EAPP_LOCALES.EN]: {
    basic: 'Arial, Helvetica, sans-serif',
    primary: 'PublicSans, Arial, Helvetica, sans-serif',
  },
  [EAPP_LOCALES.JA]: {
    basic: 'Arial, Helvetica, sans-serif',
    primary: 'PublicSans, Arial, Helvetica, sans-serif',
  },
  [EAPP_LOCALES.AR]: {
    basic: 'Arial, Helvetica, sans-serif',
    primary: 'DINNextLTArabic, Arial, Helvetica, sans-serif',
  },
};

const icu = new ICU({
  localeData: I18N_LOCALE_MAPPING[EAPP_LOCALES.EN], // you also can pass in array of localeData
});

const whiteLabelConfigByOrgEN = {
  AUSTRALIA_POST: wlAUSPEN,
  DODDLE_USPS: wlUSPSEN,
  STARLINKS: wlStarlinksEN,
  YAMATO: wlYamatoEN,
};
const whiteLabelConfigByOrgAR = {
  AUSTRALIA_POST: wlAUSPAR,
  DODDLE_USPS: wlUSPSAR,
  STARLINKS: wlStarlinksAR,
  YAMATO: wlYamatoAR,
};
const whiteLabelConfigByOrgJP = {
  AUSTRALIA_POST: wlAUSPJP,
  DODDLE_USPS: wlUSPSJP,
  STARLINKS: wlStarlinksJP,
  YAMATO: wlYamatoJP,
};

const wlConfigEn = whiteLabelConfigByOrgEN[ROOT_ORG_FULLNAME];
const wlConfigAr = whiteLabelConfigByOrgAR[ROOT_ORG_FULLNAME];
const wlConfigJp = whiteLabelConfigByOrgJP[ROOT_ORG_FULLNAME];

type ITranslator = {
  interpolator: {
    interpolate: (tpl: string, options: any, language: EAPP_LOCALES) => string;
  };
  language: EAPP_LOCALES;
};

const customInterpolator = (
  translator: ITranslator,
  subst: any,
  key: string,
  options: { ns: string }
): string => {
  const substitutionObj = subst[key];
  let hasDynamicData = false;
  if (isString(substitutionObj)) {
    return i18n.getResource(
      translator.language,
      CUSTOM_TEXT_NAMESPACE,
      substitutionObj
    );
  }
  if (isArray(substitutionObj)) {
    const tpl: string = substitutionObj
      .map((sub: { type: string; val: string; key?: string }) => {
        let res: string;
        if (sub.type === 'key') {
          res = i18n.getResource(
            translator.language,
            CUSTOM_TEXT_NAMESPACE,
            sub.val
          );
          hasDynamicData = !!res;
          return res;
        }
        return sub.val;
      })
      .join(' ');

    return hasDynamicData
      ? translator.interpolator.interpolate(tpl, options, translator.language)
      : '';
  }
};

const postProcess = (
  value: string,
  key: string[],
  options: { ns: string },
  translator: ITranslator
) => {
  const keyVal = key.join('');
  const literalNsArr = keyVal.split(':');
  const literalNs = literalNsArr.length > 1 ? literalNsArr[0] : null;
  const nsSubst: TextNamespaceItem = CUSTOM_TEXT_MAP[options.ns];
  const nsLiteralSubst = literalNs && CUSTOM_TEXT_MAP[literalNs];
  const literalKeyVal = literalNsArr[literalNsArr.length - 1];
  let res: string;

  if (nsSubst) {
    if (nsSubst[keyVal]) {
      res = customInterpolator(translator, nsSubst, keyVal, options);
    } else if (nsSubst[literalKeyVal]) {
      res = customInterpolator(translator, nsSubst, literalKeyVal, options);
    }
  }
  if (nsLiteralSubst) {
    if (nsLiteralSubst[keyVal]) {
      res = customInterpolator(translator, nsLiteralSubst, keyVal, options);
    } else if (nsLiteralSubst[literalKeyVal]) {
      res = customInterpolator(
        translator,
        nsLiteralSubst,
        literalKeyVal,
        options
      );
    }
  }

  return res || value;
};

i18n
  .use(icu)
  .use(initReactI18next)
  .use({
    type: 'postProcessor',
    name: 'substituteDefaultValues',
    process: postProcess,
  })
  .init({
    resources: {
      [EAPP_LOCALES.EN]: {
        details: detailsEN,
        locate: locateEN,
        constants: constantsEN,
        printOption: printOptionEN,
        returnMethod: returnMethodEN,
        start: startEN,
        notFound: notFoundEN,
        returnSubmitted: returnSubmittedEN,
        success: successEN,
        payment: paymentEN,
        tracking: trackingEN,
        stores: storesEN,
        utils: utilsEN,
        components: componentsEN,
        wl: wlConfigEn,
      },
      [EAPP_LOCALES.AR]: {
        details: detailsAR,
        locate: locateAR,
        constants: constantsAR,
        printOption: printOptionAR,
        returnMethod: returnMethodAR,
        start: startAR,
        notFound: notFoundAR,
        returnSubmitted: returnSubmittedAR,
        payment: paymentAR,
        success: successAR,
        tracking: trackingAR,
        stores: storesAR,
        utils: utilsAR,
        components: componentsAR,
        wl: wlConfigAr,
      },
      [EAPP_LOCALES.JA]: {
        details: detailsJP,
        locate: locateJP,
        constants: constantsJP,
        printOption: printOptionJP,
        returnMethod: returnMethodJP,
        start: startJP,
        notFound: notFoundJP,
        returnSubmitted: returnSubmittedJP,
        payment: paymentJP,
        success: successJP,
        tracking: trackingJP,
        stores: storesJP,
        utils: utilsJP,
        components: componentsJP,
        wl: wlConfigJp,
      },
    },
    lng: getCurrentLocale(),
    fallbackLng: DEFAULT_LOCALE,
    defaultNS: 'common',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true,
      useSuspense: false,
    },
    postProcess: ['substituteDefaultValues'],
  });

export default i18n;
