import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { compose } from 'recompose';
import {
  Box,
  FormContainer,
  Star,
  StarsContainer,
  SubTitle,
  Title,
  Wrapper,
} from '../elements';
import { WhiteLabelUi } from '_common/whitelabelConfig';
import { getEnvVar } from '_common/utils/environmentVariable';

interface RatingProps {
  rate: string;
  t: TFunction;
}

class RatingsBody extends Component<RatingProps> {
  buildStars = (activeIcon: string, disabledIcon: string) =>
    Array.from({ length: 5 }, (_, i) => (
      <Star
        src={Number(this.props.rate) >= i + 1 ? activeIcon : disabledIcon}
        key={i}
        alt="star-image"
      />
    ));

  render() {
    const {
      title,
      subTitle,
      icons: { starActive, starDisabled },
    } = WhiteLabelUi.pages.ratings.config;
    const typeFormUrl = getEnvVar('REACT_APP_RATING_APP_URL');
    const { t } = this.props;
    return (
      <Wrapper>
        <StarsContainer>
          {this.buildStars(starActive, starDisabled)}
        </StarsContainer>
        <Title>{t(`wl:${title}`)}</Title>
        <SubTitle>{t(`wl:${subTitle}`)}</SubTitle>
        <Box>
          <FormContainer
            className="typeform-widget"
            data-url={typeFormUrl}
            data-hide-headers
            data-hide-footer
            style={{ width: '100%', height: '440px' }}
          >
            {((): any => {
              let js;
              let q;
              const d = document;
              const gi = d.getElementById;
              const ce = d.createElement;
              const gt = d.getElementsByTagName;
              const id = 'typef_orm';
              const b = 'https://embed.typeform.com/';
              if (!gi.call(d, id)) {
                js = ce.call(d, 'script');
                js.id = id;
                js.src = `${b}embed.js`;
                // eslint-disable-next-line
                q = gt.call(d, 'script')[0];
                q.parentNode.insertBefore(js, q);
              }
            })()}
          </FormContainer>
        </Box>
      </Wrapper>
    );
  }
}

export default compose(withTranslation('wl'))(RatingsBody);
