import { Input } from 'antd';
import * as React from 'react';
import styled, { AnyStyledComponent, css } from 'styled-components';
import { FORM_ITEM_HEIGHT } from '_common/constants/styles';
import { rtlMixin } from '_common/constants/theme';

const { TextArea } = Input;

export const StyledBaseInput: AnyStyledComponent = styled(
  ({ innerRef, ...rest }) => <Input {...rest} />
)`
  & {
    border: solid 1px #e8e8e8;
    border-radius: 3px !important;
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 400;
    font-size: ${props => props.theme.fontSize.m} !important;
    @supports (-webkit-overflow-scrolling: touch) {
      font-size: 16px !important;
    }
    color: ${props => props.theme.colors.text} !important;
    width: 100%;
    margin-bottom: 0.7rem !important;
    &::placeholder {
      font-weight: 400;
      font-family: ${props => props.theme.fonts.primary};
      text-align: left;
      font-size: ${props => props.theme.fontSize.m} !important;
      color: ${props => props.theme.colors.textAlt2} !important;
      white-space: nowrap;
      ${rtlMixin(css`
        text-align: right !important;
      `)}
    }
  }
`;

export const StyledBaseTextArea: AnyStyledComponent = styled(
  ({ innerRef, ...rest }) => <TextArea {...rest} />
)`
  & {
    border: solid 1px #e6e7e8;
    border-radius: 3px !important;
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 400;
    font-size: ${props => props.theme.fontSize.m} !important;
    @supports (-webkit-overflow-scrolling: touch) {
      font-size: 16px !important;
    }
    color: ${props => props.theme.colors.text} !important;
    width: 100%;
    &::placeholder {
      font-weight: 400;
      font-family: ${props => props.theme.fonts.primary};
      text-align: left;
      font-size: ${props => props.theme.fontSize.m} !important;
      color: ${props => props.theme.colors.textAlt2} !important;
      white-space: normal;
      ${rtlMixin(css`
        text-align: right !important;
      `)}
    }
  }
`;

export const StyledInput: AnyStyledComponent = styled(
  ({ shifted, ...rest }) => <StyledBaseInput {...rest} />
)`
  && {
    margin-top: ${({ shifted }) => (shifted ? '40px' : '0')};
    min-height: ${FORM_ITEM_HEIGHT};
    @media only screen and (max-width: 768px) {
      min-height: 50px;
      margin-top: initial;
    }
  }
`;

export const StyledInputWithTooltip: AnyStyledComponent = styled.div`
  position: relative;
  input {
    padding-right: 40px;
    ${rtlMixin(css`
      padding-right: 11px;
      padding-left: 40px;
    `)}
  }
`;

export const StyledTooltipHolder: AnyStyledComponent = styled.span`
  position: absolute;
  right: 5px;
  top: 8px;
  text-align: center;
  width: 32px;
  height: 32px;
  border: 1px solid #e8e8e8;
  color: #222;
  border-radius: 3px;
  line-height: 1.5;
  padding: 5px;
  cursor: pointer;
  ${rtlMixin(css`
    right: initial;
    left: 5px;
  `)}

  &:hover {
    background: #222;
    color: ${props => props.theme.colors.white};
    border: 0;
  }
`;

export const StyledTextArea: AnyStyledComponent = styled(StyledBaseTextArea)`
  && {
    min-height: ${FORM_ITEM_HEIGHT};
    border: solid 1px #e8e8e8;
    @media only screen and (max-width: 768px) {
      min-height: 50px;
    }
  }
`;

export const SearchInputStyles = styled(Input.Search)<{
  disabledSearch: boolean;
}>`
  .ant-input {
    line-height: ${FORM_ITEM_HEIGHT};
    height: ${FORM_ITEM_HEIGHT};

    &::placeholder {
      font-weight: 400;
      font-family: ${props => props.theme.fonts.primary};
      text-align: left;
      font-size: ${props => props.theme.fontSize.m} !important;
      color: ${props => props.theme.colors.textAlt2} !important;
      ${rtlMixin(css`
        text-align: right !important;
      `)}
    }
  }

  .ant-btn.ant-input-search-button.ant-btn-primary {
    height: ${FORM_ITEM_HEIGHT};
    background-color: ${({ disabledSearch }) =>
      disabledSearch ? '#c7c7cc' : '#4a90e2'};
    font-family: ${props => props.theme.fonts.primary};
    font-size: ${props => props.theme.fontSize.m};
    font-weight: bold;
    border: none;
    border-radius: initial;
  }

  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 50px;
  }
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 50px;
  }

  @media only screen and (max-width: 768px) {
    .ant-input-group-addon {
      display: none;
    }
    .ant-input {
      line-height: 50px;
      height: 50px;
    }
  }

  && {
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 400;
    font-size: ${props => props.theme.fontSize.m} !important;
    color: ${props => props.theme.colors.text};
    width: 100%;
  }
`;
