import { Checkbox as AntdCheckbox } from 'antd';
import React from 'react';
import { CheckboxWrapper } from './elements';

const Checkbox = React.forwardRef(({ ...props }: any, ref: any) => {
  const hasError = props.fieldError?.length > 0;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <CheckboxWrapper hasError={hasError}>
      <AntdCheckbox {...props} ref={ref} />
    </CheckboxWrapper>
  );
});

export default Checkbox;
