import styled, { keyframes } from 'styled-components';

const placeholderShimmer = keyframes`
  0% { background-position: 100% 50% }
  100% { background-position: 0 50% }
`;

export const ShimmerPlaceholder = styled.div`
  height: 100px;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: ${placeholderShimmer} 1.4s ease infinite;
`;

export const AnimatedPlaceholder = styled(ShimmerPlaceholder)<{
  height?: string;
  width?: string;
  centered?: boolean;
}>`
  height: ${({ height = '100%' }) => height};
  width: ${({ width = '100%' }) => width};
  ${({ centered }) =>
    centered &&
    `
      margin: 0 auto;
    `}
`;

export const LoadingWrapper = styled.div`
  height: 395px;
  ${AnimatedPlaceholder} {
    margin-bottom: 3rem;
  }
  @media only screen and (max-width: 768px) {
    height: auto;
    padding: 25px 0;
  }
`;
