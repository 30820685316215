import { Button } from '_common/components';
import styled from 'styled-components';
import { isIE } from 'react-device-detect';

export const BackButton = styled(Button)`
  && {
    border-radius: 3px;
    margin-top: 19px;
    font-family: ${props => props.theme.fonts.basic};
    font-size: 23px;
    letter-spacing: 0;
    text-align: center;
    color: #222;
    background-color: ${props => props.theme.colors.defaultBackground};
    border: 1px solid #c7c7cc;

    &:hover,
    &:active,
    &:focus {
      background-color: ${props => props.theme.colors.defaultBackground};
      color: #222222;
      border: 1px solid #949499;
      opacity: 1;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }

    // Ensure we don't crop buttons on IE.
    ${isIE && 'min-height: 60px'}
  }
`;

export const PrimaryText = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: ${props => props.theme.fontSize.xxl};
  font-weight: bold;
  color: ${props => props.theme.colors.textAlt};
`;

export const SecondaryText = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: ${props => props.theme.fontSize.s};
  font-weight: 400;
  line-height: 1.71;
  color: ${props => props.theme.colors.textAlt};
`;

export const IframeErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
