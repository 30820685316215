import styled from 'styled-components';

export const StyledLogo = styled.img`
  object-fit: contain;
  max-width: 200px;
  @media only screen and (max-width: 768px) {
    max-width: 150px;
  }
`;

export const LogoWrapper = styled.div`
  width: 200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    width: 150px;
    justify-content: center;
  }
`;
