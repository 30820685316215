export default {
  index: '/',
  root: '/:company',
  notFound: '/not-found',

  details: '/:company/details',
  payment: '/:company/payment',
  success: '/:company/success/:fingerprint?',
  tracking: '/:company/tracking',
  locate: '/:company/locate',
  ratings: '/:company/ratings',
  printOption: '/:company/print-option',
  returnMethod: '/:company/return-method',
  returnSubmitted: '/:company/return-submitted',
};
