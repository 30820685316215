// eslint-disable-next-line max-classes-per-file
import React, { forwardRef } from 'react';
import Tooltip from '_common/components/Tooltip';
import {
  StyledBaseInput,
  StyledInput,
  StyledTextArea,
  StyledInputWithTooltip,
  StyledTooltipHolder,
} from './elements';
import { InputProps as AntdInputProps } from 'antd/lib/input';
import { TextAreaProps as AntdTextAreaProps } from 'antd/lib/input/TextArea';

interface InputProps extends AntdInputProps {
  getRef?: (ref: React.Ref<React.Component>) => void;
  shifted?: boolean;
}

interface TextAreaProps extends AntdTextAreaProps {
  getRef?: (ref: React.Ref<React.Component>) => void;
}

interface InputWithTooltipProps extends InputProps {
  tooltipText: string | JSX.Element;
}

export const BaseInput = (props: InputProps) => <StyledBaseInput {...props} />;

export class Input extends React.Component<InputProps> {
  inputRef = (ref: React.Ref<React.Component>) => {
    if (this.props.getRef) {
      this.props.getRef(ref);
    }
  };

  render() {
    const { getRef, ...rest } = this.props; // excluded getRef prop. DO NOT DELETE!!!

    return <StyledInput innerRef={this.inputRef} {...rest} />;
  }
}

export const InputWithTooltip = forwardRef(
  (
    { tooltipText, ...otherProps }: InputWithTooltipProps,
    getRef: (ref: React.Ref<React.Component>) => void
  ) => (
    <StyledInputWithTooltip>
      <Input {...otherProps} getRef={getRef} />
      <Tooltip title={tooltipText} placement="topRight">
        <StyledTooltipHolder>?</StyledTooltipHolder>
      </Tooltip>
    </StyledInputWithTooltip>
  )
);

export class TextArea extends React.Component<TextAreaProps> {
  textAreaRef = (ref: React.Ref<React.Component>) => {
    if (this.props.getRef) {
      this.props.getRef(ref);
    }
  };

  render() {
    const { getRef, ...rest } = this.props; // excluded getRef prop. DO NOT DELETE!!!

    return <StyledTextArea innerRef={this.textAreaRef} {...rest} />;
  }
}

export default {
  BaseInput,
  Input,
  InputWithTooltip,
  TextArea,
};
