import { find, get } from 'lodash';
import { isPaymentPageAvailable } from '_common/utils';
import { WhiteLabelConstants } from '_common/whitelabelConfig';
import { PAYMENT_TYPES } from '_common/constants/common';

export default (stores): string => {
  const { companyConfig, returnReasons } = stores.directoryStore;
  const {
    formFields: { returnReason },
  } = stores.detailsPageStore;
  const consumerChargeAccount = get(
    companyConfig,
    `products.${WhiteLabelConstants.PRODUCT_NAME}.customerPayments.consumerChargeAccount`
  );
  const merchantChargeAccount = get(
    companyConfig,
    `products.${WhiteLabelConstants.PRODUCT_NAME}.customerPayments.merchantChargeAccount`
  );
  const paymentType = get(
    companyConfig,
    `products.${WhiteLabelConstants.PRODUCT_NAME}.customerPayments.paymentType`
  );

  /** accountNumber sent in http headers. */
  let accountNumber = merchantChargeAccount;

  const hasReturnReasons = returnReasons?.reasons?.length > 0;

  /**
   * CONSUMER_PAID type selected and ALL Return reason are disabled. In this case we process payment, so need
   * to take consumer account number.
   * */
  const isConsumerPaidAndAllReasonsDisabled =
    paymentType === PAYMENT_TYPES.CONSUMER_PAID &&
    hasReturnReasons &&
    returnReasons.reasons.every(({ enabled }) => !enabled);

  /** CONSUMER_PAID or BOTH_PAID type selected and Return reason required payment. */
  const isConsumerPaidAndSelectedReasonRequiresPayment =
    isPaymentPageAvailable(paymentType) &&
    hasReturnReasons &&
    get(
      find(returnReasons.reasons, { description: returnReason }),
      'paymentRequired'
    );

  if (
    isConsumerPaidAndAllReasonsDisabled ||
    isConsumerPaidAndSelectedReasonRequiresPayment
  ) {
    accountNumber = consumerChargeAccount;
  }

  return accountNumber;
};
