import { action, observable, runInAction, computed } from 'mobx';
import { get } from 'lodash';

import { EAsyncStatus, ICommonStoresActions } from 'types/core';
import { EWL_FEATURES, ICompanyFeaturesConfig, IFeature } from 'types/features';
import featureService from '_common/services/featureService';
import { MERCHANT_ORGANISATION } from '_common/utils';

class FeatureStore {
  private commonStoresActions: ICommonStoresActions;

  @observable
  private companyFeaturesConfig: ICompanyFeaturesConfig | null = null;

  @observable
  public asyncStatus: EAsyncStatus = EAsyncStatus.IDLE;

  public registerCommonActions = (
    commonStoresActions: ICommonStoresActions
  ) => {
    this.commonStoresActions = commonStoresActions;
  };

  @computed
  public get isFeatureConfigProccessed() {
    return (
      this.asyncStatus !== EAsyncStatus.IDLE &&
      this.asyncStatus !== EAsyncStatus.LOADING
    );
  }

  // this fallbacks to old way of feature flags resolve (via SSMs and company configs)
  public resolveFeatureStatus = (featureId: EWL_FEATURES): boolean => {
    // before config is resolved - do not show features (to avoid menu items flickering)
    if (!this.isFeatureConfigProccessed) return false;
    return this.companyFeaturesConfig !== null
      ? get(this.companyFeaturesConfig, `${featureId}.policyData.enabled`)
      : this.commonStoresActions.validateFeatureAccess(featureId);
  };

  // features are stored in BE as policies with signature like `${featureName}_${companyId}`, so we need to process the response
  private processFeaturesConfig = (
    featuresArr: IFeature[]
  ): ICompanyFeaturesConfig => {
    // no features config - resolve via SSM
    if (!featuresArr?.length) return null;
    const appFeatures = Object.values(EWL_FEATURES);
    return appFeatures.reduce((obj, feat) => {
      obj[feat] = featuresArr.find(
        el => el.policyName === `${feat}_${MERCHANT_ORGANISATION}`
      );
      return obj;
    }, {});
  };

  @action
  public getFeaturesForCompany = async () => {
    try {
      this.asyncStatus = EAsyncStatus.LOADING;
      const featureConfig = await featureService.getFeaturesForCompany(
        MERCHANT_ORGANISATION
      );
      const processed = this.processFeaturesConfig(featureConfig);
      runInAction(() => {
        this.companyFeaturesConfig = processed;
        this.asyncStatus = EAsyncStatus.SUCCESS;
      });
    } catch (e) {
      console.error('Features API error', e);
      runInAction(() => {
        this.asyncStatus = EAsyncStatus.FAILED;
      });
    }
  };

  @action
  public resetStore = () => {
    this.asyncStatus = EAsyncStatus.IDLE;
    this.companyFeaturesConfig = null;
  };
}

export default FeatureStore;
