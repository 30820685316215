import styled, { css, keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const getCssWithProps = ({
  color = '#01adee',
  fontSize = 24,
  arcSize = 64,
  arcBorderSize = 4,
}) => css`
  width: ${arcSize}px;
  height: ${arcSize}px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${arcSize - 13}px;
    height: ${arcSize - 13}px;
    margin: 4px;
    border: ${arcBorderSize}px solid ${color};
    border-radius: 50%;
    top: -20px;
    left: 0;
    animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${color} transparent transparent transparent;
  }
  span {
    position: absolute;
    bottom: -60px;
    width: 380px;

    font-family: ${props => props.theme.fonts.basic};
    font-size: ${fontSize}px;
    font-weight: bold;
    line-height: 1.75;
    text-align: center;
    color: #222;

    @media only screen and (max-width: 768px) {
      font-size: ${fontSize - 4}px;
    }
  }
`;

export const Wrapper = styled.div<{
  color?: string;
  fontSize?: number;
  arcSize?: number;
  arcBorderSize?: number;
}>`
  position: relative;

  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  ${getCssWithProps}

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
