import { apiClient } from '_common/api/clients/clients';
import { CancelToken } from 'axios';
import { IChannelStoreModel } from 'types/store';
import { getGeoUrl } from '_common/utils/storesUtils';
import { ROOT_ORG_ID } from '_common/constants/common';

const getGeoQueryStores = async (
  config,
  cancelToken: CancelToken
): Promise<IChannelStoreModel[]> => {
  const response = await apiClient.get(getGeoUrl(config), {
    cancelToken,
  });
  return response.data.resources;
};

type ReverseGeocodeConfig = {
  lat: number;
  lng: number;
};

const getReverseGeocode = async ({ lat, lng }: ReverseGeocodeConfig) => {
  const geocoder = new google.maps.Geocoder();
  const response = await geocoder.geocode({ location: { lat, lng } });
  return response.results;
};

const getCoordinates = async (request: google.maps.GeocoderRequest) => {
  const geocoder = new google.maps.Geocoder();
  const response = await geocoder.geocode(request);
  return response.results;
};

const getNearestLocations = async ({ lat, lng }, companyId) => {
  const response = await apiClient.get(
    `/v1/returns-portal-service/${ROOT_ORG_ID}/company/${companyId}/latitude/${lat}/longitude/${lng}`
  );
  return response.data;
};

export default {
  getGeoQueryStores,
  getReverseGeocode,
  getCoordinates,
  getNearestLocations,
};
