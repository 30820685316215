import React from 'react';
import Icon from '@ant-design/icons';
import { IconProps } from '@ant-design/compatible/lib/icon';

const CloseIcon = (props: IconProps) => {
  const Svg = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );

  return <Icon component={Svg} {...props} />;
};

export default CloseIcon;
