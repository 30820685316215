import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import UIStore from '_common/stores/uiStore';
import BaseModal from '_common/components/BaseModal';
import { ModalContainer } from './elements';

const FORM_MODAL_WIDTH = {
  LARGE: 1045,
  MEDIUM: 780,
  SMALL: 400,
};

type Props = {
  uiStore: UIStore;
};

@observer
class Modal extends Component<Props> {
  render() {
    const {
      uiStore: { modal, isModalShown, closeModal, submitModal, modalError },
    } = this.props;
    if (!modal || !modal.component || !isModalShown) {
      return null;
    }
    const {
      title,
      bodyText,
      component,
      width = FORM_MODAL_WIDTH.SMALL,
      hasExtraCloseBtn = false,
      props,
    } = modal;
    const ModalContent = component;

    return (
      <BaseModal toggle={closeModal} hasCloseBtn={hasExtraCloseBtn}>
        <ModalContainer width={width}>
          {title && <h2>{title}</h2>}
          <ModalContent
            {...props}
            closeModal={closeModal}
            onSubmit={submitModal}
            bodyText={bodyText}
            modalError={modalError}
          />
        </ModalContainer>
      </BaseModal>
    );
  }
}

export default compose(inject('uiStore'))(Modal);
