import React from 'react';
import {
  ItemHolder,
  ItemContentWrapper,
  DetailsBlock,
  Image,
  Description,
  DetailsRow,
  Title,
  Info,
  InfoButtons,
  InfoContent,
  StyledButton,
  DetailsWrapper,
  FitContentImage,
  IeDisabledImage,
  ReturnItemError,
} from './elements';
import { IReason } from 'types/company';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { compose } from 'recompose';
import { CheckmarkIcon } from '_common/icons';
import { truncate, getMerchantFromUrl } from '_common/utils';
import { includes } from 'lodash';
import { isIE } from 'react-device-detect';

export interface ItemContentProps {
  description: string;
  priceCurrency: string;
  price: number;
  imageUrl: string;
  size: string;
  colour?: string;
  reason?: IReason;
  comment?: string;
  productError?: string;
  t: TFunction;
  isComplete?: boolean;
  fitContent?: boolean;
  onCancel?: () => void;
  onEdit?: () => void;
  hasEditButton?: boolean;
  isDisabled: boolean;
}

const Content = ({
  description,
  imageUrl,
  size,
  colour,
  price,
  priceCurrency,
  reason,
  comment,
  isComplete,
  onCancel,
  onEdit,
  hasEditButton,
  t,
  fitContent,
  isDisabled,
  productError,
}: ItemContentProps) => {
  const renderWithLabel = (value?: string | number, label?: string) =>
    typeof value !== 'undefined' ? (
      <div>{label ? `${label} ${value}` : value}</div>
    ) : null;
  // THIS IS A WORKAROUND REQUESTED IN TECH-11851 (PLANET_BLUE) merchant
  const renderPrice = () =>
    includes(['PLANET_BLUE', 'PLANETBLUE'], getMerchantFromUrl()) ? (
      <div>&nbsp;</div>
    ) : (
      renderWithLabel(price, priceCurrency)
    );

  const renderSVGForIE = () => (
    <IeDisabledImage>
      <defs>
        <filter xmlns="http://www.w3.org/2000/svg" id="desaturate">
          <feColorMatrix type="saturate" values="0" />
        </filter>
      </defs>
      <image
        xlinkHref={imageUrl}
        preserveAspectRatio="xMidYMid slice"
        width="83"
        height="83"
        filter="url(#desaturate)"
      />
    </IeDisabledImage>
  );

  return (
    <ItemHolder fitContent={fitContent}>
      {isComplete && <CheckmarkIcon />}
      <ItemContentWrapper>
        {fitContent ? (
          isIE && isDisabled ? (
            renderSVGForIE()
          ) : (
            <FitContentImage
              imageUrl={imageUrl}
              fitContent={fitContent}
              isDisabled={isDisabled}
            />
          )
        ) : (
          <Image
            imageUrl={imageUrl}
            fitContent={fitContent}
            isDisabled={isDisabled}
          />
        )}
        <DetailsWrapper isDisabled={isDisabled}>
          <DetailsBlock fitContent={fitContent}>
            <Description>
              <Title>{description}</Title>
            </Description>
            <DetailsRow>
              {renderPrice()}
              {renderWithLabel(size, t('size'))}
              {renderWithLabel(colour, t('success:colour'))}
            </DetailsRow>
          </DetailsBlock>
          {productError && <ReturnItemError>{productError}</ReturnItemError>}
        </DetailsWrapper>
      </ItemContentWrapper>
      <Info>
        <InfoContent>
          {reason && (
            <>
              <b>{t('returnReasonLabel')}:</b>
              <p>{reason.description}</p>
            </>
          )}
          {comment && (
            <>
              <b>{t('reasonComment2Label')}:</b>
              <p>{truncate(comment, 100)}</p>
            </>
          )}
        </InfoContent>
        {isComplete && (
          <InfoButtons>
            {hasEditButton && (
              <StyledButton size="small" onClick={onEdit}>
                {t('constants:editBtn')}
              </StyledButton>
            )}
            <StyledButton size="small" onClick={onCancel}>
              {t('constants:cancelBtn')}
            </StyledButton>
          </InfoButtons>
        )}
      </Info>
    </ItemHolder>
  );
};

export default compose(withTranslation(['details', 'constants']))(Content);
