import { action, computed, observable, runInAction } from 'mobx';
import { get, find } from 'lodash';
import i18nService from '_common/services/i18nService';
import {
  CURRENCY_SYMBOLS,
  API_PURCHASE_DATE_FORMAT,
} from '_common/constants/common';
import OrdersService from '_common/services/ordersService';
import moment from 'moment';
import {
  IOrder,
  IOrderProductModel,
  IUserInfo,
  IOrderServerResponse,
  IOrderProduct,
} from 'types/order';
import { EAsyncStatus, ICommonStoresActions } from 'types/core';
import { IStoreWithCommonActions } from 'types/internal';
import { WhiteLabelConstants } from '_common/whitelabelConfig';

const ORDERTYPE_STATUS = {
  RETURNED: 'INTERNAL',
  NOT_RETURNED: 'EXTERNAL',
};

class OrderStore implements IStoreWithCommonActions {
  @observable
  orderData: IOrder | null = null;

  @observable
  orderNumber: string | null = null;

  @observable
  asyncStatus: EAsyncStatus = EAsyncStatus.IDLE;

  @observable
  returnedProducts: Map<string, boolean> = new Map();

  commonStoresActions: ICommonStoresActions;

  registerCommonActions = (commonStoresActions: ICommonStoresActions) => {
    this.commonStoresActions = commonStoresActions;
  };

  @computed
  get isIntegratedFlow(): boolean {
    return Boolean(this.orderData);
  }

  @computed
  get products(): IOrderProductModel[] {
    if (!this.orderData) return [];
    const { orderLines } = this.orderData;
    if (!orderLines || orderLines.length <= 0) {
      return [];
    }

    return orderLines.map(
      ({
        product,
        priceCurrency = WhiteLabelConstants.DEFAULT_CURRENCY_NAME,
      }) => ({
        ...product,
        priceCurrency: get(CURRENCY_SYMBOLS, priceCurrency),
      })
    );
  }

  @computed
  get userInfo(): IUserInfo | object {
    if (!this.orderData) return {};
    const firstName = get(this.orderData, 'customer.name.firstName', '');
    const lastName = get(this.orderData, 'customer.name.lastName', '');
    const orderDate = Array.isArray(this.orderData.eventHistory?.slice())
      ? this.orderData.eventHistory[0].dateTime
      : null;
    const fullName =
      firstName || lastName ? `${firstName} ${lastName}`.trim() : null;
    const addressFields = get(
      this.orderData,
      'externalOrderData.deliveryAddress',
      {}
    );
    return {
      phoneNumber: get(this.orderData, 'customer.mobileNumber', null),
      name: fullName,
      orderDate,
      ...addressFields,
    };
  }

  /**
   * Take the order response and add a productId to the product if it doesn't
   * have one in the first place
   *
   * @param response
   */
  @action
  addUIDtoOrderLinesProductsIfBlank = (response: IOrderServerResponse) => {
    response.resources.forEach((order: IOrder) => {
      if (order.orderLines) {
        order.orderLines.forEach((ol, index) => {
          const isExternalOrder =
            order.orderType === ORDERTYPE_STATUS.NOT_RETURNED;
          const { productId: originalProductId } = ol.product;

          if (isExternalOrder) {
            const newProductId = originalProductId
              ? `pi#${originalProductId}`
              : '';
            ol.product.productId = `${newProductId}oi#${order.orderId}#inx${index}`;
          } else {
            ol.product.productId = get(
              ol.product,
              'productId',
              this.createUIDFromProduct(ol.product, index)
            );
          }
        });
      }
    });
  };

  /**
   * Takes a product and creates a unique repeatable product ID based on data
   * from the product itself. Takes the index in case we have multiple
   * of the exact same product in an order lines object
   *
   * @param product
   * @param index
   */
  createUIDFromProduct = (product: IOrderProduct, index: number): string =>
    // eslint-disable-next-line
    `${get(product, 'sku', 'sku')}-${get(product, 'name', 'name')}-${get(
      product,
      'sku',
      'sku'
    )}-${get(product, 'name', 'name')}-${get(product, 'size', 'size')}-${get(
      product,
      'colour',
      'colour'
    )}-${index}`;

  @action
  checkOrderDateIsValid = (orderPurchaseDate: string): boolean => {
    const purchaseDateValidationDisabled = !get(
      this.commonStoresActions.getReturnFormFields(),
      'purchaseDate'
    );
    const companyWarrantyPeriod = this.commonStoresActions.getPurchaseWarrantyPeriod();
    /**
     * check only if there's date, warrantyDays provided and
     * product integrated company (ensure only USPS change)
     */

    if (
      purchaseDateValidationDisabled ||
      !orderPurchaseDate ||
      typeof companyWarrantyPeriod !== 'number' ||
      !this.commonStoresActions.getProductJourneyType().INTEGRATED
    )
      return true;
    if (
      moment(orderPurchaseDate, API_PURCHASE_DATE_FORMAT) <
      moment().subtract(companyWarrantyPeriod + 1, 'days')
    ) {
      runInAction(() => {
        this.asyncStatus = EAsyncStatus.FAILED;
        this.orderData = null;
        this.orderNumber = null;
      });
      return false;
    }
    return true;
  };

  /**
   * Get the returned status of a product by checking the map
   * of returned products against a product ID of the same product
   *
   * @param product
   */
  getReturnedStatusForProduct = (product: IOrderProductModel): boolean =>
    this.returnedProducts.get(product.productId);

  @action
  clearReturnedProducts = (): void => {
    this.returnedProducts.clear();
  };

  @action
  getNotReturnedOrder = (response: IOrderServerResponse): IOrder => {
    const notReturnedOrders: IOrder[] = [];

    response.resources.forEach((order: IOrder) => {
      if (order.orderType === ORDERTYPE_STATUS.RETURNED) {
        order.orderLines.forEach(ol => {
          /**
           * Set the returned item to the product ID as we will always
           * have one, created, or otherwise
           */
          this.returnedProducts.set(ol.product.productId, true);
        });
      }
      if (order.orderType === ORDERTYPE_STATUS.NOT_RETURNED) {
        notReturnedOrders.push(order);
      }
    });
    return notReturnedOrders[0];
  };

  @action
  getOrderById = async (orderNumber, company, email) => {
    if (!orderNumber) {
      this.orderData = null;
      return Promise.reject(Error(i18nService.t('stores:orderNumberError')));
    }

    /** For case success page -> details redirect after flow check. */
    if (orderNumber === this.orderNumber) {
      return Promise.resolve();
    }

    try {
      this.asyncStatus = EAsyncStatus.LOADING;
      this.orderNumber = orderNumber;
      const response = await OrdersService.getOrdersByExternalId(
        orderNumber,
        company,
        email
      );

      // Ensure that we have a product ID by creating one if we don't
      this.addUIDtoOrderLinesProductsIfBlank(response);
      const orderData = this.getNotReturnedOrder(response);

      runInAction(() => {
        this.orderData = orderData;
        this.asyncStatus = EAsyncStatus.SUCCESS;
      });

      return Promise.resolve();
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.asyncStatus = EAsyncStatus.FAILED;
        this.orderData = null;
        this.orderNumber = null;
      });
      return Promise.reject(Error(i18nService.t('stores:orderNotFoundError')));
    }
  };

  @action
  updateIntegratedComment = (value: string, productId: string) => {
    const newOrderData = { ...this.orderData };
    const selectedOrderLine = find(
      newOrderData.orderLines,
      orderLine => orderLine.product.productId === productId
    );
    selectedOrderLine.product.reasonComments = value;

    this.orderData = newOrderData;
  };

  @action
  resetStore = () => {
    this.orderData = null;
    this.orderNumber = null;
    this.clearReturnedProducts();
  };
}

export default OrderStore;
