import { apiClient } from '_common/api/clients/clients';
import {
  RETURNS_PORTAL_SERVICE_POSTFIX,
  ROOT_ORG_ID,
} from '_common/constants/common';

const getTrackingInfo = async (trackingId, companyId): Promise<any> => {
  const response = await apiClient.get(
    `v1/returns-portal-service${RETURNS_PORTAL_SERVICE_POSTFIX}/${ROOT_ORG_ID}/tracking-status/${trackingId}?companyId=${companyId}`
  );
  return response.data;
};

export default {
  getTrackingInfo,
};
