import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { Beforeunload } from 'react-beforeunload';
import { AustraliaPost, Card, OutlineButton } from '_common/components';
import commonStoresActions from '_common/actions';
import { ConfirmButton } from 'pages/start/elements';
import { AsyncStatus } from '_common/constants/common';
import {
  BackButton,
  IframeErrorContainer,
  PrimaryText,
  SecondaryText,
} from 'pages/payment/elements/elements';
import { createFormFromModel, isPaymentPageAvailable } from '_common/utils';
import { TransProps, withTranslation } from 'react-i18next';
import ErrorModal from './ErrorModal';
import amplitude from '_common/utils/amplitude';
import { IRouteNavigator, IRouterMatch, TSPResult } from 'types/core';
import { IDetailsStore } from 'types/internal';
import {
  CardActionButtons,
  StyledCardTitle,
} from '_common/components/Card/elements';
import OrderStore from '_common/stores/orderStore';
import DirectoryStore from '_common/stores/directoryStore';
import ThemeStore from '_common/stores/themeStore';
import PaymentPageStore from 'pages/payment/stores/paymentPageStore';
import StartPageStore from 'pages/start/stores/startPageStore';

type Props = RouteComponentProps<IRouterMatch> &
  TransProps &
  FormComponentProps & {
    paymentPageStore: PaymentPageStore;
    directoryStore: DirectoryStore;
    detailsPageStore: IDetailsStore;
    startPageStore: StartPageStore;
    themeStore: ThemeStore;
    orderStore: OrderStore;
    setCurrentGoBackMethod: (cb: Function) => void;
    routeNavigator: IRouteNavigator;
  };

type State = {
  shipmentCreationStatus: string;
  activePaymentMethod: string;
  paymentStatus: string;
  onLoadError: boolean;
  refreshIframe: boolean;
  isGoForward: boolean;
  isErrorModalOpen: boolean;
  errorType: number;
};

@observer
class PaymentPage extends Component<Props, State> {
  static PAGE_NAME = 'Payment page';

  state = {
    activePaymentMethod: 'card',
    shipmentCreationStatus: AsyncStatus.IDLE,
    paymentStatus: AsyncStatus.IDLE,
    onLoadError: false,
    refreshIframe: false,
    isGoForward: false,
    isErrorModalOpen: false,
    errorType: null,
  };

  componentDidMount() {
    this.props.setCurrentGoBackMethod(this.goBack);

    amplitude.logEventWithOrganisationAndUrl('page_open', {
      page_name: PaymentPage.PAGE_NAME,
    });
  }

  goBack = ({ withConfirm = false }: { withConfirm?: boolean } = {}): any => {
    const message = this.props.t('backMsg');

    const confirmedAction =
      process.env.NODE_ENV === 'production' && withConfirm
        ? window.confirm(message)
        : true;
    confirmedAction && this.props.routeNavigator.previous();
  };

  goToNextPage = () => {
    this.props.routeNavigator.next();
  };

  handleSuccessPayment = () => {
    this.setState({ paymentStatus: AsyncStatus.SUCCESS });
    this.goToNextPage();
  };

  handleErrorPayment = () => {
    this.setState({ paymentStatus: AsyncStatus.FAILED });
  };

  renderSecurePayButtons = ({ confirm }) => {
    const { shipmentCreationStatus, paymentStatus } = this.state;
    const onClick = () => {
      this.setState({ paymentStatus: AsyncStatus.LOADING });
      confirm();
    };

    return (
      <>
        <ConfirmButton
          onClick={onClick}
          disabled={
            [shipmentCreationStatus, paymentStatus].indexOf(
              AsyncStatus.LOADING
            ) !== -1
          }
        >
          {this.props.t('confirmBtn')}
        </ConfirmButton>
        <BackButton onClick={() => this.goBack()}>
          {this.props.t('backBtn')}
        </BackButton>
      </>
    );
  };

  renderErrorPage = () => {
    const { t } = this.props;
    return (
      <IframeErrorContainer>
        <div>
          <PrimaryText>{t('error')}</PrimaryText>
          <SecondaryText>{t('errorInstruction')}</SecondaryText>
        </div>
        <div>
          <ConfirmButton onClick={this.refreshIframe}>
            {t('errorBtn')}
          </ConfirmButton>
          <BackButton onClick={() => this.goBack()}>
            {t('errorBackBtn')}
          </BackButton>
        </div>
      </IframeErrorContainer>
    );
  };

  handleToggleErrorModal = () => {
    if (this.state.isErrorModalOpen === true) {
      this.setState({ isErrorModalOpen: false });
    } else {
      this.setState({ isErrorModalOpen: true });
    }
  };

  onLoadError = () => {
    this.setState({
      onLoadError: true,
    });
  };

  refreshIframe = () => {
    this.setState(prevState => ({ refreshIframe: !prevState.refreshIframe }));
  };

  renderCommonPaymentsBlock = () => {
    const {
      activePaymentMethod,
      shipmentCreationStatus,
      isErrorModalOpen,
      errorType,
    } = this.state;
    const { t } = this.props;
    return (
      <>
        {activePaymentMethod === 'card' && this.renderSecurePayFrame()}
        <CardActionButtons>
          {activePaymentMethod !== 'card' && (
            <ConfirmButton
              onClick={this.goToNextPage}
              disabled={shipmentCreationStatus === AsyncStatus.LOADING}
            >
              {t('confirmBtn')}
            </ConfirmButton>
          )}
          <OutlineButton onClick={() => this.goBack()}>
            {t('constants:backBtn')}
          </OutlineButton>
        </CardActionButtons>
        {isErrorModalOpen && (
          <ErrorModal
            errorType={errorType}
            toggle={this.handleToggleErrorModal}
            t={t}
          />
        )}
      </>
    );
  };

  getCompanyId() {
    const {
      match: {
        params: { company },
      },
    } = this.props;
    return company;
  }

  getForwardLink() {
    return `/${this.getCompanyId()}/success`;
  }

  onPaymentDone = (details: TSPResult) => {
    commonStoresActions.setConsumerPaymentWasSuccessful(details);
    amplitude.logEventWithOrganisationAndUrl('payment happens');

    this.setState({ isGoForward: true });
  };

  /**
   * On Payment error, update error state and then toggle the error modal
   */
  onPaymentError = (error: number) => {
    console.error(`Secure Pay iframe error code: ${error}`);

    // Wait for state set before toggle
    this.setState({ errorType: error }, () => {
      if (this.state.isErrorModalOpen !== true) {
        this.handleToggleErrorModal();
      }
      this.refreshIframe();
    });
  };

  renderSecurePayFrame = () => {
    const {
      directoryStore: {
        paymentConfig: { paymentType, price },
      },
      startPageStore: {
        formFields: { orderNumber, email },
      },
    } = this.props;

    if (!isPaymentPageAvailable(paymentType)) {
      return null;
    }

    const amount = Math.round(Number(price) * 100);

    return (
      <AustraliaPost.SecureFrame
        amount={amount}
        referenceId={orderNumber}
        email={email}
        onPaymentDone={this.onPaymentDone}
        onPaymentCancel={() => this.goBack({ withConfirm: false })}
        onPaymentError={this.onPaymentError}
        onLoadError={this.onLoadError}
      />
    );
  };

  render() {
    const {
      directoryStore: {
        paymentConfig: { price },
      },
      t,
    } = this.props;

    const { onLoadError, isGoForward } = this.state;
    const isAutoPageTransition = isGoForward;

    return (
      <Beforeunload
        onBeforeunload={() => {
          if (isAutoPageTransition) return;
          return t('closeWarning');
        }}
      >
        {isGoForward && <Redirect push to={this.getForwardLink()} />}
        <Card>
          {price ? (
            <>
              <StyledCardTitle>
                {t('title')} : ${price}
              </StyledCardTitle>
              {this.renderCommonPaymentsBlock()}
            </>
          ) : onLoadError ? (
            this.renderErrorPage()
          ) : (
            <StyledCardTitle>{t('emptyPrice')}</StyledCardTitle>
          )}
        </Card>
      </Beforeunload>
    );
  }
}

export default compose(
  inject(
    'paymentPageStore',
    'directoryStore',
    'detailsPageStore',
    'startPageStore',
    'themeStore',
    'orderStore'
  ),
  withTranslation('payment'),
  Form.create<Props>({
    mapPropsToFields(props) {
      return createFormFromModel(props.paymentPageStore.formFields, Form);
    },
    onValuesChange(props, fields) {
      const keys = Object.keys(fields);
      if (!keys.length) {
        return;
      }

      const { name, value } = fields[keys[0]];

      props.paymentPageStore.setFormField(name, value);
    },
  })
)(PaymentPage);
