// this has to be in sync with BE features naming
export enum EWL_FEATURES {
  NON_RETURNABLE_ITEMS = 'non_returnable_items',
  RETURN_APPROVALS = 'return_approvals',
  RETURN_WINDOWS = 'return_windows',
}

export interface IFeature {
  policyName: string;
  policyId: string;
  companyId: string;
  policyData: {
    enabledBy: string;
    dateTimeChanged: string;
    enabled: boolean;
  };
  policyType: string;
}

export interface ICompanyFeaturesConfig
  extends Partial<Record<EWL_FEATURES, IFeature>> {}

export interface IShipmentConfigFeaturesData
  extends Partial<Record<EWL_FEATURES, boolean>> {}
