type CombinedTextItem = {
  type: 'key' | 'var';
  val: string;
  key?: string;
};
type TextItem = string | CombinedTextItem[];
export type TextNamespaceItem = { [key: string]: TextItem };
type CustomTextMap = { [key: string]: TextNamespaceItem };

export const CUSTOM_TEXT_NAMESPACE = 'substitute';

export const CUSTOM_TEXT_MAP: CustomTextMap = {
  wl: {
    titleText: `${CUSTOM_TEXT_NAMESPACE}_1_heading1`,
    subTitleText: `${CUSTOM_TEXT_NAMESPACE}_1_heading2`,
  },
  start: {
    orderLabel: `${CUSTOM_TEXT_NAMESPACE}_1_orderID`,
    orderPlaceholder: `${CUSTOM_TEXT_NAMESPACE}_1_orderIDGhostText`,
    tooltipText: `${CUSTOM_TEXT_NAMESPACE}_1_orderIDTooltip`,
  },
  details: {
    integratedTitle: `${CUSTOM_TEXT_NAMESPACE}_2_heading`,
    returnReasonLabel: `${CUSTOM_TEXT_NAMESPACE}_3_reasonForReturn`,
    reasonCommentLabel: `${CUSTOM_TEXT_NAMESPACE}_3_returnComments`,
    reasonComment2Label: `${CUSTOM_TEXT_NAMESPACE}_3_returnComments`,
    reasonCommentsPlaceholder: `${CUSTOM_TEXT_NAMESPACE}_3_returnCommentsGhostText`,
    reasonComments2Placeholder: `${CUSTOM_TEXT_NAMESPACE}_3_returnCommentsGhostText`,
  },
  printOption: {
    title: `${CUSTOM_TEXT_NAMESPACE}_4_heading`,
  },
  success: {
    packageText: `${CUSTOM_TEXT_NAMESPACE}_5_whatToDoNext`,
  },
  returnSubmitted: {
    title: `${CUSTOM_TEXT_NAMESPACE}_6_title`,
    text: [
      { type: 'key', val: `${CUSTOM_TEXT_NAMESPACE}_6_returnRequestHeading` },
      { type: 'var', val: '<1>#{{caseNumber}}</1>', key: 'caseNumber' },
      {
        type: 'key',
        val: `${CUSTOM_TEXT_NAMESPACE}_6_confirmationEmailHeading`,
      },
    ],
    btnText: `${CUSTOM_TEXT_NAMESPACE}_6_primaryButton`,
  },
};
