import { WhiteLabelUtils } from '_common/whitelabelConfig';
import { IUserInfo } from 'types/order';
import { IDetailsFormFields } from 'types/formFields';
import { EPrintOptions } from 'types/core';

export const getUserAddress = (
  isIntegratedFlow: boolean,
  userInfo: IUserInfo | object,
  formFields: IDetailsFormFields
): string => {
  const { addressLine1, addressLine2, city, postcode, state } = isIntegratedFlow
    ? WhiteLabelUtils.convertAddressForMap(userInfo)
    : formFields;

  return [addressLine1, addressLine2, city, state, postcode]
    .filter(v => v)
    .join(', ');
};

export const generateLocateLinks = ({
  nearestStores,
  address,
  selectedOption,
  lat,
  lng,
}) => {
  const nearestStoresLinks = nearestStores[selectedOption].map(
    nearestStore =>
      `locate?storeId=${
        nearestStore.store.storeId
      }&latitude=${lat}&longitude=${lng}&address=${address.replace(/,/g, '')}`
  );
  let findLocationLink = `locate?address=${address.replace(/,/g, '')}`;

  if (selectedOption === EPrintOptions.PAPERLESS) {
    findLocationLink += '&printInStore=true';
    nearestStoresLinks.map(
      nearestStoreLink => `${nearestStoreLink}&printInStore=true`
    );
  }
  return {
    nearestStoresLinks,
    findLocationLink,
  };
};
