import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #f5f5f6;
`;

export const Title = styled.h1`
  font-size: 4rem;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  color: #222222;
  text-align: center;
  margin-bottom: 24px;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 700;
  padding: 0 2rem;
`;

export const SubTitle = styled.p`
  font-size: ${props => props.theme.fontSize.xxl};
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  text-align: center;
  color: #222222;
  font-family: ${props => props.theme.fonts.primary};
  padding: 0 2rem;
`;

export const Box = styled.div`
  max-width: 960px;
  border-radius: 6px;
  box-shadow: 0 18px 38px 0 rgba(0, 0, 0, 0.06), 0 0 8px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
`;

export const FormContainer = styled.div`
  height: 440px;
`;

export const StarsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 2rem;
  padding: 2rem 2rem 0;
`;

export const Star = styled.img`
  margin-left: 6px;
  width: 51px;
  height: 49px;
`;
