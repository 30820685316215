import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { Button, Skeleton } from 'antd';
import { reaction } from 'mobx';
import { isIE } from 'react-device-detect';
import { MarketingImage } from 'pages/tracking/elements';
import { Card, LoaderArc } from '_common/components';
import amplitude from '_common/utils/amplitude';
import { Trans, TransProps, withTranslation } from 'react-i18next';
import {
  AddressWrapper,
  Distance,
  DownloadLabelButton,
  FindLocationLink,
  HintText,
  IntegratedOrderDetails,
  IntegratedProductsHeader,
  LocationsWrapper,
  NearestLocationText,
  ReturnContent,
  ReturnStep,
  ReturnStepsWrapper,
  StepNumber,
  StepTitle,
  StepWrapper,
  SubTitle,
  SuccessIcon,
  SuccessInfo,
  Title,
  StoreName,
  LoaderArcWrapper,
} from './elements';
import { ThemeStore } from 'stores';
import { IDetailsStore, IWhitelabelProxyStore } from 'types/internal';
import commonStoresActions from '_common/actions';
import {
  WhiteLabelConstants,
  withWhitelabelProps,
} from '_common/whitelabelConfig';
import {
  EAsyncStatus,
  EPrintOptions,
  IRouteNavigator,
  IRouterMatch,
  EReturnType,
  ServerErrorType,
} from 'types/core';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import successStepIcon from 'assets/images/success-step-icon.svg';
import notificationService from '_common/services/notificationService';
import ReturnFailed from './components/ReturnFailed';
import { CardSize } from '_common/components/Card/Card';
// import { IChannelStoreModel } from 'types/store'; Support should be added in TECH-11216
import {
  generateLocateLinks,
  getUserAddress,
} from '_common/utils/addressUtils';
import IntegratedProduct from './components/IntegratedProduct';
import { localStore as storage, USER_LOCALE } from 'storage';
import { DEFAULT_LOCALE } from '_common/constants/common';
import PrintOptionStore from 'pages/printOption/stores/printOptionPageStore';
import ReturnMethodStore from 'pages/returnMethod/stores/returnMethodPageStore';
import OrderStore from '_common/stores/orderStore';
import DirectoryStore from '_common/stores/directoryStore';
import StartPageStore from 'pages/start/stores/startPageStore';

type Props = RouteComponentProps<IRouterMatch> &
  FormComponentProps &
  TransProps & {
    directoryStore: DirectoryStore;
    whitelabelProxyStore: IWhitelabelProxyStore;
    themeStore: ThemeStore;
    orderStore: OrderStore;
    detailsPageStore: IDetailsStore;
    whiteLabeled: any;
    setCurrentGoBackMethod: (cb?: Function) => void;
    routeNavigator: IRouteNavigator;
    startPageStore: StartPageStore;
    printOptionStore: PrintOptionStore;
    returnMethodStore: ReturnMethodStore;
  };

type State = {
  labelLoadingStatus: EAsyncStatus;
  isUserAwaitingNewTab: boolean;
  errorMessage: string | null;
  errorType: ServerErrorType | null;
};

@observer
class SuccessPage extends Component<Props, State> {
  static PAGE_NAME = 'Success page';

  _isMount = true;

  intergratedFlowView: boolean;

  state = {
    labelLoadingStatus: EAsyncStatus.LOADING,
    isUserAwaitingNewTab: false,
    errorMessage: null,
    errorType: null,
  };

  successPageSteps = {
    [EPrintOptions.PAPERLESS]: [
      'PACKAGE_RETURN',
      'FIND_LOCATION',
      'RETURN_BAR_CODE',
    ],
    [EPrintOptions.PRINT_OWN]: [
      'PRINT_LABEL',
      'PACKAGE_RETURN',
      'FIND_LOCATION',
    ],
    [EReturnType.DROP_OFF]: [
      'PACKAGE_RETURN',
      'OTHER_PLACES',
      'RETURN_2D_CODE',
    ],
    [EReturnType.HOME_PICKUP]: ['PACKAGE_RETURN', 'COURIER'],
  };

  constructor(props: Props) {
    super(props);

    this.intergratedFlowView = this.props.orderStore.isIntegratedFlow;
    /** case when config loaded before (no redirects) */
    if (props.directoryStore.status === EAsyncStatus.SUCCESS) {
      this.processPreadvice();
    } else {
      reaction(
        () => props.directoryStore.paymentConfig,
        (paymentConfig, reactionFunc) => {
          reactionFunc.dispose();
          this.processPreadvice();
        }
      );
    }
  }

  componentDidMount() {
    const {
      whiteLabeled: { countryCode, isPrinterOptionDisabled },
      detailsPageStore: { formFields },
      orderStore: { isIntegratedFlow, userInfo },
      printOptionStore: { getClosestStores, selectedOption, nearestStores },
      setCurrentGoBackMethod,
    } = this.props;
    setCurrentGoBackMethod();

    amplitude.logEventWithOrganisationAndUrl('page_open', {
      page_name: SuccessPage.PAGE_NAME,
    });
    // get closest stores if none present
    !nearestStores[selectedOption] &&
      getClosestStores({
        address: getUserAddress(isIntegratedFlow, userInfo, formFields),
        countryCode,
        isPrinterOptionDisabled,
      });
  }

  componentWillUnmount() {
    this._isMount = false;
    if (this.props.whitelabelProxyStore.orderWasSubmitted) {
      commonStoresActions.resetStoresWithUserData();
    }
    // on success page changing the language doesn't change it in store
    commonStoresActions.setLanguage(storage.get(USER_LOCALE) || DEFAULT_LOCALE);
  }

  processPreadvice = async () => {
    const {
      match: {
        params: { company },
      },
      whitelabelProxyStore: { orderWasSubmitted },
      orderStore: { asyncStatus: orderStoreAsyncStatus },
      detailsPageStore: {
        formFields: { returnReason },
      },
      routeNavigator,
    } = this.props;

    if (this.state.labelLoadingStatus !== EAsyncStatus.LOADING) {
      this.setState({
        labelLoadingStatus: EAsyncStatus.LOADING,
      });
    }

    if (orderWasSubmitted) {
      return;
    }

    try {
      /** Need fetch this order data before going, to check which flow is running. */
      if (orderStoreAsyncStatus === EAsyncStatus.IDLE) {
        const productJourney = commonStoresActions.getProductJourneyType();
        try {
          /** it is implicit - need to force user to select products to return. Old one should be used already. */
          if (
            WhiteLabelConstants.IS_INTEGRATED_JOURNEY_AVAILABLE &&
            (productJourney.INTEGRATED || productJourney.NOT_SUPPORTED)
          ) {
            await commonStoresActions.getOrderById(company);
            return routeNavigator.previous();
          }
        } catch (e) {
          // if error - just means non-integrated flow, store handle it
        }
      }

      if (!this._isMount) {
        return;
      }

      // notificationService.showInfoMessage(this.props.t('notificationLoading'), {
      //   autoClose: false,
      //   closeOnClick: false,
      // });

      if (this.state.errorType === ServerErrorType.VALIDATION_ERROR) {
        return routeNavigator.previous();
      }

      await commonStoresActions.createShipment(company);
      /** If user already pressed Download button - need to open new tab */
      if (this.state.isUserAwaitingNewTab) {
        this.openNewTab(this.props.whitelabelProxyStore.pdfLabelURL);
      }

      this.setState({
        isUserAwaitingNewTab: false,
        labelLoadingStatus: EAsyncStatus.SUCCESS,
        errorMessage: null,
        errorType: null,
      });
      notificationService.dismiss();
      this.logFieldEvent('confirmation success page loads', {
        'return reason': returnReason,
      });
    } catch (e) {
      notificationService.dismiss();
      this.setState({
        labelLoadingStatus: EAsyncStatus.FAILED,
        isUserAwaitingNewTab: false,
        errorMessage: e.message,
        errorType: e.errorType,
      });
      console.error('processPreadvice::', e);
      return Promise.reject(e);
    }
  };

  openNewTab = (url?: string) => {
    this.logFieldEvent('click on "Find location" link');
    if (!url) {
      amplitude.logValidationErrors(
        [
          {
            errors: [
              {
                message: 'no "Find location" URL is available',
                field: 'find_location',
              },
            ],
          },
        ],
        SuccessPage.PAGE_NAME
      );
      return;
    }
    if (isIE) {
      const element = document.createElement('a');
      element.setAttribute('href', url);
      element.setAttribute('target', '_blank');
      element.click();
    } else {
      window.open(url, '_blank');
    }
  };

  handlePDFDownloading = () => {
    const { labelLoadingStatus } = this.state;
    this.logFieldEvent('click on download & print label');

    switch (labelLoadingStatus) {
      case EAsyncStatus.SUCCESS:
        /** label have been loaded, opening new tab with it */
        return this.openNewTab(this.props.whitelabelProxyStore.pdfLabelURL);
      case EAsyncStatus.FAILED:
        /** Prev.attempt to load failed, need to retry it and disable button. */
        return this.setState({ isUserAwaitingNewTab: true }, () => {
          this.processPreadvice();
        });
      default:
        /** loading in process */
        return this.setState({ isUserAwaitingNewTab: true });
    }
  };

  logFieldEvent = (eventName, extraPayload?) => {
    const { href: url } = window.location;
    const { company: retailerName } = this.props.match.params;
    amplitude.logEvent(eventName, {
      url,
      retailerName,
      ...extraPayload,
    });
  };

  renderSuccessButton = () => {
    const { isUserAwaitingNewTab } = this.state;

    return (
      <DownloadLabelButton
        onClick={this.handlePDFDownloading}
        disabled={isUserAwaitingNewTab}
      >
        {this.props.t('printBtn')}
      </DownloadLabelButton>
    );
  };

  renderTitle = () => {
    const {
      startPageStore: {
        formFields: { email },
      },
      returnMethodStore: { returnType },
      printOptionStore: { selectedOption },
      whiteLabeled: { returnPaperlessSubtitle, returnOwnLabelSubtitle },
      t,
    } = this.props;

    switch (this.state.labelLoadingStatus) {
      case EAsyncStatus.SUCCESS: {
        const successType: EReturnType | EPrintOptions =
          selectedOption || returnType;
        return (
          <>
            <Title>{t('returnTitle')}</Title>
            <SubTitle>
              <Trans
                i18nKey={
                  selectedOption === EPrintOptions.PAPERLESS
                    ? returnPaperlessSubtitle
                    : returnOwnLabelSubtitle(successType)
                }
                values={{ email }}
                components={{
                  1: <br />,
                  2: <p></p>,
                  3: <br />,
                }}
              />
            </SubTitle>
          </>
        );
      }
      case EAsyncStatus.LOADING:
        return <Title>{t('bookingLoading')}</Title>;
      default:
        return <Title>{t('unexpectedResult')}</Title>;
    }
  };

  renderAddress = (store: any) => {
    switch (this.props.printOptionStore.asyncStatus) {
      case EAsyncStatus.FAILED:
        return (
          <span>
            <Trans
              i18nKey="searchError"
              ns="success"
              components={{
                1: <br />,
              }}
            />
          </span>
        );
      case EAsyncStatus.SUCCESS: {
        const {
          address: { line1, line2, town, area },
        } = store.store.place;

        return (
          <>
            <div>
              <span>{line1},</span>
              {line2 && <span>{line2},</span>}
              {town && <span>{town},</span>}
              <span>{area}</span>
            </div>
            <Distance>
              {store.locationInfo.distance}
              {store.locationInfo.unit}
            </Distance>
          </>
        );
      }
      case EAsyncStatus.LOADING:
        return <Skeleton active paragraph={{ rows: 2 }} />;
      default:
        return <span>{this.props.t('notFound')}</span>;
    }
  };

  get PACKAGE_RETURN() {
    const {
      returnMethodStore: { returnType },
    } = this.props;
    const isDropOff = returnType === EReturnType.DROP_OFF;
    return (
      <StepWrapper>
        <StepTitle>
          {isDropOff
            ? this.props.t('packageTextDropOff')
            : this.props.t('packageText')}
        </StepTitle>
      </StepWrapper>
    );
  }

  get COURIER() {
    return (
      <StepWrapper>
        <StepTitle>{this.props.t('courierText')}</StepTitle>
      </StepWrapper>
    );
  }

  get FIND_LOCATION() {
    const {
      detailsPageStore: { formFields },
      orderStore: { isIntegratedFlow, userInfo },
      printOptionStore: {
        selectedOption,
        nearestStores,
        geoCoordinatesOfAddress: { lat, lng },
      },
      t,
    } = this.props;
    const { findLocationLink } = generateLocateLinks({
      lat,
      lng,
      selectedOption,
      nearestStores,
      address: getUserAddress(isIntegratedFlow, userInfo, formFields),
    });
    return (
      <StepWrapper column>
        <StepTitle>
          {t(
            selectedOption === EPrintOptions.PAPERLESS
              ? 'stepTitlePaperless'
              : 'stepTitleOwnLabel'
          )}
        </StepTitle>

        <NearestLocationText>{t('nearestLocations')}</NearestLocationText>
        <LocationsWrapper>
          {nearestStores[selectedOption].map((nearestStore, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${nearestStore.store.storeId}-${index}`}>
              <StoreName>{nearestStore.store.storeName}</StoreName>
              <AddressWrapper>
                {this.renderAddress(nearestStore)}
              </AddressWrapper>
            </div>
          ))}
        </LocationsWrapper>
        <FindLocationLink onClick={() => this.openNewTab(findLocationLink)}>
          {t('findLocationBtn')}
        </FindLocationLink>
      </StepWrapper>
    );
  }

  get PRINT_LABEL() {
    return (
      <StepWrapper column>
        <StepTitle marginBottomMobile={16}>
          {this.props.t('printLabel')}
        </StepTitle>
        {this.renderSuccessButton()}
      </StepWrapper>
    );
  }

  get RETURN_BAR_CODE() {
    const {
      whiteLabeled: { pdfDownloadEnabled },
    } = this.props;

    return (
      <StepWrapper column>
        <StepTitle marginBottomMobile={16}>
          <Trans i18nKey="returnCodeSubtitle" ns="success" />
        </StepTitle>
        <SubTitle marginBottom={20}>{this.props.t('returnCodeDesc')}</SubTitle>
        {pdfDownloadEnabled && (
          <HintText>
            <Trans
              i18nKey="hint"
              ns="success"
              components={{
                1: <br />,
                2: <Button type="link" onClick={this.handlePDFDownloading} />,
              }}
            />
          </HintText>
        )}
      </StepWrapper>
    );
  }

  get RETURN_2D_CODE() {
    return (
      <StepWrapper column>
        <StepTitle>{this.props.t('2dcodeTitle')}</StepTitle>
        <SubTitle marginBottom={20}>{this.props.t('2dcodeSubtitle')}</SubTitle>
      </StepWrapper>
    );
  }

  get OTHER_PLACES() {
    const openLink = () =>
      this.openNewTab(this.props.whiteLabeled.otherPlacesLink);
    return (
      <StepWrapper column>
        <StepTitle>{this.props.t('nearestLocations')}</StepTitle>
        <FindLocationLink onClick={openLink}>
          {this.props.whiteLabeled.otherPlacesLink}
        </FindLocationLink>
        {this.props.t('nearestLocationsAdditional')}
      </StepWrapper>
    );
  }

  renderIntegratedProducts = () => {
    const {
      orderStore: { products },
      t,
    } = this.props;
    if (!this.intergratedFlowView) {
      return null;
    }

    const populatedOrders = commonStoresActions.filterIntegratedOrderProducts(
      products
    );
    return (
      <IntegratedOrderDetails>
        <IntegratedProductsHeader>
          <StepTitle>{t('summaryTitle')}</StepTitle>
        </IntegratedProductsHeader>
        {populatedOrders.map(product => (
          <IntegratedProduct product={product} key={product.productId} t={t} />
        ))}
      </IntegratedOrderDetails>
    );
  };

  renderList = list =>
    Array.isArray(list)
      ? list.map((name, idx) => {
          if (!this[name]) {
            console.error(
              'Success page rendering error. Invalid method name provided!'
            );
            return null;
          }
          return (
            <ReturnStep key={name}>
              <StepNumber>{idx + 1}</StepNumber>
              {this[name]}
            </ReturnStep>
          );
        })
      : null;

  render() {
    const {
      directoryStore: { customAdImage },
      printOptionStore: { selectedOption },
      returnMethodStore: { returnType },
      themeStore: {
        theme: {
          config: { marketingUrl, isMarketingAssetsVisible },
        },
      },
      t,
    } = this.props;
    const orderIsCreating =
      this.state.labelLoadingStatus === EAsyncStatus.LOADING;

    const successType: EReturnType | EPrintOptions =
      selectedOption || returnType;

    return this.state.labelLoadingStatus === EAsyncStatus.FAILED ? (
      <ReturnFailed
        retryAction={this.processPreadvice}
        t={t}
        errorMessage={this.state.errorMessage}
      />
    ) : (
      <Card
        noPadding
        bordered={false}
        customSize={isMarketingAssetsVisible ? CardSize.LARGE : CardSize.SMALL}
      >
        <ReturnContent>
          {orderIsCreating ? (
            <LoaderArcWrapper>
              <LoaderArc
                arcSize={100}
                arcBorderSize={6}
                color="#111"
                fontSize={28}
                displayText={t('bookingLoading')}
              />
            </LoaderArcWrapper>
          ) : (
            <ReturnStepsWrapper fullWidth={!customAdImage}>
              <SuccessInfo>
                <SuccessIcon>
                  <img src={successStepIcon} alt="success icon" />
                </SuccessIcon>
                <div>{this.renderTitle()}</div>
              </SuccessInfo>
              <Title>{t('whatToDoNext')}</Title>
              {this.renderList(this.successPageSteps[successType])}
            </ReturnStepsWrapper>
          )}
          {isMarketingAssetsVisible && (
            <MarketingImage href={marketingUrl} target="_blank" />
          )}
        </ReturnContent>
        {this.renderIntegratedProducts()}
      </Card>
    );
  }
}

export default compose(
  inject(
    'themeStore',
    'directoryStore',
    'whitelabelProxyStore',
    'orderStore',
    'detailsPageStore',
    'startPageStore',
    'printOptionStore',
    'returnMethodStore'
  ),
  withTranslation('success'),
  withWhitelabelProps({
    countryCode: 'ui.common.countryCode',
    isPrinterOptionDisabled: 'ui.common.isPrinterOptionDisabled',
    otherPlacesLink: 'ui.pages.success.otherPlacesLink',
    returnPaperlessSubtitle: 'ui.pages.success.returnSubtitlePaperless',
    returnOwnLabelSubtitle: 'ui.pages.success.returnSubtitleOwnLabel',
    pdfDownloadEnabled: 'ui.pages.success.pdfDownloadEnabled',
  })
)(SuccessPage);
