import i18nService from '_common/services/i18nService';

export const validateOnSpecialCharacters = (
  _,
  value: string,
  callback: (error?: Error) => void
) => {
  const error = new Error(i18nService.t('wl:validationSpecError'));
  if (/[<#&]/.test(value)) {
    return callback(error);
  }
  return callback();
};

export const validateOnSpecialCharactersAddress = (
  _,
  value: string,
  callback: (error?: Error) => void
) => {
  const error = new Error(i18nService.t('wl:validationSpecError'));
  if (/[<&]/.test(value)) {
    return callback(error);
  }
  return callback();
};

export const convertFromAddress = ({
  postcode,
  phoneNumber,
  email,
  fullName,
  addressLine1,
  addressLine2,
  city,
  state,
}) => ({
  zipCode: postcode,
  phoneNumber,
  email,
  name: fullName,
  line1: addressLine1,
  line2: addressLine2,
  city,
  state,
});

export interface IUspsIntegrated {
  zipCode?: string;
  phoneNumber?: string;
  email?: string;
  name?: string;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
}

export const convertFromAddressIntegrated = (
  email,
  intergratedOrderInfo
): IUspsIntegrated => ({
  zipCode: intergratedOrderInfo.postcode,
  phoneNumber: intergratedOrderInfo.phoneNumber,
  email,
  name: intergratedOrderInfo.name,
  line1: intergratedOrderInfo.line1,
  line2: intergratedOrderInfo.line2,
  city: intergratedOrderInfo.town,
  state: intergratedOrderInfo.area,
});

export const convertToAddress = ({
  line1,
  line2,
  town,
  area,
  returnCompanyName,
  departmentName,
  toPostcode,
  toPhoneNumber,
}) => ({
  line1,
  line2,
  city: town,
  state: area,
  name: returnCompanyName,
  deptName: departmentName,
  zipCode: toPostcode,
  phoneNumber: toPhoneNumber,
  email: 'apemails@doddle.com',
});

export const convertReturns = ({
  purchaseDate,
  returnItems,
  returnReason,
  confirmTerms,
  reasonComments,
  returnsAuthorisationNumber,
  isIntegrated,
}) => ({
  purchaseDate,
  returnReason,
  confirmTerms,
  reasonComments,
  returnsAuthorisationNumber,
  ...(isIntegrated && {
    returnItems: `${returnItems}`,
  }),
});

const convertAddressForMap = ({ line1, line2, town, postcode, area }) => ({
  addressLine1: line1,
  addressLine2: line2,
  city: town,
  postcode,
  state: area,
});

const assetsForStoresEnabled = true;

export default {
  convertFromAddress,
  convertToAddress,
  convertAddressForMap,
  convertReturns,
  convertFromAddressIntegrated,
  assetsForStoresEnabled,
};
