import styled, { css } from 'styled-components';
import { Button as StyledButton, Select as StyledSelect } from 'antd';
import { Icon } from '_common/components';
import { RefObject } from 'react';
import { rtlMixin } from '_common/constants/theme';

export const Root = styled.div`
  color: #fff;
  min-width: 640px;
  border-radius: 3px;
  border: solid 1px #e8e8e8;
  background-color: ${props => props.theme.colors.defaultBackground};
  @media only screen and (max-width: 768px) {
    min-width: unset;
  }
`;

export const Select = styled(StyledSelect)<{ labelText: string }>`
  &:before {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
      content: '${({ labelText = '' }) => `${labelText}`}';
      position: absolute;
      right: auto;
      left: 2rem;
      color: #222;
      font-size: 14px;
      top: 30%;
      z-index: 10;
    }
  }
  &.ant-select {
    color: ${props => props.theme.colors.text};
    margin: 0 8px;
    width: 186px;
    flex-shrink: 0;
    border-left: 1px solid #d8d8d8;
    ${rtlMixin(css`
      border-left: initial;
      border-right: 1px solid #d8d8d8;
    `)}
    .ant-select-selector {
      display: flex;
      align-items: center;
      height: 60px !important;
      border: none !important;
      .ant-select-selection-rendered {
        line-height: 60px;
      }
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin: 0;
      border: 0;
      .ant-select-selector {
        height: 50px;
        .ant-select-selector-rendered {
          line-height: 50px;
          margin-left: 8rem;
        }
      }
    }
  }
`;

const getButtonBgColor = ({ theme }) =>
  theme.config.css.accentColor || '#4a90e2';

export const Button = styled(StyledButton)`
  &.ant-btn,
  &.ant-btn:hover {
    background-color: ${getButtonBgColor};
    color: #fff;
  }

  &.ant-btn {
    font-size: 18px;
    line-height: 20px;
    border: none;
    padding: 10px 33px;
    height: 100%;
  }
`;

export const Input = styled.input<{ ref: RefObject<HTMLInputElement> }>`
  height: 60px;
  flex-grow: 1;
  font-size: 18px;
  font-family: ${props => props.theme.fonts.basic};
  color: ${props => props.theme.colors.text};
  border: none;
  border-radius: 4px;
  margin-right: 5px;
  padding-left: 20px;
  min-width: 100px;
`;

export const InputWrapper = styled.div`
  height: 60px;
  padding: 0;
  background-color: ${props => props.theme.colors.defaultBackground};
  border-radius: 3px;
  display: flex;
  align-items: center;
  font-size: 18px;
  width: 100%;
  & > div:first-child {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    @media only screen and (max-width: 768px) {
      border-bottom: 1px solid rgba(151, 151, 151, 0.25);
      border-radius: 0;
      height: 50px;
      ${Input} {
        height: 100%;
        font-size: 2rem;
        padding-left: 1.6rem;
        padding-right: 12rem;
        margin: 0;
        text-overflow: ellipsis;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    ${Select} {
      width: 100%;
    }
    ${Button} {
      display: none;
    }
  }
`;

export const LabelWrapper = styled.div`
  position: relative;
`;

export const GeoIcon = styled(Icon)<{ disabled: boolean }>`
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    position: absolute;
    right: 16px;
    top: 13px;
  }
`;

export const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.error};
`;

export const ClearButton = styled.div`
  font-size: 14px;
  cursor: pointer;
  color: rgb(199, 199, 204);
  text-decoration: underline;
  margin-right: 10px;
  ${rtlMixin(css`
    margin-right: initial;
    margin-left: 10px;
  `)}
  @media only screen and (max-width: 768px) {
    position: absolute;
    right: 45px;
    top: 14px;
  }
`;
