import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { compose } from 'recompose';
import { get } from 'lodash';
import { BgType } from 'types/core';
import { StyledBackgroundWrapper } from './elements';
import { isMobile } from 'react-device-detect';

export interface BackgroundWrapperProps {
  children: React.ReactNode;
  expandedFooter: boolean;
  isEmbedded: boolean;
  themeStore: any;
  isFooterHidden: boolean;
}
@observer
class BackgroundWrapper extends React.Component<BackgroundWrapperProps, any> {
  state = {
    backGroundSrc: '#fff',
  };

  disposeReaction: IReactionDisposer;

  componentDidMount() {
    this.disposeReaction = reaction(
      () => this.props.themeStore.theme,
      newThemeVal => {
        if (!newThemeVal) return;
        const {
          colors: { defaultBackground },
        } = newThemeVal;
        if (this.props.isEmbedded || isMobile) {
          this.setState({
            backGroundSrc: defaultBackground,
          });
          return;
        }
        switch (newThemeVal.config.bgType) {
          case BgType.IMAGE: {
            this.processImageLoading(newThemeVal);
            break;
          }
          case BgType.COLOR: {
            this.setState({
              backGroundSrc: get(
                newThemeVal,
                'config.css.bgColor',
                defaultBackground
              ),
            });
            break;
          }
          default: {
            if (!newThemeVal.assets.backgroundImage) return;
            this.processImageLoading(newThemeVal);
          }
        }
      }
    );
  }

  componentWillUnmount() {
    this.disposeReaction();
  }

  processImageLoading = theme => {
    const img = new Image();
    img.onload = () => {
      this.setState({
        backGroundSrc: `url(${theme.assets.backgroundImage})`,
      });
    };
    if (theme.assets.placeholder) {
      this.setState({
        backGroundSrc: `url(${theme.assets.placeholder})`,
      });
    }
    img.src = theme.assets.backgroundImage;
  };

  render() {
    const { children, ...rest } = this.props;
    return (
      <>
        <StyledBackgroundWrapper {...rest} imageSrc={this.state.backGroundSrc}>
          {children}
        </StyledBackgroundWrapper>
      </>
    );
  }
}
export default compose(inject('themeStore'))(BackgroundWrapper);
