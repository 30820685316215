import { getEnvVar } from '_common/utils/environmentVariable';

/* eslint camelcase: 0 */
// @ts-ignore
(function generateManifest() {
  const customIconsPath = `icons/${getEnvVar('REACT_APP_ROOT_ORG_ID')}`;
  const myDynamicManifest = {
    name: 'Returns Portal',
    short_name: 'Returns Portal',
    start_url: '.',
    background_color: '#ffffff',
    theme_color: '#000000',
    display: 'standalone',
    icons: [
      {
        src: `${customIconsPath}/android-chrome-192x192.png`,
        sizes: '192x192',
        type: 'image/png',
      },
      {
        src: `${customIconsPath}/android-chrome-512x512.png`,
        sizes: '512x512',
        type: 'image/png',
      },
      {
        src: `${customIconsPath}/favicon-16x16.png`,
        sizes: '16x16',
        type: 'image/x-icon',
      },
      {
        src: `${customIconsPath}/favicon-32x32.png`,
        sizes: '32x32',
        type: 'image/x-icon',
      },
      {
        src: `${customIconsPath}/favicon.ico`,
        sizes: '16x16',
        type: 'image/x-icon',
      },
    ],
  };
  const stringManifest = JSON.stringify(myDynamicManifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  document
    .getElementById('manifest-placeholder')
    .setAttribute('href', manifestURL);
})();
