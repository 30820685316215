import React from 'react';
import { Card } from '_common/components';
import {
  Title,
  SubTitle,
  RetryButton,
  ReturnFailedContent,
  SuccessIcon,
} from '../elements';
import { primaryCross } from 'assets';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

type Props = {
  retryAction: () => void;
  t: TFunction;
  errorMessage: string;
};

const ReturnFailed = ({ retryAction, errorMessage, t }: Props) => (
  <Card>
    <ReturnFailedContent>
      <div>
        <SuccessIcon>
          <img src={primaryCross} alt="failed icon" />
        </SuccessIcon>
        <Title centered>
          <Trans
            i18nKey="returnFailed"
            ns="success"
            components={{
              1: <br />,
            }}
          />
        </Title>
        <SubTitle>{errorMessage}</SubTitle>
      </div>
      <RetryButton onClick={retryAction}>{t('returnBtn')}</RetryButton>
    </ReturnFailedContent>
  </Card>
);

export default ReturnFailed;
