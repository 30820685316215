import styled, { keyframes } from 'styled-components';

export const PageWrapper = styled.div<{
  global?: boolean;
}>`
  width: 100%;
  min-height: ${({ global }) =>
    global
      ? 'calc(100vh - 100px - 60px)'
      : 'calc(100vh - 100px - 60px - 120px)'}; // header+footer+-card padding
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;
const ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }`;
const ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

export const LoaderDots = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #777;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  div:nth-child(1) {
    left: 6px;
    animation: ${ellipsis1} 0.6s infinite;
  }
  div:nth-child(2) {
    left: 6px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  div:nth-child(3) {
    left: 26px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  div:nth-child(4) {
    left: 45px;
    animation: ${ellipsis3} 0.6s infinite;
  }
`;

export const LoaderText = styled.div`
  font-size: 2rem;
`;
export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ErrorWrapper = styled.div`
  font-size: 1.5rem;
  color: orangered;
`;
