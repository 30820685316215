import styled from 'styled-components';

export const ModalBody = styled.div<{
  bodyWidth?: number;
  hasCloseBtn?: boolean;
}>`
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #f4f4f5;
  z-index: ${props => props.theme.zIndex.elevation2};
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translateX(50%) translateY(50%);
  width: ${({ bodyWidth }) => (bodyWidth ? `${bodyWidth}px` : 'auto')};
  max-width: calc(100% - 4rem);
  color: ${props => props.theme.colors.white};

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    right: 0;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid #222;
    border-top: 20px solid #222;
    border-bottom: 20px solid transparent;
    display: ${({ hasCloseBtn }) => (hasCloseBtn ? 'block' : 'none')};
    border-radius: 3px;
  }

  & > span {
    position: absolute;
    right: 5px;
    top: 5px;
    color: ${props => props.theme.colors.white};
  }
`;

export const ModalOverlay = styled.div`
  && {
    background: rgba(0, 0, 0, 0.8);
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    position: absolute;
  }
`;

export const ModalWrapper = styled.div`
  && {
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    max-width: inherit;
    position: fixed;
    overflow-y: auto;
    z-index: ${props => props.theme.zIndex.elevation6};
  }
`;
