import { IProductJourneyType } from 'types/core';

export default (stores): IProductJourneyType => {
  const journey = stores.directoryStore.productIntegratedJourney;
  return {
    NOT_SUPPORTED: typeof journey === 'undefined',
    INTEGRATED: typeof journey === 'boolean' && journey,
    NON_INTEGRATED: typeof journey === 'boolean' && !journey,
  };
};
