import { get } from 'lodash';
import { action, observable } from 'mobx';
import { EReturnType } from 'types/core';
import { getMerchantFromUrl } from '_common/utils';
import { MOBX_STORES, sessionStore } from 'storage';

class ReturnMethodStore {
  @observable
  returnType: EReturnType | null = null;

  @observable
  pickupDate: string | null = null;

  @observable
  pickupTime: string | null = null;

  registerCommonActions = commonStoresActions => {
    commonStoresActions.validateSession(getMerchantFromUrl());
    const savedStoreState = sessionStore.get(MOBX_STORES.ReturnMethodPageStore);

    this.returnType = get(savedStoreState, 'returnType', null);
    this.pickupDate = get(savedStoreState, 'pickupDate', null);
    this.pickupTime = get(savedStoreState, 'pickupTime', null);
  };

  @action
  setReturnMethod = (returnType: EReturnType) => {
    this.returnType = returnType;
  };

  @action
  setpickupDate = (pickupDate: string) => {
    this.pickupDate = pickupDate;
    // reset time, as it tight be unavailable
    this.pickupTime = null;
  };

  @action
  setpickupTime = (pickupTime: string) => {
    this.pickupTime = pickupTime;
  };

  @action
  resetStore = () => {
    this.returnType = null;
    this.pickupDate = null;
    this.pickupTime = null;
  };
}

export default ReturnMethodStore;
