import engine from 'store/src/store-engine';

import sessionStorage from 'store/storages/sessionStorage';
import localStorage from 'store/storages/localStorage';
import defaultPlugin from 'store/plugins/defaults';
import eventsPlugin from 'store/plugins/events';
import operationsPlugin from 'store/plugins/operations';
import { includes } from 'lodash';

const plugins = [defaultPlugin, eventsPlugin, operationsPlugin];

const _sessionStore = engine.createStore([sessionStorage], plugins);
const _localStore = engine.createStore([localStorage], plugins);

// Constants
export const TOKEN_KEY = 'returnsportalclient/token';
export const REFRESH_TOKEN_KEY = 'returnsportalclient/refreshtoken';
export const TOKEN_LIFETIME = 'returnsportalclient/tokenlifetime';
export const USER_STORAGE_KEY = 'returnsportalclient/user';

export const PDF_LABEL_URL = 'returnsportalclient/PdfLabelURL';
export const START_EVENT_SEND = 'returnsportalclient/startEventSend';

export const MOBX_STORES = {
  StartPageStore: 'returnsportalclient/StartPageStore',
  DetailsPageStore: 'returnsportalclient/DetailsPageStore',
  PrintOptionPageStore: 'returnsportalclient/PrintOptionPageStore',
  ReturnMethodPageStore: 'returnsportalclient/ReturnMethodPageStore',
};
export const SUBMITTED_STEPS = 'returnsportalclient/SubmittedSteps';

export const CURRENT_SESSION = 'returnsportalclient/session';

export const USER_LOCALE = 'returnsportalclient/locale';

export type CurrentSession = {
  startDate: number;
  merchantId: string;
};

export const localStore = {
  watch: (key: string, listener) => _localStore.watch(key, listener),
  get: (key: string) => _localStore.get(key),
  set: (key: string, value): void => {
    try {
      return _localStore.set(key, value);
    } catch (err) {
      console.error('unable to set value for storage');
    }
  },
  append: (key: string, value) => {
    try {
      _sessionStore.push(key, value);
    } catch (err) {
      console.error('unable to set value for storage');
    }
  },
  remove: (key: string): void => _localStore.remove(key),
  removeAll: (): void => {
    _localStore.clearAll();
  },
};

export const sessionStore = {
  get: (key: string) => _sessionStore.get(key),
  set: (key: string, value): void => {
    try {
      return _sessionStore.set(key, value);
    } catch (err) {
      console.error('unable to set value for storage');
    }
  },
  append: (key: string, value) => {
    try {
      const stored = _sessionStore.get(key);
      if (!includes(stored, value)) {
        _sessionStore.push(key, value);
      }
      return _sessionStore;
    } catch (err) {
      console.error('unable to set value for storage');
    }
  },
  remove: (key: string): void => _sessionStore.remove(key),
  removeAll: (): void => {
    _sessionStore.clearAll();
  },
};
