import { apiClient } from '_common/api/clients/clients';

interface IRatingsRequest {
  interactionType: string;
  interactionId: string;
  serviceType: string;
  serviceId: string;
  ratingType: string;
  ratingValue: number;
}

const sendRating = async (body: IRatingsRequest): Promise<any> =>
  apiClient.post('/v1/ratings/', body);

export default {
  sendRating,
};
