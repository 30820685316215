import styled, { css } from 'styled-components';
import { rtlMixin } from '_common/constants/theme';

export const Wrapper = styled.div`
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 769px) {
    width: 100%;
  }
`;

export const LinkSection = styled.div``;

export const Delimeter = styled.div<{ spacing: number }>`
  position: relative;
  width: ${({ spacing }) => (spacing ? `${spacing}px` : '10px')};
  display: inline-block;
  ::before {
    width: 1px;
    height: 23px;
    position: absolute;
    top: -15px;
    left: ${({ spacing }) => (spacing ? `${spacing / 2}px` : '5px')};
    content: '';
    background-color: ${props => props.theme.colors.border};
  }
  @media only screen and (max-width: 480px) {
    width: 10px;
    ::before {
      left: 5px;
    }
  }
`;

export const FooterContent = styled.div<{
  hasCarrierLogo: boolean;
  logoStacked: boolean;
  withSocialIcons?: boolean;
  locatePageView?: boolean;
}>`
  height: 60px;
  background-color: #ffffff;
  border-top: solid 1px #dfdfdf;
  position: relative;
  color: #888888;
  /** TODO Refactor this mess */
  ${Wrapper} {
    padding: 0 30px;
    justify-content: ${({ hasCarrierLogo, logoStacked }) =>
      logoStacked ? 'flex-end' : hasCarrierLogo ? 'space-between' : 'flex-end'};
    @media only screen and (max-width: 768px) {
      flex-direction: ${({ logoStacked }) => (logoStacked ? 'column' : 'row')};
      justify-content: ${({ hasCarrierLogo, logoStacked }) =>
        logoStacked ? 'flex-end' : hasCarrierLogo ? 'space-between' : 'center'};
    }
  }
  @media only screen and (max-width: 768px) {
    height: ${({ logoStacked, locatePageView }) =>
      logoStacked ? (locatePageView ? '160px' : '165px') : '60px'};
    border-top: unset;
    ${({ locatePageView }) => !locatePageView && 'margin-top: 1.6rem;'};
    padding: ${({ logoStacked }) => (logoStacked ? '0' : '0 5px')};
    ${LinkSection} {
      ${({ logoStacked }) => logoStacked && 'margin-top: 3rem;'};
      @media only screen and (max-width: 769px) {
        ${({ logoStacked }) => logoStacked && 'margin-left: 4.2rem;'};
      }
      &:before {
        height: 1px;
        width: 100%;
        content: '';
        position: absolute;
        top: ${({ withSocialIcons }) => `${withSocialIcons ? 75 : 70}px`};
        left: 0;
        background-color: #dfdfdf;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    ${Wrapper} {
      padding: 0;
    }
  }
`;

const menuItem = css`
  padding: 3px 5px;
  color: inherit;
  font-family: ${props => props.theme.fonts.basic};
  font-size: ${props => props.theme.fontSize.xxs};
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
  &:focus {
    border: 1px dotted;
    border-radius: 3px;
  }
`;

export const Link = styled.a`
  ${menuItem}
`;

export const StyledButton = styled.button`
  ${menuItem};
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    border: 0;
    outline: none;
  }
  & > span {
    font-size: 0.8rem !important;
  }
`;

export const LanguageMenuItem = styled.div`
  font-size: ${props => props.theme.fontSize.s};
  font-family: ${props => props.theme.fonts.basic};
  display: flex;
  align-items: center;
  span {
    font-size: 0.9rem !important;
    margin-left: 0 !important;
    transform: rotate(180deg) !important;
  }
`;

export const LanguageIcon = styled.div<{ iconName?: string }>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-image: url(${props => props.iconName});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;

  ${rtlMixin(css`
    margin-right: initial;
    margin-left: 10px;
  `)}
`;

export const MenuHolder = styled.div`
  transform: translate(15px, 35px);

  ul {
    padding: 5px;
    border: solid 1px rgba(151, 151, 151, 0.25);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
    border-radius: 2px;
    width: 18rem;
  }

  li {
    padding: 0 !important;
    margin: 0 5px !important;
    border-bottom: solid 1px rgba(151, 151, 151, 0.25);
  }
`;

export const SocialLinkSection = styled.div`
  margin-left: 2rem;
  @media only screen and (min-width: 769px) {
    margin-right: 35px;
  }
  @media only screen and (max-width: 769px) {
    margin: 1.4rem 0 1.4rem;
  }
`;

export const SocialLink = styled.a`
  &:not(:last-child) {
    margin-right: 20px;
  }
  position: relative;

  & img {
    width: 35px;
    outline: none;
    filter: brightness(0.65);
  }

  &:hover {
    & img {
      filter: brightness(0.8);
    }
  }

  &:focus {
    &:after {
      border: 1px dotted #fff;
      content: '';
      display: block;
      height: 41px;
      left: -3px;
      pointer-events: none;
      position: absolute;
      top: -10px;
      width: 41px;
      border-radius: 50%;
    }
    & img {
      filter: brightness(0.8);
    }
  }
`;
