import { parseEnabledFeatures } from '_common/utils/appConfig';
import { getEnvVar } from '_common/utils/environmentVariable';

export const ROOT_ORG_ID = getEnvVar('REACT_APP_ROOT_ORG_ID');
export const ROOT_CARRIER_ID = getEnvVar('REACT_APP_CARRIER');
export const SECURE_PAY_MERCHANT_ID = getEnvVar(
  'REACT_APP_SECURE_PAY_MERCHANT_ID'
);
export const SECURE_PAY_TRANSACTION_PASSWORD = getEnvVar(
  'REACT_APP_SECURE_PAY_TRANSACTION_PASSWORD'
);
export const RETURNS_PORTAL_BUILD_NAME = getEnvVar(
  'REACT_APP_RETURNS_PORTAL_BUILD_NAME'
);
export const ALLOW_MULTIPLE_INTEGRATED_RETURNS = !!(
  getEnvVar('REACT_APP_MULTIPLE_INTEGRATED_RETURNS') &&
  getEnvVar('REACT_APP_MULTIPLE_INTEGRATED_RETURNS').trim().toUpperCase() ===
    'TRUE'
);

export const GOOGLE_API_KEY = getEnvVar('REACT_APP_GOOGLE_API_KEY');
export const SENTRY_DSN_URL = getEnvVar('REACT_APP_SENTRY_DSN_URL');
export const DEFAULT_LOCALE = getEnvVar('REACT_APP_DEFAULT_LOCALE');

const getWhiteLabelConfigName = () =>
  [getEnvVar('REACT_APP_ROOT_ORG_ID'), getEnvVar('REACT_APP_CARRIER')]
    .filter(name => name && name !== 'N/A')
    .join('_');

export const ROOT_ORG_FULLNAME = getWhiteLabelConfigName();

export const AsyncStatus = Object.freeze({
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  IDLE: 'IDLE',
});

export enum PAYMENT_TYPES {
  MERCHANT_PAID = 'MERCHANT_PAID',
  CONSUMER_PAID = 'CONSUMER_PAID',
  BOTH_PAID = 'BOTH_PAID',
}

export const ASPECT_RATIO_16_9 = 1.777777777777778;

export const CURRENCY_SYMBOLS = {
  USD: '$',
  JPY: '¥',
  GBP: '£',
  SR: 'SR',
};

export const API_PURCHASE_DATE_FORMAT = 'DD-MM-YYYY';

export const ENABLED_FEATURES = parseEnabledFeatures(
  getEnvVar('REACT_APP_ENABLED_FEATURES')
);

export const RETURNS_PORTAL_SERVICE_POSTFIX =
  getEnvVar('REACT_APP_RETURNS_PORTAL_SERVICE_POSTFIX') || '';
