import { IOrder } from 'types/order';
import { toJS, ObservableMap } from 'mobx';
import { IReason } from 'types/company';
import { omit, uniq, get } from 'lodash';
import { RETURNS_PORTAL_BUILD_NAME } from '_common/constants/common';

const prepareCreateReturnRequest = (
  orderData: IOrder,
  selectedReturnCommentForProduct: ObservableMap<string, string>,
  selectedReturnReasonsForProduct: ObservableMap<string, IReason>
) => {
  const {
    companyId,
    customer,
    orderLines,
    externalOrderId,
    externalOrderData: { purchaseDate },
  } = orderData;
  const comments = toJS(selectedReturnCommentForProduct);
  const reasons = toJS(selectedReturnReasonsForProduct);
  const selectedProductIds = uniq(
    Object.keys(comments).concat(Object.keys(reasons))
  );

  const updatedCustomer = {
    name: `${customer.name.firstName} ${customer.name.lastName}`,
    email: customer.email,
    address: orderData.externalOrderData.deliveryAddress,
    ...(customer.mobileNumber && {
      phoneNumber: customer.mobileNumber,
    }),
  };

  // send only selected items
  const selectedOrderLines = orderLines.filter(line =>
    selectedProductIds.some(id => id === line.product.productId)
  );
  const updatedOrderLines = selectedOrderLines.map(line => {
    const updatedLine = omit(line, [
      'product.reasonComments',
      'product.purchaseDate',
      'product.returnWindow',
      'product.isApproved',
    ]);
    const comment = comments[line.product.productId];
    const reason = get(reasons[line.product.productId], 'description');
    updatedLine.returnData = {};
    if (comment) {
      updatedLine.returnData.reasonComments = comment;
    }
    if (reason) {
      updatedLine.returnData.returnReason = reason;
    }
    return updatedLine;
  });

  return {
    companyId,
    customer: updatedCustomer,
    orderLines: updatedOrderLines,
    externalOrderId,
    purchaseDate,
    appName: RETURNS_PORTAL_BUILD_NAME,
  };
};

export default prepareCreateReturnRequest;
