import commonStoresActions from '_common/actions';
import i18nService from '_common/services/i18nService';

export const LOCATION_LABEL_BY_TYPE = {
  POST_OFFICES: i18nService.t('success:POST_OFFICES'),
  STREET_POST_BOXES: i18nService.t('success:STREET_POST_BOXES'),
  OTHER_RETAILERS: i18nService.t('success:OTHER_RETAILERS'),
};

export const OPTION_ALL_LOCATIONS = {
  value: 'ALL_LOCATIONS',
  label: i18nService.t('success:OPTION_ALL_LOCATIONS'),
};

export const OPTION_EXTENDED_HOURS = {
  value: 'EXTENDED_HOURS',
  label: i18nService.t('success:OPTION_EXTENDED_HOURS'),
};

export const OPTION_PRINT_IN_STORE = {
  value: 'PRINT_IN_STORE',
  label: i18nService.t('success:OPTION_PRINT_IN_STORE'),
};

export const LOCATIONS_MAPPER = {
  POST_OFFICES: 'POSTOFFICE',
  STREET_POST_BOXES: 'POSTBOX',
  EXPRESS_POSTBOX: 'EXPRESS_POSTBOX',
  OTHER_RETAILERS: 'STORE',
};

export const getAllLocationsMapper = () => {
  const activeLocationTypes = commonStoresActions.getLocationTypes();
  const allLocations = activeLocationTypes.reduce((acc, { value }) => {
    const mappedName = LOCATIONS_MAPPER[value];
    if (!mappedName) {
      return acc;
    }
    return acc ? `${acc},${mappedName}` : mappedName;
  }, '');

  return {
    ...LOCATIONS_MAPPER,
    ALL_LOCATIONS: allLocations,
    EXTENDED_HOURS: allLocations,
    PRINT_IN_STORE: allLocations,
  };
};

export const MAX_STORES = 40;
export const MAX_DISTANCE = 50;

const STREET_POSTBOX_NAME = i18nService.t('success:STREET_POSTBOX_NAME');

export const normalizeStoreName = (storeName, locationType) =>
  locationType === 'POSTBOX' || locationType === 'EXPRESS_POSTBOX'
    ? STREET_POSTBOX_NAME
    : storeName;
