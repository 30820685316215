import Icon from '@ant-design/icons';
import { DatePicker as DP } from 'antd';
import React, { Component, SyntheticEvent } from 'react';
import { DatePickerDropdownStyles, DatePickerWrapper } from './elements';
import { PURCHASE_DATE_ERRORS } from '_common/constants/date';
import { DatePickerProps } from 'antd/lib/date-picker';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { compose } from 'recompose';
import { IconProps } from '@ant-design/compatible/lib/icon';

type Props = DatePickerProps & {
  onChange?: (date: object, dateString: string) => void;
  onClick: (e: SyntheticEvent<HTMLDivElement>) => void;
  dateErrors: string[];
  fullWidth: boolean;
  t: TFunction;
};

const CustomIcon = (props: IconProps) => {
  const Svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 20">
      <g fill="#030104" fillRule="nonzero">
        <path d="M14 4c.552 0 1-.584 1-1.312V1.315C15 .586 14.553 0 14 0c-.553 0-1 .586-1 1.315v1.374C13 3.416 13.447 4 14 4z" />
        <path d="M18.98 2.036h-2.692v.72c0 1.188-.911 2.156-2.035 2.156-1.125 0-2.035-.968-2.035-2.156v-.72h-5.43v.72c0 1.188-.914 2.156-2.037 2.156-1.123 0-2.035-.967-2.035-2.156v-.72L0 2v18H17.644L19 19.996l-.02-17.96zm-1.338 16.53H1.358V7.067h16.286v11.5h-.002z" />
        <path d="M5 4c.552 0 1-.584 1-1.312V1.315C6 .585 5.552 0 5 0c-.553 0-1 .586-1 1.315v1.374C4 3.416 4.446 4 5 4zM7 8h2v2H7zM10 8h2v2h-2zM13 8h2v2h-2zM7 11h2v2H7zM10 11h2v2h-2zM13 11h2v2h-2zM7 15h2v2H7zM4 11h2v2H4zM4 15h2v2H4zM10 15h2v2h-2zM13 15h2v2h-2z" />
      </g>
    </svg>
  );

  return <Icon component={Svg} {...props} />;
};

interface State {
  popupWidth: number | null;
}

class DatePicker extends Component<Props, State> {
  inputRef = React.createRef<HTMLDivElement>();

  state = {
    popupWidth: null,
  };

  componentDidMount() {
    this.setState({ popupWidth: this.inputRef.current.offsetWidth });
  }

  get dateErrors() {
    const { dateErrors, t } = this.props;
    if (!dateErrors) {
      return null;
    }

    const fullMsg = t(`constants:${PURCHASE_DATE_ERRORS.FULL_MESSAGE}`);
    const shortMsg = t(`constants:${PURCHASE_DATE_ERRORS.SHORT_MESSAGE}`);

    return dateErrors.some(el => el === fullMsg) ? shortMsg : null;
  }

  render() {
    const { onClick, fullWidth, onChange } = this.props;
    return (
      <DatePickerWrapper ref={this.inputRef} onClick={onClick}>
        {fullWidth && (
          <DatePickerDropdownStyles width={this.state.popupWidth} />
        )}
        <DP
          suffixIcon={<CustomIcon />}
          {...this.props}
          onChange={onChange}
          dropdownClassName="rpc-datepicker"
          allowClear={false}
        />
      </DatePickerWrapper>
    );
  }
}

export default compose(withTranslation('constants', { withRef: true }))(
  DatePicker
);
