import styled, { createGlobalStyle, DefaultTheme } from 'styled-components';
import { FORM_ITEM_HEIGHT } from '_common/constants/styles';

export const DatePickerWrapper = styled.div`
  position: relative;

  .ant-picker {
    width: 100%;
    height: ${FORM_ITEM_HEIGHT};
    border-style: solid;
    border-width: 1px;
    color: ${props => props.theme.colors.textAlt};
    font-size: ${props => props.theme.fontSize.m};
    @supports (-webkit-overflow-scrolling: touch) {
      font-size: 16px !important;
    }
    @media only screen and (max-width: 768px) {
      height: 50px;
    }

    .ant-picker-suffix {
      pointer-events: auto;
    }

    & span.anticon {
      width: 19px;
      height: 18px;
      right: 16px;
      top: 45%;
      cursor: pointer;
    }
  }

  .ant-picker-input {
    font-family: ${props => props.theme.fonts.primary};
    letter-spacing: -1.5px;

    ::placeholder {
      font-family: ${props => props.theme.fonts.primary};
      font-size: ${props => props.theme.fontSize.m};
      font-weight: 400;
      letter-spacing: 0;
      color: #c7c7c7;
    }
  }
`;

export const DatePickerDropdownStyles = createGlobalStyle<{
  width: number;
  theme: DefaultTheme;
}>`
  .ant-form-item:not(.ant-form-item-has-error) .ant-picker {
    border-color: #e8e8e8;
  }
  .ant-picker-input {
    height: ${FORM_ITEM_HEIGHT};
    && input {
      letter-spacing: 0;
      height: 100%;
      font-size: ${props => props.theme.fontSize.m};
    }
  }
  .rpc-datepicker {
    font-family: ${props => props.theme.fonts.primary}, sans-serif !important;

    & .ant-picker-panel-container {
      .ant-picker-panel, .ant-picker-date-panel {
        width: 100%;
      }

      ${({ width }) => width && `width: ${width}px;`};

      .ant-picker-content {
        ${({ width }) =>
          width && `width: ${width - 24}px;`}; // 12 is padding, fit content
      }

      & .ant-picker-content td {
        height: 45px;
      }

      & .ant-picker-cell-today > .ant-picker-cell-inner {
        background: #000 !important;
        color: ${props => props.theme.colors.white};
        font-weight: 700;

        &::before {
          display: none;
        }
      }

      & .ant-calendar-date:hover {
        background: #e5e5e5;
      }

      & .ant-picker-today-btn {
        color: #000;
      }

      .ant-picker-cell-selected > .ant-picker-cell-inner {
        background: #000;
        color: #ffffff;
      }

      & .ant-picker-cell-disabled {
        pointer-events: auto;
        cursor: not-allowed;
      }
    }
  }
`;
