import moment from 'moment';
import { INTERNAL_EVENT_STATES } from 'pages/tracking/stores/trackingStore';

export const getSecondaryConfig = (
  utcDateTime: string,
  internalEventState: string
) => {
  if (utcDateTime) {
    return {
      secondaryText: moment
        .utc(utcDateTime)
        .local()
        .format('h:mm a - DD MMM YYYY'),
      isTextShouldBeGreyed: false,
    };
  }

  return internalEventState === INTERNAL_EVENT_STATES.EVENT_SKIPPED
    ? {
        secondaryText: 'Unavailable',
        isTextShouldBeGreyed: true,
      }
    : {
        secondaryText: undefined,
        isTextShouldBeGreyed: false,
      };
};
