import { Button } from '_common/components/Button/Button';
import styled, { css } from 'styled-components';
import { StyledLink } from '_common/components/TermsLink/elements';

export const AdditionalInfo = styled.span`
  font-size: ${props => props.theme.fontSize.l};
  font-weight: bold;
  color: ${props => props.theme.colors.textAlt};
  display: flex;
  justify-content: start;
  margin-bottom: 1rem;
  font-family: ${props => props.theme.fonts.primary};
  @media only screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

export const CenteredItem = styled.div`
  text-align: center;
`;

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${StyledLink} {
    margin-top: 0;
    padding-left: 10px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubText = styled.p<{ breakLine: boolean }>`
  font-size: ${props => props.theme.fontSize.l};
  font-weight: normal;
  line-height: 1.5;
  color: ${props => props.theme.colors.textAlt};
  margin-bottom: 2rem;
  @media only screen and (min-width: 768px) {
    ${({ breakLine }) => breakLine && 'white-space: pre-line;'}
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const ConfirmButton = styled(Button)`
  && {
    margin-top: 10px;
    font-weight: 400;
  }
`;

export const FormErrorMessage = styled.div<{ centered?: boolean }>`
  font-size: ${props => props.theme.fontSize.s};
  font-weight: 400;
  letter-spacing: 0;
  color: #d0021b;
  ${({ centered }) => centered && 'margin: 0 auto;'}
  ${props =>
    props.hidden &&
    css`
      display: none;
    `}
`;
