import styled from 'styled-components';

export const ButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
  @media only screen and (max-width: 480px) {
    margin-bottom: 2rem;
    align-items: center;
  }
`;
