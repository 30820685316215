import { includes } from 'lodash';
import i18nService from '_common/services/i18nService';
import { TValidatePreconditions } from 'types/core';
import {
  DEFAULT_SPECIAL_CHARS_REGEX,
  doubleByteChars,
  doubleByteSybmols,
  doubleByteNumbers,
  doubleByteHyphenes,
  doubleByteKatakana,
} from '_common/constants/regex';
import { ValidationRule } from '@ant-design/compatible/lib/form';

/**
 * Validate phone number
 * @param _
 * @param phoneNumber
 * @param callback
 */
export const validatePhoneNumber = (
  _: ValidationRule,
  phoneNumber: string,
  callback: (error?: Error) => void
) => {
  const error = new Error(i18nService.t('utils:phoneError'));

  if (!phoneNumber) return callback();
  // contains invalid chars (0-9, whitespaces, '(', ')', '-', '+' are valid)
  if (/[^\d\-\s()+]/.test(phoneNumber) || phoneNumber.includes('--')) {
    return callback(error);
  }
  const formattedValue = phoneNumber.replace(/[-\s]/g, '');
  const digitsCount = (formattedValue.match(/\d/g) || []).length;
  const validLength = digitsCount >= 4 && digitsCount <= 15;

  // +XXXXXX or +XX(XXX)XXX
  if (
    validLength &&
    (/^\+?\d+$/.test(formattedValue) ||
      /^\+?\d*\(\d+\)\d+$/.test(formattedValue))
  ) {
    return callback();
  }
  callback(error);
};

/**
 * This util should be used for multiple validators checks. Allows passing in a
 * message to be used if the regex fails
 *
 * @param allowedLengths Allowed length of field
 * @param regex Regex to be used in the check
 * @param restrictRegex Regex to be restricted in validation
 * @param specialCharacters regex with FORBIDDEN chars
 * @returns {Function}
 */
export const validateByPreconditions: TValidatePreconditions = ({
  allowedLengths,
  regex,
  restrictRegex,
  specialCharacters = DEFAULT_SPECIAL_CHARS_REGEX,
}) => (_: ValidationRule, inputValue: string, callback) => {
  if (!inputValue) {
    return callback();
  }

  if (allowedLengths && !includes(allowedLengths, (inputValue || '').length)) {
    return callback(i18nService.t('utils:lengthError'));
  }

  if (regex && !regex.value.test(inputValue)) {
    return callback(regex.message || i18nService.t('common:invalidRegex'));
  }

  if (restrictRegex?.value.test(inputValue)) {
    return callback(
      restrictRegex.message || i18nService.t('common:invalidRegex')
    );
  }

  if (specialCharacters?.regexValue?.test(inputValue)) {
    return callback(specialCharacters.errorText);
  }

  return callback();
};

export const validateOnSpecialCharacters = (
  _: ValidationRule,
  value: string,
  callback: (error?: string) => void,
  customRegex = DEFAULT_SPECIAL_CHARS_REGEX
) => {
  const regexp = customRegex.regexValue;
  if (regexp.test(value)) {
    return callback(customRegex.errorText);
  }
  return callback();
};

const generateRuleByRegEx = (regex: RegExp) => (
  message: string | React.ReactNode
) => ({
  validator: validateByPreconditions({
    regex: {
      value: regex,
      message,
    },
  }),
});

export const fullWidthRule = generateRuleByRegEx(
  new RegExp(`^[${doubleByteChars}${doubleByteNumbers}${doubleByteSybmols}]+$`)
);

export const nameValidationRule = generateRuleByRegEx(
  new RegExp(`^[${doubleByteChars}${doubleByteSybmols}]+$`)
);

export const kanaValidationRule = generateRuleByRegEx(
  new RegExp(`^[${doubleByteKatakana}${doubleByteHyphenes}]+$`)
);

export const cityValidationRule = generateRuleByRegEx(
  new RegExp(`^[${doubleByteChars}${doubleByteNumbers}${doubleByteHyphenes}]+$`)
);

export const address1ValidationRule = cityValidationRule;

export const address2ValidationRule = fullWidthRule;

// validation for restricting double-byte characters (eg 株式会)
export const restrictedDoubleBytesRule = (
  message: string | React.ReactNode
) => ({
  validator: validateByPreconditions({
    restrictRegex: {
      value: /([\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff])/,
      message,
    },
  }),
});

export const specialCharactersRule = {
  validator: (rule, value: string, callback: (error?: string) => void) => {
    validateOnSpecialCharacters(rule, value, callback);
  },
};
