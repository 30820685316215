import styled from 'styled-components';
import { IImageDimensions } from 'types/core';
import { LogoWrapper, StyledLogo } from '_common/components/Logo/elements';
import { HEADER_HEIGHT } from '_common/constants/styles';
import { AnimatedPlaceholder } from '../LoadingPage/elements';

export const BackButton = styled.div<{ isHidden: boolean }>`
  display: none;
  align-items: center;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  width: 65px;
  justify-content: center;
  z-index: ${props => props.theme.zIndex.elevation1 - 1};

  @media only screen and (max-width: 420px) {
    display: flex;
    width: 65px;
    justify-content: center;
  }
  & img {
    cursor: pointer;
    width: 10px;
  }
`;

export const MerchantLogoWrapper = styled.div<{ centered: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  ${LogoWrapper} {
    justify-content: ${({ centered }) => (centered ? 'center' : 'flex-start')};
    margin-left: ${({ centered }) => (!centered ? '5rem' : '0')};
  }
  justify-content: ${({ centered }) => (centered ? 'center' : 'flex-start')};
  @media only screen and (max-width: 768px) {
    justify-content: center;
    ${LogoWrapper} {
      justify-content: center;
      margin-left: 0;
    }
  }
  @media only screen and (max-width: 420px) {
    padding: 0 6.5rem;
  }
`;

export const HeaderWrapper = styled.div`
  background-color: #ffffff;
  height: ${HEADER_HEIGHT};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #dfdfdf;
  position: relative;
  @media only screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
    align-items: center;
    height: 63.5px;
    justify-content: flex-start;
  }
  ${StyledLogo} {
    max-height: calc(${HEADER_HEIGHT} - 20px);
    @media only screen and (max-width: 768px) {
      max-height: 50px;
    }
  }
`;

export const CarrierLogo = styled.img<{
  dimensions: IImageDimensions;
  hideOnDesktop?: boolean;
}>`
  width: ${({ dimensions }) => `${dimensions.desktop.width}px`};
  height: ${({ dimensions }) => `${dimensions.desktop.height}px`};
  align-self: center;
  display: ${({ hideOnDesktop }) => (hideOnDesktop ? 'none' : 'block')};
  @media only screen and (max-width: 768px) {
    width: ${({ dimensions }) => `${dimensions.mobile.width}px`};
    height: ${({ dimensions }) => `${dimensions.mobile.height}px`};
    display: ${({ hideOnDesktop }) => hideOnDesktop && 'block'};
    ${({ hideOnDesktop }) => hideOnDesktop && 'margin-left: 1rem'};
  }
`;

export const CarrierLogoWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 768px) {
    position: static;
    width: auto;
    margin: 0 0 0 auto;
    display: none;
  }
`;

export const LogoPlaceholder = styled(AnimatedPlaceholder)`
  height: 65px;
  width: 175px;
  margin-left: 20px;
  margin-top: 6px;
  @media only screen and (max-width: 768px) {
    width: 145px;
    margin-left: 0;
    margin-top: 6px;
    height: 50px;
  }
`;
