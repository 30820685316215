import React from 'react';
import {
  AccentText,
  Body,
  CheckIcon,
  Footer,
  FooterAccentText,
  FooterContent,
  IconSection,
  InfoSection,
  PointDetailsWrapper,
  PointText,
  PointWrapper,
  SmallIcon,
  StoreName,
  TypeTitle,
  Wrapper,
  FooterStoresWrapper,
  StoreDistance,
  StoreWrapper,
  NotFoundText,
} from './elements';
import { IStore, IChannelStoreModel } from 'types/store';
import { Skeleton } from 'antd';
import { EAsyncStatus } from 'types/core';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import { isMobile } from 'react-device-detect';

interface IPointDetails {
  text: string;
  isNew?: boolean;
}

interface IPoint {
  includes: IPointDetails[];
  icon: string;
  text: string;
}
interface IPrintOptionConfig {
  title: string;
  nearestLocationText: string;
  icon: string;
  store?: IStore;
  points: IPoint[];
  imageSize?: number;
}

export interface CardProps {
  printOptionConfig: IPrintOptionConfig;
  stores: IChannelStoreModel[];
  asyncStatus: EAsyncStatus;
  isActive: boolean;
  isDefaultOption: boolean;
  onSelected: () => void;
  t: TFunction;
}

const ReturnTypeCard = ({
  printOptionConfig: { title, icon, points, imageSize, nearestLocationText },
  stores,
  onSelected,
  asyncStatus,
  isActive,
  t,
}: CardProps) => {
  const handleCardSelected = () => onSelected();

  const renderStoreDetails = storeToRender => {
    const {
      locationInfo,
      store: {
        storeName,
        storeId,
        place: {
          address: { line1, postcode, area },
        },
      },
    } = storeToRender;

    if (!locationInfo) {
      return null;
    }
    const { distance, unit } = locationInfo;

    return (
      <div key={storeId}>
        <StoreWrapper>
          <StoreName>{storeName}</StoreName>
          <div>
            <span>{line1},</span>
            <br />
            <span>
              {area}, {postcode}
            </span>
          </div>
        </StoreWrapper>
        <StoreDistance>
          {distance}
          {unit}
        </StoreDistance>
      </div>
    );
  };

  const renderFooter = () => {
    switch (asyncStatus) {
      case EAsyncStatus.FAILED:
        return (
          <FooterAccentText noBorder>
            <Trans
              i18nKey="error"
              ns="printOption"
              components={{
                1: <br />,
              }}
            />
          </FooterAccentText>
        );
      case EAsyncStatus.SUCCESS:
        return stores.length > 0 ? (
          <FooterContent>
            <FooterAccentText>
              {t(`wl:${nearestLocationText}`)}
            </FooterAccentText>
            <FooterStoresWrapper>
              {stores.map(renderStoreDetails)}
            </FooterStoresWrapper>
          </FooterContent>
        ) : (
          <NotFoundText>{t('notFound')}</NotFoundText>
        );
      case EAsyncStatus.LOADING:
        return isMobile ? (
          <Skeleton
            active
            paragraph={{ width: '100%', rows: 2 }}
            title={{ width: '70%' }}
          />
        ) : (
          <>
            <Skeleton
              active
              paragraph={{ width: '90%', rows: 1 }}
              title={{ width: '70%' }}
            />
            <Skeleton
              active
              paragraph={{ width: '90%', rows: 1 }}
              title={{ width: '70%' }}
            />
          </>
        );
      default:
        return <NotFoundText>{t('notFound')}</NotFoundText>;
    }
  };

  const renderPointDetails = ({ text, isNew }: IPointDetails) => (
    <PointDetailsWrapper key={text}>
      <CheckIcon>✓</CheckIcon>
      {t(`wl:${text}`)}
      {isNew && <AccentText>new</AccentText>}
    </PointDetailsWrapper>
  );

  const renderPoint = ({ icon: smallIcon, text, includes }: IPoint) => (
    <PointWrapper key={text}>
      <SmallIcon icon={smallIcon} />
      <PointText>
        {t(`wl:${text}`)}
        {includes && includes.map(renderPointDetails)}
      </PointText>
    </PointWrapper>
  );

  return (
    <Wrapper active={isActive} onClick={handleCardSelected}>
      <Body>
        <InfoSection>
          <TypeTitle>{t(`wl:${title}`)}</TypeTitle>
          {points.map(renderPoint)}
        </InfoSection>
        <IconSection icon={icon} imageSize={imageSize} />
      </Body>
      <Footer
        isLoading={asyncStatus === EAsyncStatus.LOADING}
        hasError={asyncStatus === EAsyncStatus.FAILED}
      >
        {renderFooter()}
      </Footer>
    </Wrapper>
  );
};

export default ReturnTypeCard;
