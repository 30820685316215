import Button, { ButtonSize } from 'antd/lib/button';
import styled, { AnyStyledComponent, css } from 'styled-components';
import { darken } from 'polished';

const colors = css`
  background-color: ${({ theme }) => theme.config.css.accentColor || '#4a90e2'};
  color: #fff;
`;

const StyledBaseButton: AnyStyledComponent = styled(Button)`
  && {
    text-align: center;
    min-width: 30rem;
    height: 4.8rem;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 600;
    font-size: ${props => props.theme.fontSize.xl};
    font-family: ${props => props.theme.fonts.primary};
    &:hover {
      opacity: 0.7;
    }

    &:disabled,
    &:hover:disabled {
      // '7F' prefix is opacity for 0.5
      background-color: ${({ theme }) =>
        `${theme.config.css.accentColor}7F` || '#4a90e27F'};
      color: #fff;
      opacity: 1;
    }

    @media only screen and (max-width: 768px) {
      min-width: auto;
      width: 100%;
      height: 48px;
    }
  }
`;

export const StyledButton: AnyStyledComponent = styled(StyledBaseButton)<{
  size?: ButtonSize;
}>`
  && {
    ${colors};

    ${props =>
      props.size === 'small' &&
      css`
        height: 4rem;
        min-width: 8rem;
        font-size: ${props.theme.fontSize.m};
        font-weight: normal;
      `}

    &:focus,
    &:active {
      ${colors};
    }

    &:focus,
    &:hover {
      background-color: ${({ theme }) =>
        darken(0.3, theme.config.css.accentColor || theme.colors.primary)};
      color: #fff;
    }

    ${props =>
      props['disabled-styles'] === 'true' &&
      css`
        background-color: #e1e0e1;
        color: #fff;

        &:hover,
        &:active,
        &:focus {
          background-color: #e1e0e1;
          color: #fff;
        }
      `};
  }
`;

export const StyledButtonLink: AnyStyledComponent = styled(StyledButton)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
