import React from 'react';
import { Holder, Pin, Content, StyledSubTitle } from './elements';
import { StyledCardTitle } from '_common/components/Card/elements';
import { EReturnType } from 'types/core';

export interface Props {
  value: EReturnType;
  onSelect: (string) => void;
  isActive: boolean;
  title: string;
  description: string;
}

const ReturnOption = ({
  isActive,
  title,
  description,
  value,
  onSelect,
}: Props) => {
  const onMethodSelect = () => onSelect(value);

  return (
    <Holder>
      <Pin onClick={onMethodSelect} isActive={isActive} />
      <Content>
        <StyledCardTitle>{title}</StyledCardTitle>
        <StyledSubTitle>{description}</StyledSubTitle>
      </Content>
    </Holder>
  );
};

export default ReturnOption;
